import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { message, MessageArgsProps, notification, NotificationArgsProps } from 'antd';
import type { NotificationPlacement } from 'antd/es/notification/interface';
import { useAppDispatch } from '@hooks/redux';
import { removeNotificationByKey } from '@redux/actions/notificationActions';
import {
  notificationsSelector,
  sequentialNotificationsSelector,
} from '@redux/selectors/notificationsSelectors';
import { INotification, NotificationMode, NotificationType } from '@redux/types/types';
import MarkdownViewer from '@components/MarkdownViewer/MarkdownViewer';
import styles from './notificationCenter.module.scss';

const NOTIFICATION_PLACEMENT: NotificationPlacement = 'bottomRight';

type DisplayConfig = (MessageArgsProps | NotificationArgsProps) & {
  mode: NotificationMode;
  key: string;
};

interface DisplayNotification {
  key: string;
  mode: NotificationMode;
  content: string;
}

const NotificationCenter = () => {
  const dispatch = useAppDispatch();
  const notifications = useSelector(notificationsSelector);
  const sequentialNotifications = useSelector(sequentialNotificationsSelector);
  const [messageApi, messageContextHolder] = message.useMessage();
  const [notificationApi, notificationContextHolder] = notification.useNotification();
  const currentDisplaysRef = useRef<DisplayNotification[]>([]);

  const removeFromDisplayRef = (key: string) => {
    currentDisplaysRef.current = currentDisplaysRef.current.filter(
      (display) => display.key !== key,
    );
  };

  const isNotificationMode = (mode: NotificationMode): mode is NotificationMode.Notification =>
    mode === NotificationMode.Notification;

  const dismissDisplayed = (display: DisplayNotification) => {
    const { key, mode } = display;

    if (isNotificationMode(mode)) notificationApi.destroy(key);
    else messageApi.destroy(key);

    removeFromDisplayRef(key);
  };

  const getDisplayConfig = (item: INotification): DisplayConfig => {
    const { key, mode = NotificationMode.Message, type, title, content, duration } = item;

    const baseConfig = {
      key,
      duration,
      onClose: () => {
        removeFromDisplayRef(key);
        dispatch(removeNotificationByKey(key));
      },
    };

    if (isNotificationMode(mode)) {
      return {
        ...baseConfig,
        mode,
        type:
          type === NotificationType.Loading ? NotificationType.Info : type || NotificationType.Info,
        message: title,
        description: <MarkdownViewer>{content}</MarkdownViewer>,
        placement: NOTIFICATION_PLACEMENT,
      };
    }

    return {
      ...baseConfig,
      mode,
      type: type || NotificationType.Info,
      content: (
        <div data-testid="message-container">
          <MarkdownViewer>{content}</MarkdownViewer>
        </div>
      ),
      className: styles.messageContainer,
    };
  };

  const displayNotification = (config: DisplayConfig) => {
    const { mode, ...restConfig } = config;

    if (isNotificationMode(mode)) {
      notificationApi.open(restConfig as NotificationArgsProps);
    } else {
      messageApi.open(restConfig as MessageArgsProps);
    }
  };

  const handleDisplayNotification = (item: INotification) => {
    const config = getDisplayConfig(item);

    currentDisplaysRef.current.push({
      key: config.key,
      mode: config.mode,
      content: item.content,
    });

    displayNotification(config);
  };

  const dismissSequentialNotifications = () => {
    const sequentialDisplays = currentDisplaysRef.current.filter((display) => {
      const sequentialNotification = sequentialNotifications.find((n) => n.key === display.key);
      return !!sequentialNotification;
    });

    sequentialDisplays.forEach((display) => {
      dismissDisplayed(display);
    });
  };

  // Handle sequential notifications
  useEffect(() => {
    if (sequentialNotifications.length === 0) return;

    const latestItem = sequentialNotifications.at(-1)!;

    const shouldUpdate = !currentDisplaysRef.current.some(({ key }) => key === latestItem.key);

    if (shouldUpdate) {
      dismissSequentialNotifications();
      handleDisplayNotification(latestItem);
    }
  }, [sequentialNotifications.length]);

  // Handle regular notifications
  // We use the length of the notifications array to trigger the effect
  // as we always push notifications to the array,
  // rather than replacing or removing them.
  useEffect(() => {
    const currentKeys = new Set(notifications.map((n) => n.key));

    // Cleanup orphaned displays
    currentDisplaysRef.current.forEach((display) => {
      if (!currentKeys.has(display.key)) dismissDisplayed(display);
    });

    if (notifications.length === 0 && currentDisplaysRef.current.length > 0) {
      currentDisplaysRef.current.forEach(dismissDisplayed);
      return;
    }

    const latestNotification = notifications.at(-1);
    if (latestNotification) handleDisplayNotification(latestNotification);
  }, [notifications.length]);

  return (
    <>
      {messageContextHolder}
      {notificationContextHolder}
    </>
  );
};

export default NotificationCenter;
