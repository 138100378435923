import React from 'react';
import DebugTunnelModal from '@modules/DebugTunnel/components/DebugTunnelModal';
import PipelinesList from './features/pipelinesList/PipelinesList';
import styles from './pipelinesLandingPage.module.scss';

const PipelinesLandingPage = () => {
  return (
    <div className={styles.container}>
      <PipelinesList />
      <DebugTunnelModal />
    </div>
  );
};

export default PipelinesLandingPage;
