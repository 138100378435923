import { UserRoles } from '@constants/enum/common';
import { IInviteUserData, ModelProviders } from '@redux/types/types';
import { http } from './base-http';

/* Get details of the user's organization */
export const getOrganizationApi = () => {
  return http.get('/api/v1/organization');
};

export const getOrganizationsApi = () => {
  return http.get('/api/v2/organizations');
};

/* Get workspaces of organization */
export const getWorkspacesApi = () => {
  return http.get('/api/v1/workspaces');
};

/* Create new workspace */
export const createWorkspaceApi = (workspace: string) => {
  return http.post('/api/v1/workspaces', { name: workspace });
};

/* Delete a workspace */
export const deleteWorkspaceApi = (workspace: string) => {
  return http.delete(`/api/v1/workspaces/${workspace}`);
};

/* Invite a user to the organization */
export const inviteUserApi = (data: IInviteUserData, organizationId: string) => {
  return http.post(`/api/v1/organization/${organizationId}/invitation`, data);
};

/* Update user role for the organization */
export const updateUserRoleApi = (userID: string, userRole: UserRoles) => {
  return http.patch(`/api/v1/users/${userID}`, { role: userRole });
};

/* Remove a user from the organization */
export const deleteUserApi = (userID: string) => {
  return http.delete(`/api/v1/users/${userID}`);
};

/* Get details of the user's organization */
export interface IAPIKeysParams {
  limit?: number;
  after?: string;
  page_number?: number;
}

export const getAPIKeysApi = (params?: IAPIKeysParams) => {
  const paramsMap = {
    limit: params?.limit || 100,
    ...(params?.after && { after: params?.after }),
    ...(params?.page_number && { page_number: params?.page_number }),
  };

  return http.get(`/api/v1/token`, { params: paramsMap });
};

export const deleteAPIKeyApi = (apiKeyId: string) => {
  return http.delete(`/api/v1/token/${apiKeyId}`);
};

export const generateAPIKeyApi = (expire?: string) => {
  const oneYearFromNow = new Date();
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
  const expiresAt = expire || oneYearFromNow.toISOString();
  return http.post('/api/v1/token', { expires_at: expiresAt });
};

export const getModelRegistryTokensApi = () => {
  return http.get('/api/v1/model_registry_tokens');
};

export const postModelRegistryTokenApi = (modelToken: string, modelProvider: ModelProviders) => {
  return http.post(`/api/v1/model_registry_tokens/${modelProvider}`, { token: modelToken });
};

export const deleteModelRegistryTokenApi = (modelProvider: ModelProviders) => {
  return http.delete(`/api/v1/model_registry_tokens/${modelProvider}`);
};
