import React, { memo, useEffect } from 'react';
import LatestRequests from '@modules/Dashboard/components/LatestRequests';
import NumberOfRequests from '@modules/Dashboard/components/NumberOfRequests';
import WorkspaceStatistic from '@modules/Dashboard/components/WorkspaceStatistic';
import useFreeLimits from '@modules/Upgrade/hooks/useFreeLimits';
import styles from './dashboardPage.module.scss';
import UsageLimits from '../components/usageLimits/UsageLimits';
import useDashboard from '../hooks/useDashboard';

// TODO: Error handling, loading handling, empty stat handling. - https://github.com/deepset-ai/haystack-hub-ui/issues/2439

const DashboardPage: React.FC = () => {
  const { isLimitedUser } = useFreeLimits();
  const {
    getSearchCount,
    getWorkspaceStats,
    getRequestHistory,
    searchCount,
    workspaceStats,
    requestHistory,
    loadingRequestHistory,
    requestsToday,
  } = useDashboard();

  useEffect(() => {
    getSearchCount();
    getRequestHistory();
    getWorkspaceStats();
  }, []);

  return (
    <div className={styles.container} data-testid="dashboardPage">
      {isLimitedUser && (
        <div className={styles.item}>
          <UsageLimits />
        </div>
      )}
      <div className={styles.item}>
        <WorkspaceStatistic statistic={workspaceStats} requestsToday={requestsToday} />
      </div>
      <div className={styles.item}>
        <NumberOfRequests data={searchCount} />
      </div>
      <div className={styles.item}>
        <LatestRequests
          data={requestHistory}
          searchRequestCount={workspaceStats.searchRequestCount}
          loading={loadingRequestHistory}
        />
      </div>
    </div>
  );
};

export default memo(DashboardPage);
