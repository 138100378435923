import { Edge, Node } from '@xyflow/react';
import yaml from 'js-yaml';
import { isObject } from 'lodash';

import { isPrompt } from './common';
import {
  IGNORE_NODE_TYPE,
  INPUT_NODE_TYPE,
  INPUT_NODE_TYPE_MAPPING,
  InputNodeType,
  NodeTypeValue,
  OUTPUT_NODE_TYPE,
} from './types';

export const isValidYAML = (yamlString: string): boolean => {
  try {
    const parsed = yaml.load(yamlString, { schema: yaml.CORE_SCHEMA });
    return isObject(parsed) && !Object.keys(parsed).some((key) => key === '[object Object]');
  } catch (e) {
    return false;
  }
};

function getNodeType(node: InputNodeType): NodeTypeValue {
  return INPUT_NODE_TYPE_MAPPING[node];
}

export const getNodeName = (node: any, nodes?: any[]) => {
  if (!nodes) return node?.data?.customName || node?.data?.type;
  const customNameExists = nodes?.find(
    (n: any) => n.data.customName === node?.data?.customName && n.id !== node?.id,
  );
  const index = nodes?.findIndex((n: any) => n.id === node?.id);

  let fullName = `${node?.data?.customName}${customNameExists ? `_${index}` : ''}`;
  // replace whitespaces with underscores
  fullName = fullName.replace(/\s/g, '_');
  return fullName;
};

export const getdCNodesIO = (nodes: any, edges: any) => {
  let inputs: any = {};
  let outputs: any = {};
  nodes.forEach((node: any) => {
    if (INPUT_NODE_TYPE.includes(node.data.type)) {
      edges
        ?.filter((edge: any) => edge.source === node.id)
        .forEach((inputConnection: any) => {
          const inputConnectionTarget = nodes.find(
            (_node: any) => _node.id === inputConnection?.target,
          );

          if (inputConnection) {
            const nodeType: NodeTypeValue = getNodeType(node.data.type);
            const nodeName: string = `${getNodeName(inputConnectionTarget, nodes)}.${
              inputConnection.targetHandle
            }`;

            // Check if the inputs object already has the nodeType key
            if (inputs[nodeType] && inputs[nodeType].length > 0) {
              // Append to the existing array
              inputs[nodeType].push(nodeName);
            } else {
              // Initialize the array with the new item
              inputs[nodeType] = [nodeName];
            }
          }
        });
    } else if (OUTPUT_NODE_TYPE.includes(node.data.type)) {
      edges
        ?.filter((edge: any) => edge.target === node.id)
        .forEach((outputConnection: any) => {
          const outputConnectionOrigin = nodes.find(
            (_node: any) => _node.id === outputConnection?.source,
          );
          if (outputConnection) {
            outputs[`${outputConnection.targetHandle}`] = `${getNodeName(
              outputConnectionOrigin,
              nodes,
            )}.${outputConnection.sourceHandle}`;
          }
        });
    }
  });
  return { inputs, outputs };
};

const removeNullValues = (obj: any, componentTypeInitParams: any) => {
  const newObj: any = {};
  let initParams = componentTypeInitParams || {};
  Object.keys(obj).forEach((key) => {
    let isSecret = initParams[key]?.$ref?.startsWith('#/definitions/haystackTypes/Secret');
    // Some stuff like the PyPDFConverter need the explicit null values
    // Only for secrets we want to rely on the defaults
    if (!isSecret || obj[key] !== null) {
      newObj[key] = obj[key];
    }
  });
  return newObj;
};

const parseInitParameters = (
  componentType: string,
  params: any,
  componentTypeInitParams: Record<string, any>,
) => {
  let copiedParams = removeNullValues(params, componentTypeInitParams);
  const parsedParams = Object.entries(copiedParams).reduce((acc, [key, paramValue]) => {
    let parsedValue: any = paramValue;

    const isPromptValue = isPrompt(componentType, key);

    //TODO: Add support for other types when it comes in an array
    // Avoid parsing to yaml if the value is already a string and the type is or can be a string
    if (isPromptValue) return { ...acc, [key]: paramValue };
    if (
      componentTypeInitParams &&
      componentTypeInitParams[key]?.type === 'string' &&
      typeof paramValue === 'string'
    )
      return { ...acc, [key]: paramValue };
    if (componentTypeInitParams && componentTypeInitParams[key]?.type instanceof Array) {
      const types = componentTypeInitParams[key]?.type.filter((v: string) => v !== 'null');
      if (
        types.length === 1 &&
        (types[0] === 'string' || types[0] === 'integer' || types[0] === 'boolean')
      )
        return { ...acc, [key]: paramValue };
    }
    if (typeof paramValue === 'string' && isValidYAML(paramValue))
      return { ...acc, [key]: yaml.load(paramValue) };
    return { ...acc, [key]: parsedValue };
  }, {});
  return parsedParams;
};

const resolveDocumentStoreParams = (
  nodes: Node[],
  edges: Edge[],
  components: any,
  nodeList: any,
  documentStoreComponents: Record<string, any>,
  inputOutputMap: Record<string, any>,
) => {
  // Create copies to avoid mutating the original arrays
  let updatedNodes: Node[] = [...nodes];
  let updatedEdges: Edge[] = [...edges];
  let updatedNodeList: Record<string, any> = { ...nodeList };
  let updatedComponents: Record<string, any> = { ...components };

  // Find edges connecting document stores to document_store inputs
  const documentStoreEdges = edges.filter((edge) => {
    const sourceNode = nodes.find((n) => n.id === edge.source);
    return (
      (sourceNode?.data?.type as string) in documentStoreComponents &&
      edge.targetHandle?.includes('document_store')
    );
  });

  documentStoreEdges.forEach((edge: any) => {
    let sourceNode = nodes.find((n) => n.id === edge.source);
    let targetNode = nodes.find((n) => n.id === edge.target);
    if (sourceNode && targetNode) {
      const params = sourceNode.data.params || {};

      let docstoreParams = { ...params } as Record<string, any>;
      const docStoreTypeConst = sourceNode.data.nodeTypeConst;
      const { type, init_parameters: initParams, ...cleanedParams } = docstoreParams;

      // Update the target component's document_store parameter
      const targetComponentName = getNodeName(targetNode, nodes);
      if (updatedComponents[targetComponentName]) {
        updatedComponents[targetComponentName].init_parameters.document_store = {
          type:
            type ||
            docStoreTypeConst ||
            'haystack_integrations.document_stores.opensearch.document_store.OpenSearchDocumentStore',
          init_parameters: { ...initParams, ...cleanedParams },
        };
      }
      updatedNodes = updatedNodes.filter((n) => n.id !== sourceNode.id);
      updatedEdges = updatedEdges.filter((e) => e.source !== sourceNode.id);
      delete updatedNodeList[sourceNode.id];
    }
  });

  // set empty document_store if there's no docstore node connected
  updatedNodes.forEach((node: Node) => {
    const ioData = inputOutputMap[node?.data?.type as string];
    if (ioData) {
      const documentStoreInput = ioData.input.find((input: any) => input.key === 'document_store');
      if (documentStoreInput) {
        const thereIsNoDocumentStore = !documentStoreEdges.some(
          (edge) => edge.sourceHandle === 'document_store' && edge.target === node.id,
        );
        if (thereIsNoDocumentStore && updatedComponents[getNodeName(node, nodes)]) {
          updatedComponents[getNodeName(node, nodes)].init_parameters.document_store = {};
        }
      }
    }
  });

  Object.keys(updatedComponents).forEach((key) => {
    const componentType = updatedComponents[key].type.split('.').pop();
    if (
      updatedComponents[key] &&
      (key in documentStoreComponents || componentType in documentStoreComponents)
    ) {
      delete updatedComponents[key];
    }
  });

  return {
    nodes: updatedNodes,
    edges: updatedEdges,
    nodeList: updatedNodeList,
    components: updatedComponents,
  };
};

export const getYamlObject = (
  nodes: any,
  edges: any,
  nodeList: any,
  include_io_mapping: boolean = true,
  originalYamlString?: string,
  documentStoreComponents?: Record<string, any>,
  inputOutputMap?: Record<string, any>,
) => {
  let inputs: any = {};
  let outputs: any = {};
  let components: any = {};
  let connections: any[] = [];
  let originalYaml = null;

  const defaults: {
    max_runs_per_component: number;
    metadata: Record<string, unknown>;
    async_enabled?: boolean;
    pipeline_output_type?: string;
  } = {
    max_runs_per_component: 100,
    metadata: {},
  };

  if (originalYamlString) {
    try {
      originalYaml = yaml.load(originalYamlString) as Record<string, any>;
    } catch (e) {
      console.error('Error parsing original YAML', e);
    }
  }

  if (originalYaml && originalYaml.max_runs_per_component)
    defaults.max_runs_per_component = originalYaml.max_runs_per_component;
  if (originalYaml && originalYaml.metadata) defaults.metadata = originalYaml.metadata;
  if (originalYaml && originalYaml.async_enabled)
    defaults.async_enabled = originalYaml.async_enabled;
  if (originalYaml && originalYaml.pipeline_output_type)
    defaults.pipeline_output_type = originalYaml.pipeline_output_type;

  let filteredNodes: any[] = [];
  let updatedComponents: any = {};
  if (nodes) {
    if (nodes.length > 0) {
      const IOResults = getdCNodesIO(nodes, edges);
      inputs = IOResults.inputs;
      outputs = IOResults.outputs;
    }

    // remove the nodes that are not part of the pipeline
    filteredNodes = nodes.filter((node: any) => !IGNORE_NODE_TYPE.includes(node.data.type));

    // Step 3: Iterate through components and add to components object
    filteredNodes.forEach((node: any) => {
      const componentType = nodeList.find((item: { type: any }) => item.type === node.data.label);
      components[getNodeName(node, nodes)] = {
        type: componentType ? componentType.typeConst : node.data.nodeTypeConst,
        init_parameters:
          parseInitParameters(
            componentType?.type || node.data.type,
            node.data.params,
            componentType?.initParams,
          ) || {},
      };
    });

    let updated = resolveDocumentStoreParams(
      nodes,
      edges,
      components,
      nodeList,
      documentStoreComponents || {},
      inputOutputMap || {},
    );
    updatedComponents = updated.components;
    if (updated.edges && updated.edges.length > 0) {
      updated.edges.forEach((edge: any) => {
        const sourceNode = filteredNodes.find((node: any) => node.id === edge.source);
        const targetNode = filteredNodes.find((node: any) => node.id === edge.target);
        if (!sourceNode || !targetNode) return;
        connections.push({
          sender: `${getNodeName(sourceNode, nodes)}.${edge.sourceHandle}`,
          receiver: `${getNodeName(targetNode, nodes)}.${edge.targetHandle}`,
        });
      });
    }
  }

  // Combine all parts into one YAML object
  const yamlObject: {
    components: any;
    connections: any[];
    max_runs_per_component: number;
    inputs?: any;
    outputs?: any;
    metadata: any;
  } = {
    components: updatedComponents,
    connections: connections,
    ...defaults,
  };

  if (include_io_mapping && Object.keys(inputs).length > 0) yamlObject.inputs = inputs;
  if (include_io_mapping && Object.keys(outputs).length > 0) yamlObject.outputs = outputs;

  return yamlObject;
};

export const getYamlString = (yamlObject: Record<string, any>) => {
  // line width -1 to avoid splitting long strings
  let yamlString = yaml.dump(yamlObject, {
    lineWidth: -1,
    styles: {
      '!!str': (value: string) => {
        // Use block literal (|-) for multiline strings
        return value.includes('\n') || value.startsWith('{#') ? '|' : 'plain';
      },
    },
  });

  return yamlString.replace(/\|(?=\n)/g, '|-');
};

export const generateYaml = (
  nodes: any,
  edges: any,
  nodeList: any = null,
  include_io_mapping: boolean = true,
) => {
  const yamlObject = getYamlObject(nodes, edges, nodeList, include_io_mapping);

  let yamlString = getYamlString(yamlObject);

  return yamlString.replace(/\|(?=\n)/g, '|-');
};
