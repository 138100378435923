import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import {
  getRequestHistory as getRequestHistoryAction,
  getSearchCount as getSearchCountAction,
  getWorkSpaceStatistic as getWorkSpaceStatisticAction,
  loadingRequestHistorySelector,
  requestHistorySelector,
  searchCountSelector,
  workspaceStatsSelector,
} from '../redux/dashboardSlice';

const useDashboard = () => {
  const dispatch = useAppDispatch();

  const searchCount = useAppSelector(searchCountSelector);
  const workspaceStats = useAppSelector(workspaceStatsSelector);
  const requestHistory = useAppSelector(requestHistorySelector);
  const loadingRequestHistory = useAppSelector(loadingRequestHistorySelector);

  const requestsToday = useMemo(
    () => (searchCount.length && searchCount[searchCount.length - 1].count) || 0,
    [searchCount],
  );

  const getRequestHistory = () => {
    dispatch(getRequestHistoryAction());
  };

  const getSearchCount = () => {
    dispatch(getSearchCountAction());
  };

  const getWorkspaceStats = () => {
    dispatch(getWorkSpaceStatisticAction());
  };

  return {
    getRequestHistory,
    getSearchCount,
    getWorkspaceStats,
    searchCount,
    workspaceStats,
    requestHistory,
    loadingRequestHistory,
    requestsToday,
  };
};

export default useDashboard;
