import React, { useEffect, useMemo, useState } from 'react';
import Icon from '@ant-design/icons';
import { Badge, Button, Input, Popover, Tooltip } from 'antd';
import { debounce, isEmpty } from 'lodash';
import { isValidJSONValue } from '@utils/json';
import TuneOutlinedSVG from '@assets/TuneOutlined.svg?react';
import usePipelineQuery from '@hooks/usePipelineQuery';
import { INVALID_JSON_MESSAGE } from '@constants/common';
import {
  CONFIGURATIONS_TOOLTIP_TITLE,
  QUERY_PARAMETERS_DESCRIPTION,
  QUERY_PARAMETERS_TEXT_INPUT_PLACEHOLDER,
  QUERY_PARAMETERS_TITLE,
} from '@constants/search';
import styles from './queryPipelineParams.module.scss';

const { TextArea } = Input;

const PAIRS: Record<string, string> = {
  '{': '}',
  '[': ']',
  '"': '"',
};

const INDENT = '  '; // 2 spaces for indentation
const DEBOUNCE_TIME = 500;

export const QueryPipelineParams = () => {
  const { setQueryPipelineParams, queryPipelineParams } = usePipelineQuery();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(
    !isEmpty(queryPipelineParams ?? {}) ? JSON.stringify(queryPipelineParams, null, 2) : '',
  );
  const [jsonError, setJsonError] = useState<string | null>(null);

  const validateInputOnChange = (inputValue: string) => {
    if (!inputValue.trim()) {
      setJsonError(null);
      return;
    }

    setJsonError(isValidJSONValue(inputValue) ? null : INVALID_JSON_MESSAGE);
  };

  const debouncedValidateInputOnChange = useMemo(
    () => debounce(validateInputOnChange, DEBOUNCE_TIME),
    [],
  );

  useEffect(() => {
    return () => debouncedValidateInputOnChange.cancel();
  }, [debouncedValidateInputOnChange]);

  const hasSetQueryPipelineParams = !isEmpty(queryPipelineParams ?? {});

  const handleOpenChange = (newOpenState: boolean) => {
    setOpen(newOpenState);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const textarea = e.currentTarget;
    const { selectionStart, selectionEnd, value: currentValue } = textarea;

    // Handle auto-closing pairs
    if (PAIRS[e.key]) {
      e.preventDefault();
      const closing = PAIRS[e.key];
      const newValue =
        currentValue.slice(0, selectionStart) +
        e.key +
        (selectionStart === selectionEnd ? closing : '') +
        currentValue.slice(selectionEnd);

      setValue(newValue);

      // Set cursor position inside the pairs
      setTimeout(() => {
        textarea.selectionStart = textarea.selectionEnd = selectionStart + 1;
      }, 0);
      return;
    }

    // Handle Enter key for auto-indentation
    if (e.key === 'Enter') {
      e.preventDefault();

      const beforeCursor = currentValue.slice(0, selectionStart);
      const afterCursor = currentValue.slice(selectionStart);
      const lastLine = beforeCursor.split('\n').pop() || '';
      const currentIndent = lastLine.match(/^\s*/)?.[0] || '';

      // Check if we're between braces
      const nextChar = currentValue[selectionStart];
      const isClosingBraceNext = nextChar === '}' || nextChar === ']';

      if (isClosingBraceNext) {
        // If we're between braces, add two newlines and place the closing brace
        const newValue = `${beforeCursor}\n${currentIndent}${INDENT}\n${currentIndent}${afterCursor}`;

        setValue(newValue);

        // Place cursor after the indentation on the middle line
        setTimeout(() => {
          textarea.selectionStart = textarea.selectionEnd =
            selectionStart + 1 + currentIndent.length + INDENT.length;
        }, 0);
      } else {
        // Normal enter behavior
        const extraIndent = /[{[]$/.test(lastLine.trim()) ? INDENT : '';
        const newValue = `${beforeCursor}\n${currentIndent}${extraIndent}${afterCursor}`;

        setValue(newValue);

        setTimeout(() => {
          textarea.selectionStart = textarea.selectionEnd =
            selectionStart + 1 + currentIndent.length + extraIndent.length;
        }, 0);
      }
      return;
    }

    // Handle Tab key
    if (e.key === 'Tab') {
      e.preventDefault();
      const newValue =
        currentValue.slice(0, selectionStart) + INDENT + currentValue.slice(selectionEnd);

      setValue(newValue);

      // Set cursor position after indentation
      setTimeout(() => {
        textarea.selectionStart = textarea.selectionEnd = selectionStart + INDENT.length;
      }, 0);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value: newValue } = e.target;
    setValue(newValue);
    debouncedValidateInputOnChange(newValue);
  };

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    const { value: currentValue } = e.target;
    const sanitizedValue = currentValue.trim();
    const isValid = isValidJSONValue(sanitizedValue) || !sanitizedValue;

    if (isValid) setQueryPipelineParams(JSON.parse(sanitizedValue || '{}'));
  };

  const content = (
    <div className={styles.paramsContent}>
      <h6 className={styles.paramsHeader}>JSON</h6>
      <TextArea
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        autoSize={{ minRows: 4 }}
        placeholder={QUERY_PARAMETERS_TEXT_INPUT_PLACEHOLDER}
        className={styles.paramsTextArea}
        status={jsonError ? 'error' : undefined}
        spellCheck={false}
      />
      {!!jsonError && <p className={styles.errorMessage}>{jsonError}</p>}
      <p className={styles.paramsDescription}>{QUERY_PARAMETERS_DESCRIPTION}</p>
    </div>
  );

  return (
    <Popover
      content={content}
      title={QUERY_PARAMETERS_TITLE}
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      placement="bottomRight"
      rootClassName={styles.paramsPopover}
    >
      <Tooltip title={CONFIGURATIONS_TOOLTIP_TITLE} placement="bottom">
        <Badge dot={hasSetQueryPipelineParams}>
          <Button icon={<Icon component={TuneOutlinedSVG} />} />
        </Badge>
      </Tooltip>
    </Popover>
  );
};

export default QueryPipelineParams;
