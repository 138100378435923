import React, { useCallback } from 'react';
import { Flex, Tag } from 'antd';
import { ITag } from '@redux/types/types';
import styles from './pipelineTemplatesTags.module.scss';

const enum ExcludedTag {
  CATEGORY = 'category:',
  LANGUAGE = 'language:',
}

interface IPipelineTemplatesTagsProps {
  tags: ITag[];
}

const PipelineTemplatesTags = ({ tags }: IPipelineTemplatesTagsProps) => {
  const getFilteredTags = useCallback(() => {
    return tags.filter(
      (tag) => !tag.name.includes(ExcludedTag.CATEGORY) && !tag.name.includes(ExcludedTag.LANGUAGE),
    );
  }, [tags]);

  return (
    <Flex gap="small" wrap className={styles.templateTags}>
      {getFilteredTags().map((tag) => (
        <Tag key={tag.tag_id} color="processing" bordered={false}>
          {tag.name}
        </Tag>
      ))}
    </Flex>
  );
};

export default PipelineTemplatesTags;
