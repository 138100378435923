export enum AsyncActionType {
  ENABLE_INDEX = 'INDEXES/ENABLE_INDEX',
  DISABLE_INDEX = 'INDEXES/DISABLE_INDEX',
  CREATE_INDEX = 'INDEXES/CREATE_INDEX',
  UPDATE_INDEX = 'INDEXES/UPDATE_INDEX',
  FETCH_INDEXES = 'INDEXES/FETCH_INDEXES',
  FETCH_INDEX = 'INDEXES/FETCH_INDEX',
  FETCH_INDEX_LOGS = 'INDEXES/FETCH_INDEX_LOGS',
  FETCH_CONNECTED_PIPELINES = 'INDEXES/FETCH_CONNECTED_PIPELINES',
  FETCH_INDEX_TOTAL_DOCUMENTS = 'INDEXES/FETCH_INDEX_TOTAL_DOCUMENTS',
  DOWNLOAD_INDEX_LOGS = 'INDEXES/DOWNLOAD_INDEX_LOGS',
}
