import { AxiosResponse } from 'axios';
import _get from 'lodash/get';
import { ICreatedBy } from '@redux/types/commonTypes';
import {
  IAuthUserData,
  IUserData,
  IUserProfileData,
  IWorkspaceRoleItem,
  OrganizationType,
} from '@redux/types/types';
import { UserRoles } from '@src/constants/enum/common';

export function transformUserProfile(
  authResponse: IAuthUserData,
  profileResponse: IUserProfileData,
): IUserData {
  const firstName: string = _get(authResponse, 'given_name', '');
  const lastName: string = _get(authResponse, 'family_name', '');
  const email: string = _get(authResponse, 'email', '');
  const isEmailVerified: boolean = _get(authResponse, 'email_verified', false);
  const fullName: string = firstName && lastName ? `${firstName} ${lastName}` : email;
  const userID: string = _get(profileResponse, 'user_id', '');
  const isAdmin: boolean =
    _get(profileResponse, 'organization.role', UserRoles.SEARCH_USER) === UserRoles.ADMIN;
  const workspaces: IWorkspaceRoleItem[] = _get(profileResponse, 'organization.workspaces', [
    { name: 'default', role: 'developer' },
  ]);
  const role: UserRoles = _get(profileResponse, 'organization.role', UserRoles.SEARCH_USER);
  const organization: string = _get(profileResponse, 'organization.organization_id', '');
  const userflowSignature: string = _get(profileResponse, 'userflow_signature', '');
  const organizationType: OrganizationType = _get(
    profileResponse,
    'organization.organization_type',
    OrganizationType.LIMITED,
  );

  return {
    firstName,
    lastName,
    fullName,
    email,
    userID,
    isAdmin,
    isEmailVerified,
    workspaces,
    role,
    organization,
    userflowSignature,
    isLimitedUser: organizationType === OrganizationType.LIMITED,
    organizationType,
  };
}

export interface IUserResponseData {
  family_name: string;
  given_name: string;
  email: string;
  user_id: string;
  organization: {
    name: string;
    organization_id: string;
    pricing_plan: string;
    role: UserRoles;
    workspaces: IWorkspaceRoleItem[];
  };
}

export function transformUsers(userData: IUserResponseData): IUserData {
  const firstName: string = _get(userData, 'given_name', '');
  const lastName: string = _get(userData, 'family_name', '');
  const email: string = _get(userData, 'email', '');
  const fullName: string = firstName && lastName ? `${firstName} ${lastName}` : email;
  const userID: string = _get(userData, 'user_id', '');
  const isAdmin: boolean =
    _get(userData, 'organization.role', UserRoles.SEARCH_USER) === UserRoles.ADMIN;
  const workspaces: IWorkspaceRoleItem[] = _get(userData, 'organization.workspaces', [
    { name: 'default', role: 'EDITOR' },
  ]);
  const role: UserRoles = _get(userData, 'organization.role', UserRoles.SEARCH_USER);
  const isEmailVerified: boolean = false; // We don't have this information in our DB
  const organization: string = _get(userData, 'organization.organization_id', '');
  const organizationType: OrganizationType = _get(
    userData,
    'organization.organization_type',
    OrganizationType.LIMITED,
  );

  return {
    firstName,
    lastName,
    fullName,
    email,
    userID,
    isAdmin,
    workspaces,
    role,
    isEmailVerified,
    organization,
    isLimitedUser: organizationType === OrganizationType.LIMITED,
    organizationType,
  };
}

export function transformAllUsers(allUsersResponse: AxiosResponse<any>): IUserData[] {
  const users: IUserResponseData[] = _get(allUsersResponse, 'data.data', []);

  return users.map((item: IUserResponseData) => transformUsers(item));
}

export const getUserFullname = (user: ICreatedBy): string => {
  if (!user) return '';
  return `${user.given_name} ${user.family_name}`;
};
