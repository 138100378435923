import produce from 'immer';
import { v4 as uuidv4 } from 'uuid';
import { extractSearchQueryParamsPromptTemplates, generateEmptyAnswerResult } from '@utils/search';
import {
  ADD_PROMPT_EXPLORER_INFO_RESULT,
  APPEND_CHAT_STREAM,
  APPEND_PROMPT_EXPLORER_QUERY_STREAM,
  APPEND_SEARCH_STREAM,
  DOWNLOAD_FILE,
  FULFILLED,
  GET_FILE_CONTENT,
  GET_SEARCH_SESSIONS,
  IMessage,
  IPipelineQueryParams,
  IQueryResultPrompts,
  IQueryStreamMessageData,
  ISearchResult,
  ISearchSession,
  OPEN_REFERENCE_DRAWER,
  PENDING,
  PlaygroundId,
  QUERY_PROMPT_EXPLORER,
  REJECTED,
  RESET_CHAT_RESULTS,
  RESET_QUERY_RESULT_PROMPT_EXPLORER,
  RESET_REFERENCE_DRAWER,
  RESET_SEARCH_RESULT,
  SEARCH,
  SEND_CHAT_QUERY,
  SET_QUERY_PIPELINE_PARAMS,
  SET_SEARCH_PIPELINE,
  TOGGLE_DEBUG_MODE,
  TOGGLE_PROMPT_MODAL,
} from '@redux/types/types';
import { HTTPStatusCode, MessageCodes, StatusCodes } from '@src/constants/enum/common';
import { normalizeErrorMessage } from '@src/utils/error';

interface IInitialStateProps {
  searchResults: ISearchResult;
  chatResults: ISearchResult[];
  queryResultsPromptExplorer: Record<PlaygroundId, ISearchResult[]>;
  statusPromptExplorer: Record<PlaygroundId, StatusCodes>;
  searchPipeline: string;
  query: string;
  status: StatusCodes;
  chatQueryStatus: StatusCodes;
  message: IMessage;
  searchSessions: {
    has_more: boolean;
    total: number;
    search_sessions: ISearchSession[];
  };
  searchSessionsStatus: StatusCodes;
  referenceDrawerVisible: boolean;
  activeReference?: {
    resultId: string;
    referenceId: string;
  } | null;
  promptModalVisible: boolean;
  activePrompts?: IQueryResultPrompts;
  debugMode?: boolean;
  queryPipelineParams?: IPipelineQueryParams;
}

export const initialState: IInitialStateProps = {
  searchResults: {
    answers: [],
    documents: [],
    query: '',
    query_id: '',
  },
  chatResults: [],
  queryResultsPromptExplorer: {},
  searchPipeline: '',
  query: '',
  status: StatusCodes.IDLE,
  chatQueryStatus: StatusCodes.IDLE,
  statusPromptExplorer: {},
  message: {
    type: MessageCodes.INFO,
    content: '',
    status: HTTPStatusCode.OK,
  },
  searchSessions: {
    has_more: false,
    total: 0,
    search_sessions: [],
  },
  searchSessionsStatus: StatusCodes.IDLE,
  referenceDrawerVisible: false,
  promptModalVisible: false,
  debugMode: true,
  queryPipelineParams: {},
};

// TODO: Refactor Prompt studio querying
function searchReducer(state = initialState, action: any) {
  return produce(state, (draft) => {
    let localDraft = draft;

    switch (action.type) {
      case RESET_SEARCH_RESULT:
        localDraft = {
          ...initialState,
          searchPipeline: localDraft.searchPipeline,
        };
        break;
      case RESET_QUERY_RESULT_PROMPT_EXPLORER:
        delete localDraft.queryResultsPromptExplorer[action.payload];
        delete localDraft.statusPromptExplorer[action.payload];
        localDraft.promptModalVisible = initialState.promptModalVisible;
        localDraft.activePrompts = initialState.activePrompts;
        break;
      case RESET_CHAT_RESULTS:
        localDraft.chatResults = initialState.chatResults;
        localDraft.chatQueryStatus = initialState.chatQueryStatus;
        localDraft.promptModalVisible = initialState.promptModalVisible;
        localDraft.activePrompts = initialState.activePrompts;
        break;
      case SET_SEARCH_PIPELINE:
        localDraft = {
          ...initialState,
          searchPipeline: action.payload,
        };
        break;
      case OPEN_REFERENCE_DRAWER: {
        localDraft.referenceDrawerVisible = true;
        localDraft.activeReference = action.payload;
        break;
      }
      case RESET_REFERENCE_DRAWER: {
        localDraft.referenceDrawerVisible = false;
        localDraft.activeReference = null;
        break;
      }
      case TOGGLE_PROMPT_MODAL: {
        localDraft.promptModalVisible = !localDraft.promptModalVisible;
        localDraft.activePrompts = action.payload;
        break;
      }
      case TOGGLE_DEBUG_MODE: {
        localDraft.debugMode = action.payload;
        break;
      }
      case `${SEARCH}/${PENDING}`:
        localDraft.query = action.meta.arg?.query || '';
        localDraft.status = StatusCodes.IN_PROGRESS;
        localDraft.message = initialState.message;
        localDraft.searchResults = initialState.searchResults;
        break;
      case `${SEARCH}/${REJECTED}`: {
        localDraft.status = StatusCodes.ERROR;
        localDraft.message = {
          type: MessageCodes.ERROR,
          content: normalizeErrorMessage(action.payload),
          status: action.payload?.response?.status,
        };
        break;
      }
      case `${SEARCH}/${FULFILLED}`: {
        localDraft.status = StatusCodes.SUCCESS;
        localDraft.message = initialState.message;

        // If no payload, the data is being streamed
        if (!action.payload) return;

        // Only supports single question answer
        const { results } = action.payload;
        // eslint-disable-next-line prefer-destructuring
        localDraft.searchResults = results[0];
        break;
      }

      case `${APPEND_SEARCH_STREAM}`: {
        localDraft.status = StatusCodes.SUCCESS;
        localDraft.message = initialState.message;
        const {
          query_id: queryId,
          result,
          text,
          query,
        } = action.payload as IQueryStreamMessageData & { query: string };

        if (result) {
          localDraft.searchResults = result;
          break;
        }

        const { answers: localAnswers } = localDraft.searchResults;
        const [firstAnswer] = localAnswers || { answer: '' };

        localDraft.searchResults = {
          ...localDraft.searchResults,
          answers: [
            {
              ...generateEmptyAnswerResult(),
              answer: (firstAnswer?.answer || '') + text,
            },
          ],
          query_id: queryId,
          query,
        };

        break;
      }

      case `${GET_FILE_CONTENT}/${PENDING}`:
      case `${DOWNLOAD_FILE}/${PENDING}`:
        localDraft.message = initialState.message;
        localDraft.status = StatusCodes.IN_PROGRESS;
        break;
      case `${GET_FILE_CONTENT}/${FULFILLED}`:
      case `${DOWNLOAD_FILE}/${FULFILLED}`:
        localDraft.status = StatusCodes.SUCCESS;
        localDraft.message = initialState.message;
        break;
      case `${GET_SEARCH_SESSIONS}/${PENDING}`: {
        const { fetchMore } = action.meta.arg;
        if (fetchMore) break;
        localDraft.searchSessionsStatus = StatusCodes.IN_PROGRESS;
        break;
      }
      case `${GET_SEARCH_SESSIONS}/${FULFILLED}`: {
        const { fetchMore } = action.meta.arg;
        localDraft.searchSessionsStatus = StatusCodes.SUCCESS;

        if (fetchMore)
          localDraft.searchSessions = {
            ...action.payload,
            search_sessions: [
              ...localDraft.searchSessions.search_sessions,
              ...action.payload.search_sessions,
            ],
          };
        else localDraft.searchSessions = action.payload;
        break;
      }
      case `${GET_FILE_CONTENT}/${REJECTED}`:
      case `${DOWNLOAD_FILE}/${REJECTED}`:
        localDraft.status = StatusCodes.ERROR;
        localDraft.message = {
          type: MessageCodes.ERROR,
          content: normalizeErrorMessage(action.payload),
          status: action.payload?.response?.status,
        };
        break;

      case `${SEND_CHAT_QUERY}/${PENDING}`: {
        localDraft.chatQueryStatus = StatusCodes.IN_PROGRESS;
        break;
      }

      case `${SEND_CHAT_QUERY}/${FULFILLED}`: {
        localDraft.chatQueryStatus = StatusCodes.SUCCESS;

        // If no payload, the data is being streamed
        if (!action.payload) return;

        const { results } = action.payload;
        const [firstResult] = results;
        localDraft.chatResults = [...localDraft.chatResults, firstResult];
        break;
      }

      case `${APPEND_CHAT_STREAM}`: {
        localDraft.chatQueryStatus = StatusCodes.SUCCESS;
        const {
          query_id: queryId,
          result,
          text,
          query,
        } = action.payload as IQueryStreamMessageData & { query: string };

        const existsChatResultIdx = localDraft.chatResults.findIndex(
          (chatResult) => chatResult.query_id === queryId,
        );

        if (result) {
          if (existsChatResultIdx !== -1) localDraft.chatResults[existsChatResultIdx] = result;
          else localDraft.chatResults = [...localDraft.chatResults, result];

          break;
        }

        if (existsChatResultIdx !== -1) {
          const [firstAnswer] = localDraft.chatResults[existsChatResultIdx].answers || {
            answer: '',
          };
          localDraft.chatResults[existsChatResultIdx].answers = [
            {
              ...generateEmptyAnswerResult(),
              answer: (firstAnswer?.answer || '') + text,
            },
          ];
          break;
        }

        localDraft.chatResults = [
          ...localDraft.chatResults,
          {
            documents: [],
            answers: [
              {
                ...generateEmptyAnswerResult(),
                answer: text,
              },
            ],
            query_id: queryId,
            query,
          },
        ];

        break;
      }

      case `${SEND_CHAT_QUERY}/${REJECTED}`: {
        localDraft.chatQueryStatus = StatusCodes.ERROR;

        const { query } = action.meta.arg;
        const errorResult = {
          answers: [],
          documents: [],
          query,
          query_id: uuidv4(),
          errorMessage: normalizeErrorMessage(action.payload),
        };

        localDraft.chatResults = [...localDraft.chatResults, errorResult];
        break;
      }

      case `${QUERY_PROMPT_EXPLORER}/${PENDING}`: {
        const { playgroundId } = action.meta.arg;
        localDraft.statusPromptExplorer[playgroundId] = StatusCodes.IN_PROGRESS;
        break;
      }
      case `${QUERY_PROMPT_EXPLORER}/${FULFILLED}`: {
        const { playgroundId, params } = action.meta.arg;
        localDraft.statusPromptExplorer[playgroundId] = StatusCodes.SUCCESS;

        // If no payload, the data is being streamed
        if (!action.payload) return;

        const { results } = action.payload;

        const resultWithPromptTemplates = {
          ...results[0],
          promptTemplates: extractSearchQueryParamsPromptTemplates(params),
        };

        if (localDraft.queryResultsPromptExplorer[playgroundId]) {
          localDraft.queryResultsPromptExplorer[playgroundId] = [
            ...localDraft.queryResultsPromptExplorer[playgroundId],
            resultWithPromptTemplates,
          ];
        } else {
          localDraft.queryResultsPromptExplorer[playgroundId] = [resultWithPromptTemplates];
        }
        break;
      }

      case APPEND_PROMPT_EXPLORER_QUERY_STREAM: {
        const {
          query_id: queryId,
          result,
          text,
          query,
          playgroundId,
          params,
        } = action.payload as IQueryStreamMessageData & {
          query: string;
          playgroundId: string;
          params: IPipelineQueryParams;
        };
        localDraft.statusPromptExplorer[playgroundId] = StatusCodes.SUCCESS;

        const initialResult = {
          documents: [],
          answers: [
            {
              ...generateEmptyAnswerResult(),
              answer: text,
            },
          ],
          query_id: queryId,
          query,
          promptTemplates: extractSearchQueryParamsPromptTemplates(params),
        };

        if (!localDraft.queryResultsPromptExplorer[playgroundId]) {
          if (result) {
            const resultWithPromptTemplates = {
              ...result,
              promptTemplates: extractSearchQueryParamsPromptTemplates(params),
            };
            localDraft.queryResultsPromptExplorer[playgroundId] = [resultWithPromptTemplates];
            break;
          }
          localDraft.queryResultsPromptExplorer[playgroundId] = [initialResult];
          break;
        }

        let currentPlaygroundResults = localDraft.queryResultsPromptExplorer[playgroundId];
        const existsPlaygroundResultsIdx = currentPlaygroundResults.findIndex(
          (playgroundResult) => playgroundResult.query_id === queryId,
        );

        if (result) {
          const resultWithPromptTemplates = {
            ...result,
            promptTemplates: extractSearchQueryParamsPromptTemplates(params),
          };
          if (existsPlaygroundResultsIdx !== -1)
            currentPlaygroundResults[existsPlaygroundResultsIdx] = resultWithPromptTemplates;
          else currentPlaygroundResults.push(resultWithPromptTemplates);

          break;
        }

        if (existsPlaygroundResultsIdx !== -1) {
          const [firstAnswer] = currentPlaygroundResults[existsPlaygroundResultsIdx].answers || [
            generateEmptyAnswerResult(),
          ];
          currentPlaygroundResults[existsPlaygroundResultsIdx].answers = [
            {
              ...generateEmptyAnswerResult(),
              answer: (firstAnswer.answer || '') + text,
            },
          ];
          break;
        }

        currentPlaygroundResults.push(initialResult);
        break;
      }

      case `${QUERY_PROMPT_EXPLORER}/${REJECTED}`: {
        const { playgroundId, query } = action.meta.arg;
        const errorResult = {
          answers: [],
          documents: [],
          query,
          query_id: uuidv4(),
          errorMessage: normalizeErrorMessage(action.payload),
        };

        localDraft.statusPromptExplorer[playgroundId] = StatusCodes.ERROR;

        if (localDraft.queryResultsPromptExplorer[playgroundId]) {
          localDraft.queryResultsPromptExplorer[playgroundId] = [
            ...localDraft.queryResultsPromptExplorer[playgroundId],
            errorResult,
          ];
        } else {
          localDraft.queryResultsPromptExplorer[playgroundId] = [errorResult];
        }
        break;
      }

      case ADD_PROMPT_EXPLORER_INFO_RESULT: {
        const { playgroundId, infoText } = action.payload;
        const infoResult = {
          answers: [],
          documents: [],
          query: '',
          query_id: uuidv4(),
          infoMessage: infoText,
        };

        localDraft.statusPromptExplorer[playgroundId] = StatusCodes.ERROR;

        if (localDraft.queryResultsPromptExplorer[playgroundId]) {
          localDraft.queryResultsPromptExplorer[playgroundId] = [
            ...localDraft.queryResultsPromptExplorer[playgroundId],
            infoResult,
          ];
        } else {
          localDraft.queryResultsPromptExplorer[playgroundId] = [infoResult];
        }
        break;
      }

      case SET_QUERY_PIPELINE_PARAMS: {
        localDraft.queryPipelineParams = action.payload;
        break;
      }
      default:
        break;
    }

    return localDraft;
  });
}

export default searchReducer;
