import React from 'react';
import { Button } from 'antd';
import { IPipelineTemplate } from '@redux/types/types';
import DcLogoAvatar from '@components/common/dcLogoAvatar/DcLogoAvatar';
import {
  BY_USER_LABEL,
  UPGRADE_POPOVER_TEMPLATE_CONTENT,
  USE_TEMPLATE_BUTTON_LABEL,
  VIEW_DETAILS_BUTTON_LABEL,
} from '@modules/PipelineTemplates/constants/pipeline-templates';
import UpgradePopover from '@modules/Upgrade/components/upgradePopover/UpgradePopover';
import UpgradeTag from '@modules/Upgrade/components/upgradeTag/UpgradeTag';
import styles from './templateCard.module.scss';

const DESCRIPTION_CHARACTER_LIMIT = 250;

interface ITemplatesCardProps {
  template: IPipelineTemplate;
  onViewDetails?: () => void;
  onUseTemplate?: () => void;
  showUpgradeTag?: boolean;
}

const TemplatesCard = ({
  template,
  showUpgradeTag = false,
  onViewDetails,
  onUseTemplate,
}: ITemplatesCardProps) => {
  const { author, name, description } = template;

  const parseDescription = () => {
    if (!description) return '';
    if (description.length < DESCRIPTION_CHARACTER_LIMIT) return description;
    return `${description.slice(0, DESCRIPTION_CHARACTER_LIMIT)}...`;
  };

  return (
    <div className={styles.card} data-testid={template.pipeline_name}>
      <div className={styles.card_body}>
        <h6>{name}</h6>
        <div className={styles.card_body_description}>{parseDescription()}</div>
      </div>
      <div className={styles.card_createdBy}>
        <span className={styles.card_createdBy_author}>
          <DcLogoAvatar size="small" />
          {BY_USER_LABEL} {author}
        </span>
        {showUpgradeTag && <UpgradeTag />}
      </div>
      <div className={styles.card_actionButtons}>
        {onViewDetails && (
          <Button size="small" block onClick={onViewDetails} disabled={showUpgradeTag}>
            {VIEW_DETAILS_BUTTON_LABEL}
          </Button>
        )}
        {showUpgradeTag ? (
          <UpgradePopover description={UPGRADE_POPOVER_TEMPLATE_CONTENT}>
            <Button size="small" type="primary" block>
              {USE_TEMPLATE_BUTTON_LABEL}
            </Button>
          </UpgradePopover>
        ) : (
          <Button size="small" type="primary" block onClick={onUseTemplate}>
            {USE_TEMPLATE_BUTTON_LABEL}
          </Button>
        )}
      </div>
    </div>
  );
};

export default TemplatesCard;
