import React from 'react';
import { Select } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { isNil } from 'lodash';
import { FilterType, SelectedFilterItem, SelectedFilters } from '@constants/data-table';
import styles from './selectFilter.module.scss';

interface ISelectFilterProps {
  filterKey: string;
  title: string;
  style?: React.CSSProperties;
  values: SelectedFilters;
  options: {
    key: string;
    value?: unknown;
    label: string;
  }[];
  onChange: (items: SelectedFilterItem[]) => void;
  listHeight?: number;
  searchable?: boolean;
}

const SelectFilter = ({
  title,
  filterKey,
  options,
  onChange,
  values,
  style,
  listHeight,
  searchable = true,
}: ISelectFilterProps) => {
  const { Option } = Select;

  const onSelectChange = (_: string, option?: DefaultOptionType | DefaultOptionType[]) => {
    if (!option) return;
    const { key, children, value } = option as DefaultOptionType & { children: string };
    onChange([{ key, label: children, value, labelPrefix: title, type: FilterType.SELECT }]);
  };

  return (
    <Select
      showSearch={searchable}
      placeholder={title}
      optionFilterProp="children"
      className={styles.select}
      style={style}
      dropdownStyle={{ minWidth: '140px' }}
      value={values[filterKey] as unknown as string}
      onChange={onSelectChange}
      listHeight={listHeight}
    >
      {options.map((option: { key: string; label: string; value?: unknown }) => (
        <Option key={option.key} value={!isNil(option.value) ? option.value : option.key}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};

export default SelectFilter;
