import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Image } from 'antd';
import { interpolateString } from '@utils/string';
import deepsetLogo from '@assets/deepset-logo-darkblue.svg';
import { useOrganization } from '@hooks/useOrganization';
import { useWhiteLabel } from '@hooks/useWhiteLabel';
import {
  LOGIN_ERROR_LABEL,
  REDIRECTING_AUTH_BUTTON_LABEL,
  REDIRECTING_LOGIN_AUTH,
} from '@constants/auth';
import { fetchUserInfo, login } from '@src/redux/actions/userActions';
import { userLoginErrorSelector } from '@src/redux/selectors/userSelectors';
import '@styles/components/layout.scss';

const LoginComponent = () => {
  const dispatch = useDispatch();
  const { user, isAuthenticated, isLoading, error } = useAuth0();
  const { currentOrganization } = useOrganization();
  const loginError: boolean = useSelector(userLoginErrorSelector);

  // White Label

  const { isWhiteLabeled, whiteLabelAssets } = useWhiteLabel({
    orgId: currentOrganization?.organizationId,
  });

  const handleLogin = () => {
    dispatch(login());
  };

  useEffect(() => {
    if (isLoading || error) return;
    if (isAuthenticated && user) {
      dispatch(fetchUserInfo({ authUser: user, redirect: true }));
    }
    if (!isAuthenticated && !user) {
      handleLogin();
    }
  }, [isAuthenticated, error, loginError, isLoading, dispatch, user]);

  const getLogo = () => {
    return isWhiteLabeled && whiteLabelAssets?.logo ? whiteLabelAssets.logo : deepsetLogo;
  };

  const content = (
    <div className="auth-content">
      <Image width={375} style={{ marginBottom: 32 }} src={getLogo()} preview={false} />
      {loginError || error ? (
        <span>
          {interpolateString(LOGIN_ERROR_LABEL, {
            loginLink: (
              <Button type="link" onClick={() => handleLogin()} style={{ padding: 0 }}>
                {REDIRECTING_AUTH_BUTTON_LABEL}
              </Button>
            ),
          })}
        </span>
      ) : (
        <span>
          {interpolateString(REDIRECTING_LOGIN_AUTH, {
            loginLink: (
              <Button type="link" onClick={() => handleLogin()} style={{ padding: 0 }}>
                {REDIRECTING_AUTH_BUTTON_LABEL}
              </Button>
            ),
          })}
        </span>
      )}
    </div>
  );

  return content;
};

export default LoginComponent;
