import { FilterProp, FilterType, SelectedFilters } from './data-table';

export interface IExceptionTrace {
  filename: string;
  line_number: number;
  name: string;
}

export interface IException {
  type: string;
  value: string;
  trace: IExceptionTrace[];
}

export enum LogLevels {
  DEBUG = 'debug',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
  CRITICAL = 'critical',
}

export interface ILogEntry {
  log_id: string;
  level: LogLevels;
  logged_at: string;
  message: string;
  exceptions: IException[];
  extra_fields: Record<string, unknown>;
}

export interface ILogParams {
  limit?: number;
  after?: string;
  fetchMore?: boolean;
  searchValue?: string;
  filter?: string;
  filterValues?: SelectedFilters;
}

export const LOG_LEVEL_FILTER_OPTIONS = [
  { key: 'info', label: 'info' },
  { key: 'warning', label: 'warning' },
  { key: 'error', label: 'error' },
];

export const LOGS_DATE_FILTER_OPTIONS = [
  { key: 'date_15_m', label: 'Last 15m' },
  { key: 'date_30_m', label: 'Last 30m' },
  { key: 'date_1_h', label: 'Last 1h' },
  { key: 'date_2_h', label: 'Last 2h' },
  { key: 'date_4_h', label: 'Last 4h' },
  { key: 'date_1_d', label: 'Last 1d' },
  { key: 'date_2_d', label: 'Last 2d' },
];

export const LOG_ORIGIN_FILTER_OPTIONS = [
  { key: 'querypipeline', label: 'query' },
  { key: 'indexingpipeline', label: 'indexing' },
];

export const LOG_LEVEL_FILTER = {
  type: FilterType.MULTI_SELECT,
  key: 'level',
  title: 'Level',
  style: { minWidth: '87px' },
  options: LOG_LEVEL_FILTER_OPTIONS,
} as FilterProp<FilterType.MULTI_SELECT>;

export const LOG_DATE_FILTER = {
  type: FilterType.SELECT,
  key: 'logged_at',
  title: 'Date added',
  style: { minWidth: '120px', maxWidth: '260px' },
  listHeight: 200,
  options: LOGS_DATE_FILTER_OPTIONS,
} as FilterProp<FilterType.SELECT>;

export const LOG_ORIGIN_FILTER = {
  type: FilterType.MULTI_SELECT,
  key: 'origin',
  title: 'Origin',
  style: { minWidth: '87px' },
  options: LOG_ORIGIN_FILTER_OPTIONS,
} as FilterProp<FilterType.MULTI_SELECT>;

export enum LogTabKeys {
  FORMATTED = 'formatted',
  RAW = 'raw',
}

export const LOG_TAB_LABELS = {
  [LogTabKeys.FORMATTED]: 'Formatted',
  [LogTabKeys.RAW]: 'Raw',
};

export const LINE_LABEL = 'line';
export const LOGS_API_DEFAULT_LIMIT = 20;
export const TOTAL_LOGS = ' of %totalItems%';
export const TOTAL_LOGS_PER_PAGE = 'Showing %totalPerPage%';

export const LOGS_DOWNLOAD_BUTTON_LABEL = 'Download CSV';
export const LOGS_SEARCH_PLACEHOLDER_LABEL = 'Search for logs';

export const ENABLE_AUTO_REFRESH_BUTTON_LABEL = 'Enable autorefresh';

export const COPY_TO_CLIPBOARD_BUTTON_LABEL = 'Copy to clipboard';

export const EMPTY_LOGS_WITH_SEARCH_VALUE_LABEL = `There aren't any logs for your search. Change or clear the search.`;
export const EMPTY_LOGS_UNDEPLOYED_PIPELINE_LABEL = `There aren’t any logs for this pipeline yet. Once deployed, you will be able to view all logs.`;
export const EMPTY_LOGS_DEPLOYED_PIPELINE_LABEL = `There are no logs for this pipeline. This is either because there was nothing to log or this pipeline was deployed more than two days ago. We keep logs for the last two days or since the last deployment, whichever is latest.`;
export const EMPTY_LOGS_WITH_FILTERS_LABEL = `There aren't any logs for your filters. Change or clear the filters.`;

export const CAUSED_BY_LABEL = 'Caused by:';
export const STACK_TRACE_LABEL =
  '. See the stack trace above for more information. Skipping handling requests. Traceback (most recent call last):';
export const COPY_TO_CLIPBOARD_MESSAGE_ALERT = 'Raw text copied to clipboard';
export const CLEAR_ALL_FILTERS_BUTTON_LABEL = 'Clear all filters';
