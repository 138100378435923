import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Alert, Button, Tabs } from 'antd';
import { isNil } from 'lodash';
import { getPipelineIdleTimeoutLabel, pipelineInProgress } from '@utils/pipelines';
import { getParsedFeedbackSearchResultToSearchResult } from '@utils/search';
import { interpolateString } from '@utils/string';
import useNotifications from '@hooks/useNotifications';
import usePipelineActions from '@hooks/usePipelineActions';
import usePipelineFeedback from '@hooks/usePipelineFeedback';
import { useWorkspaceNavigate } from '@hooks/useWorkspaceNavigate';
import { FilterType, SelectedFilterItem, SelectedFilters } from '@constants/data-table';
import { NUMBER_OF_LATEST_QUERIES_PER_PAGE } from '@constants/details-header';
import { StatusCodes } from '@constants/enum/common';
import { ILogEntry, ILogParams } from '@constants/log-explorer';
import {
  ACTIVATE_PIPELINE_BUTTON_LABEL,
  DOCUMENTATION_LINK,
  LOGS_LINK,
  LOGS_TAB_LABEL,
  OVERVIEW_TAB_LABEL,
  PIPELINE_STATUS_MESSAGES,
  PipelineDetailsTabsKeys,
  SETTINGS_TAB_LABEL,
  SKIPPED_FILES_MESSAGE,
} from '@constants/pipeline-details';
import {
  DEPLOYMENT_FAILED_LABEL,
  DEPLOYMENT_IN_PROGRESS_LABEL,
  IDLE_STATUS_LABEL,
  IndexingStatusMessages,
  PipelineStatusCodes,
  RENAME_PIPELINE_MODAL_OK_BUTTON,
  RENAME_PIPELINE_MODAL_TITLE,
  UNDEPLOYMENT_FAILED_LABEL,
  UNDEPLOYMENT_IN_PROGRESS_LABEL,
  UPGRADE_POPOVER_PIPELINE_CONTENT,
} from '@constants/pipelines';
import {
  activatePipeline,
  checkPipelinelogsHealth,
  deletePipeline,
  deployPipeline,
  duplicatePipeline,
  exportPipelineLogsCSV,
  fetchPipeline,
  fetchPipelineIndexing,
  fetchPipelineYaml,
  getPipelineLatestQueries,
  getPipelinelogs,
  getPipelineStatistics,
  getRuntimeIssueDetection,
  resetActiveTabPipelineDetailsPage,
  resetFetchPipelineYamlStatus,
  resetMessage,
  resetPipelineDetailsState,
  resetPipelineErrors,
  resetRenamePipelineNameStatus,
  setActiveTabPipelineDetailsPage,
  startPollingPipelineIndexing,
  startPollingPipelineLogs,
  startPollingPipelineStatus,
  stopPollingPipelineIndexing,
  stopPollingPipelineLogs,
  stopPollingPipelineStatus,
  undeployPipeline,
  updatePipeline,
  validatePipelineYaml,
} from '@redux/actions/pipelineActions';
import {
  activeTabPipelineDetailsPageSelector,
  fetchPipelineYamlStatusSelector,
  loadingLatestQueriesStatusSelector,
  pipelineErrorsSelector,
  pipelineIndexingDetailsSelector,
  pipelineLatestQueriesSelector,
  pipelineLogsSelector,
  pipelineLogsStatusSelector,
  pipelineLogsUnhealthySelector,
  pipelineMessageSelector,
  pipelineSelector,
  pipelineStatisticsAnswersSelector,
  pipelineStatisticsSelector,
  renamePipelineNameStatusSelector,
} from '@redux/selectors/pipelineSelectors';
import { userSelector } from '@redux/selectors/userSelectors';
import {
  IAPIPaginationData,
  IFeedbackSearchResult,
  IMessage,
  IPipeline,
  IPipelineIndexingData,
  IPipelineStatistics,
  IPipelineStatisticsAnswers,
  IValidatePipelineParams,
  NotificationType,
  PipelineLatestQueries as PipelineLatestQueriesType,
} from '@redux/types/types';
import DetailedQueryView from '@components/detailedQueryView/DetailedQueryView';
import LogExplorer from '@components/logExplorer/LogExplorer';
import MultipleErrorsPopup from '@components/multipleErrosPopup/MultipleErrorsPopup';
import NamePipelineModal from '@components/namePipelineModal/NamePipelineModal';
import PipelineDetailsHeader from '@components/pipelines/pipelineDetailsHeader/PipelineDetailsHeader';
import PipelineFeedbackTable from '@components/pipelines/pipelineFeedbackTable/PipelineFeedbackTable';
import PipelineLatestQueries from '@components/pipelines/pipelineLatestQueries/PipelineLatestQueries';
import PipelineServiceLevelBadge from '@components/pipelines/pipelineServiceLevelBadge/PipelineServiceLevelBadge';
import PipelineServiceLevelSwitchSection from '@components/pipelines/pipelineServiceLevelSwitchSection/PipelineServiceLevelSwitchSection';
import PipelineSettings from '@components/pipelines/pipelineSettings/PipelineSettings';
import PipelineStatistics from '@components/pipelines/pipelineStatistics/PipelineStatistics';
import PipelineStatisticsAnswers from '@components/pipelines/pipelineStatisticsAnswers/PipelineStatisticsAnswers';
import ShareModal from '@components/shareModal/ShareModal';
import DebugTunnelModal from '@modules/DebugTunnel/components/DebugTunnelModal';
import UpgradeBanner from '@modules/Upgrade/components/upgradeBanner/UpgradeBanner';
import { FREE_TIER_LIMITS, LimitCategory } from '@modules/Upgrade/consts/pricing';
import usePipelines from '@pages/pipelines/hooks/usePipelines';
import styles from './pipelineDetailsPage.module.scss';

const PipelineDetailsPage = () => {
  const dispatch = useDispatch();
  const workspaceNavigate = useWorkspaceNavigate();
  const { pipelineName } = useParams() as { pipelineName: string };
  const pipeline: IPipeline = useSelector(pipelineSelector);
  const message: IMessage = useSelector(pipelineMessageSelector);
  const pipelineIndexingData: IPipelineIndexingData = useSelector(pipelineIndexingDetailsSelector);
  const pipelineStatistics: IPipelineStatistics = useSelector(pipelineStatisticsSelector);
  const pipelineLatestQueries: PipelineLatestQueriesType = useSelector(
    pipelineLatestQueriesSelector,
  );
  const loadingLatestQueriesStatus: StatusCodes = useSelector(loadingLatestQueriesStatusSelector);
  const pipelineStatisticsAnswers: IPipelineStatisticsAnswers = useSelector(
    pipelineStatisticsAnswersSelector,
  );
  const pipelineLogs: IAPIPaginationData<ILogEntry[]> = useSelector(pipelineLogsSelector);
  const pipelineLogsUnhealthy: Record<string, boolean> = useSelector(pipelineLogsUnhealthySelector);
  const pipelineLogsStatus: StatusCodes = useSelector(pipelineLogsStatusSelector);
  const loadingPipelineLogs = pipelineLogsStatus === StatusCodes.IN_PROGRESS;
  const renamePipelineNameStatus: StatusCodes = useSelector(renamePipelineNameStatusSelector);
  const fetchPipelineYamlStatus: StatusCodes = useSelector(fetchPipelineYamlStatusSelector);
  const activeTabPipelineDetailsPage = useSelector(activeTabPipelineDetailsPageSelector);
  const pipelineErrors = useSelector(pipelineErrorsSelector);
  const { isLimitedUser } = useSelector(userSelector);

  const {
    getPipelineFeedback,
    getFeedbackTags,
    resetFeedbackTags,
    resetFeedbackData,
    resetProvidedFeedbackStatus,
    pipelineFeedback,
    selectedFeedbackSortValue,
    pipelineFeedbackTagsByPipelineId,
  } = usePipelineFeedback();

  const { totalPipelines } = usePipelines();

  const { exportPipelineDocuments } = usePipelineActions();
  const { addNotification } = useNotifications();
  const [currentActiveSearchResult, setCurrentActiveSearchResult] =
    useState<IFeedbackSearchResult | null>();
  const [currentActiveFeedbackIndex, setCurrentActiveFeedbackIndex] = useState<number>(0);
  const [showRenamePipelineModal, setShowRenamePipelineModal] = useState(false);
  const [activeTab, setActiveTab] = useState<string>(activeTabPipelineDetailsPage);
  const [pipelineToShare, setPipelineToShare] = useState(false);
  const [filterDateLastDeployedAt, setFilterDateLastDeployedAt] = useState<SelectedFilterItem>({
    key: 'date',
    label: 'Date',
    value: pipeline.last_deployed_at,
    type: FilterType.DATE,
  });
  const [feedbackBookmarkFilter, setFeedbackBookmarkFilter] = useState<SelectedFilterItem>({
    key: 'bookmarked',
    label: 'Save for later',
    value: false,
    type: FilterType.TOGGLE,
  });
  const feedbackDataTableParams = useRef<{
    currentPage: number;
    pageSize: number;
    searchValue: string;
    sortValue?: string;
    filterValues?: SelectedFilters;
  } | null>(null);

  const pipelineIdleTimeout = getPipelineIdleTimeoutLabel(pipeline.idle_timeout_in_seconds);

  const {
    indexing,
    status: queryStatus,
    last_deployed_at: lastDeployAt,
    service_level: serviceLevel,
  } = pipeline;
  const {
    pending_file_count: pipelinePendingFileCount,
    failed_file_count: pipelineFailedFileCount,
  } = indexing;

  const getLatestQueries = (limit?: number) => {
    const params = {
      name: pipelineName,
      limit: limit || NUMBER_OF_LATEST_QUERIES_PER_PAGE,
    };
    dispatch(getPipelineLatestQueries(params));
  };

  useEffect(() => {
    resetFeedbackData();
    resetFeedbackTags();
    resetProvidedFeedbackStatus();

    return () => {
      dispatch(resetPipelineDetailsState);
      dispatch(resetActiveTabPipelineDetailsPage);
      dispatch(resetPipelineErrors);
      dispatch(resetFetchPipelineYamlStatus);
    };
  }, []);

  useEffect(() => {
    if (!pipeline.pipeline_id || pipeline.name !== pipelineName) return;

    getFeedbackTags({ pipelineId: pipeline.pipeline_id });
  }, [pipeline.pipeline_id, pipeline.name, pipelineName]);

  const getPipelineIssues = async () => {
    await dispatch(resetPipelineErrors);
    dispatch(getRuntimeIssueDetection(pipelineName));

    const payload: IValidatePipelineParams = {
      indexing_yaml: pipeline.indexing_yaml || '',
      query_yaml: pipeline.query_yaml || '',
    };

    dispatch(validatePipelineYaml(payload));
  };

  useEffect(() => {
    if (pipeline.name === pipelineName && fetchPipelineYamlStatus === StatusCodes.SUCCESS)
      getPipelineIssues();
  }, [pipeline.name, pipelineName, fetchPipelineYamlStatus]);

  useEffect(() => {
    if (filterDateLastDeployedAt.value)
      dispatch(
        checkPipelinelogsHealth({
          pipelineName,
          filterValues: {
            logged_at: [{ ...filterDateLastDeployedAt }],
          },
        }),
      );
  }, [queryStatus, pipelineName, filterDateLastDeployedAt]);

  useEffect(() => {
    if (lastDeployAt) setFilterDateLastDeployedAt((prev) => ({ ...prev, value: lastDeployAt }));
  }, [queryStatus, lastDeployAt]);

  useEffect(() => {
    dispatch(getPipelineStatistics(pipelineName));
    getLatestQueries();
    dispatch(fetchPipeline({ pipelineName }));
    dispatch(startPollingPipelineStatus({ pipelineName }));
    dispatch(fetchPipelineYaml(pipelineName));
    dispatch(fetchPipelineIndexing(pipelineName));

    return () => {
      dispatch(stopPollingPipelineStatus());
      dispatch(stopPollingPipelineLogs());
    };
  }, [dispatch, pipelineName]);

  useEffect(() => {
    if (pipelineInProgress(pipeline.status)) dispatch(startPollingPipelineIndexing(pipelineName));
    if (pipeline.status === PipelineStatusCodes.DEPLOYED)
      dispatch(getPipelineStatistics(pipelineName));

    return () => {
      dispatch(stopPollingPipelineIndexing());
    };
  }, [pipeline.status, pipelineName]);

  useEffect(() => {
    if (!currentActiveSearchResult) return;

    const feedbackListLength = pipelineFeedback?.data.length - 1;
    const activeFeedbackIndex = currentActiveFeedbackIndex > 0 ? 0 : feedbackListLength;

    setCurrentActiveFeedbackIndex(activeFeedbackIndex);
    setCurrentActiveSearchResult(pipelineFeedback?.data[activeFeedbackIndex]?.search_history);
  }, [feedbackDataTableParams.current?.currentPage]);

  useEffect(() => {
    if (renamePipelineNameStatus === StatusCodes.SUCCESS) {
      workspaceNavigate(`/pipelines/${pipeline.name}`, {
        replace: true,
        state: { pipelineName },
      });
    }
  }, [renamePipelineNameStatus]);

  const handleDocumentsDownload = useCallback(() => {
    const { name, status } = pipeline;
    exportPipelineDocuments({ name, status });
  }, [pipeline.name, pipeline.status]);

  const goToPipelinesPage = () => {
    workspaceNavigate('/pipelines');
  };

  const goToStudio = () => {
    workspaceNavigate(`/pipelines/${pipelineName}/builder`);
  };

  const goToPromptExplorer = () => {
    workspaceNavigate(`/prompt-explorer/${pipelineName}`);
  };

  // Feedback

  const getFeedbacks = (payload: {
    currentPage: number;
    pageSize: number;
    searchValue: string;
    sortValue?: string;
    filterValues?: SelectedFilters;
  }) => {
    if (!pipeline.pipeline_id) return;

    const externalFilters = {
      bookmarked: [feedbackBookmarkFilter],
    };
    const filterValues = {
      ...externalFilters,
      ...(payload?.filterValues || {}),
    };

    const params = {
      pipelineId: pipeline.pipeline_id,
      ...payload,
      filterValues,
    };
    getPipelineFeedback(params);
    feedbackDataTableParams.current = {
      ...params,
    };
  };

  useEffect(() => {
    if (!pipeline.pipeline_id) return;
    getFeedbacks({
      currentPage: 1,
      pageSize: 10,
      searchValue: '',
    });
  }, [pipeline.pipeline_id]);

  const onDetailedQueryViewClose = () => {
    setCurrentActiveFeedbackIndex(0);
    setCurrentActiveSearchResult(null);
  };

  const onFeedbackTableQueryClick = (idx?: number) => {
    if (isNil(idx)) return;

    setCurrentActiveFeedbackIndex(idx);
    setCurrentActiveSearchResult(pipelineFeedback?.data[idx]?.search_history);
  };

  const onBookmarkFilterClick = (bookmarked: boolean) => {
    const {
      filterValues: currentfilterValues,
      searchValue,
      sortValue,
    } = feedbackDataTableParams.current!;

    const filterValue = {
      ...feedbackBookmarkFilter,
      value: bookmarked,
    };
    const filterValues = {
      ...currentfilterValues,
      bookmarked: [filterValue],
    };
    const params = {
      currentPage: 1,
      pageSize: 10,
      searchValue,
      sortValue,
      filterValues,
    };

    setFeedbackBookmarkFilter(filterValue);
    getFeedbacks(params);

    feedbackDataTableParams.current = {
      ...feedbackDataTableParams.current,
      ...params,
    };
  };

  const getSingleFeedbackData = () => {
    const feedback = pipelineFeedback?.data[currentActiveFeedbackIndex];
    if (!feedback) return null;

    const { score, tags } = feedback;

    return { score, tags };
  };

  const getFeedbackExtraQueryInfo = () => {
    if (!currentActiveSearchResult) return null;

    const {
      rank,
      search: { filters, created_at: createdAt, duration },
    } = currentActiveSearchResult;
    const { created_by: user } = pipelineFeedback?.data?.[currentActiveFeedbackIndex] || {};

    return { rank, filters, createdAt, user: user || null, duration };
  };

  const onPreviousFeedbackDataPointClick = () => {
    const isFirstItem = currentActiveFeedbackIndex === 0;
    const { currentPage, ...restOfParams } = feedbackDataTableParams.current!;

    if (isFirstItem && currentPage > 1) {
      getFeedbacks({ currentPage: currentPage - 1, ...restOfParams });
      return;
    }

    if (isFirstItem) return;

    const previousFeedbackIdx = currentActiveFeedbackIndex - 1;
    const nextSearchResults = pipelineFeedback?.data[previousFeedbackIdx]?.search_history;

    setCurrentActiveFeedbackIndex(previousFeedbackIdx);
    setCurrentActiveSearchResult(nextSearchResults);
  };

  const onNextFeedbackDataPointClick = () => {
    const isLastItem = currentActiveFeedbackIndex === pipelineFeedback.data.length - 1;
    const { currentPage, ...restOfParams } = feedbackDataTableParams.current!;
    const { has_more: hasMore } = pipelineFeedback;

    if (isLastItem && hasMore) {
      getFeedbacks({ currentPage: currentPage + 1, ...restOfParams });
      return;
    }

    if (isLastItem) return;

    const nextFeedbackIdx = currentActiveFeedbackIndex + 1;
    const nextSearchResults = pipelineFeedback?.data[nextFeedbackIdx]?.search_history;

    setCurrentActiveFeedbackIndex(nextFeedbackIdx);
    setCurrentActiveSearchResult(nextSearchResults);
  };

  const getPipelineStatusLabel = () => {
    const isPipelineIdle = (status: PipelineStatusCodes) => status === PipelineStatusCodes.IDLE;

    if (pipelineInProgress(queryStatus)) {
      if (
        queryStatus === PipelineStatusCodes.DEPLOYMENT_IN_PROGRESS ||
        queryStatus === PipelineStatusCodes.DEPLOYMENT_SCHEDULED
      )
        return DEPLOYMENT_IN_PROGRESS_LABEL;
      return UNDEPLOYMENT_IN_PROGRESS_LABEL;
    }

    if (queryStatus === PipelineStatusCodes.DEPLOYMENT_FAILED) return DEPLOYMENT_FAILED_LABEL;
    if (queryStatus === PipelineStatusCodes.UNDEPLOYMENT_FAILED) return UNDEPLOYMENT_FAILED_LABEL;
    if (queryStatus === PipelineStatusCodes.IDLE) return IDLE_STATUS_LABEL;

    if (queryStatus !== PipelineStatusCodes.DEPLOYED && !isPipelineIdle(queryStatus))
      return IndexingStatusMessages.NOT_INDEXED;

    if (pipelinePendingFileCount > 0) return IndexingStatusMessages.IN_PROGRESS;
    if (pipelinePendingFileCount === 0 && pipelineFailedFileCount > 0)
      return IndexingStatusMessages.PARTIALLY_INDEXED;
    if (pipelinePendingFileCount === 0 && pipelineFailedFileCount === 0)
      return IndexingStatusMessages.INDEXED;

    return '';
  };

  const getTabItems = () => {
    const tabsItems = [
      {
        label: OVERVIEW_TAB_LABEL,
        key: PipelineDetailsTabsKeys.OVERVIEW,
        children: (
          <>
            <PipelineStatistics
              pipelineStatistics={pipelineStatistics}
              pipelineIndexingData={pipelineIndexingData}
              setActiveTabPipelineDetailsPage={(tabName: string) => {
                setActiveTab(tabName);
                dispatch(setActiveTabPipelineDetailsPage(tabName));
              }}
            />
            <PipelineStatisticsAnswers statisticsAnswers={pipelineStatisticsAnswers} />
            {pipelineLatestQueries.data && pipelineLatestQueries.data.length > 0 && (
              <PipelineLatestQueries
                latestQueries={pipelineLatestQueries}
                getLatestQueries={(limit) => getLatestQueries(limit)}
                loading={loadingLatestQueriesStatus === StatusCodes.IN_PROGRESS}
              />
            )}
            <PipelineFeedbackTable
              pipelineId={pipeline.pipeline_id}
              pipelineName={pipeline.name}
              feedback={pipelineFeedback}
              tags={pipelineFeedbackTagsByPipelineId[pipeline.pipeline_id] ?? { data: [] }}
              getFeedbacks={getFeedbacks}
              selectedSortValue={selectedFeedbackSortValue}
              bookmarkFilter={feedbackBookmarkFilter}
              onQueryClick={onFeedbackTableQueryClick}
              onBookmarkFilterClick={onBookmarkFilterClick}
            />
          </>
        ),
      },
      {
        label: LOGS_TAB_LABEL,
        key: PipelineDetailsTabsKeys.LOGS,
        children: LogExplorer ? (
          <LogExplorer
            status={pipeline.status}
            logs={pipelineLogs}
            getData={(payload: ILogParams) =>
              dispatch(getPipelinelogs({ pipelineName, ...payload }))
            }
            exportData={() => dispatch(exportPipelineLogsCSV(pipelineName))}
            polling={{
              start: (payload: ILogParams) =>
                dispatch(startPollingPipelineLogs({ pipelineName, ...payload })),
              stop: () => dispatch(stopPollingPipelineLogs()),
            }}
            loading={loadingPipelineLogs}
            lastDeployedAt={pipeline.last_deployed_at}
            hasOriginFilter={true}
          />
        ) : null,
      },
      {
        label: SETTINGS_TAB_LABEL,
        key: PipelineDetailsTabsKeys.SETTINGS,
        children: <PipelineSettings pipelineId={pipeline.pipeline_id} />,
      },
    ];

    return tabsItems;
  };

  const getAlertMessagePipelineStatus = () => {
    const pipelineStatusMessage = PIPELINE_STATUS_MESSAGES.find((statusMessage) => {
      return statusMessage.status === getPipelineStatusLabel();
    });
    let showAlert = false;
    let description = null;

    if (pipelineStatusMessage && pipelineStatusMessage.status === IndexingStatusMessages.IDLE) {
      description = interpolateString(pipelineStatusMessage.message, {
        idleTimeout: pipelineIdleTimeout,
        activate: (
          <Button
            type="link"
            className={styles.pipelineDetails_alertLink}
            onClick={() => dispatch(activatePipeline({ pipelineName: pipeline.name }))}
          >
            {ACTIVATE_PIPELINE_BUTTON_LABEL}
          </Button>
        ),
      });

      return (
        <Alert
          message={pipelineStatusMessage.title}
          description={description}
          type={pipelineStatusMessage.type}
          className={styles.pipelineDetails_pipelineStatusMessage}
        />
      );
    }

    if (pipelineStatusMessage) {
      if (
        pipelineStatusMessage.status === IndexingStatusMessages.INDEXED &&
        pipelineLogsUnhealthy[pipelineName]
      ) {
        description = (
          <>
            {pipelineIndexingData.indexed_no_documents_file_count > 0 && SKIPPED_FILES_MESSAGE}
            {interpolateString(pipelineStatusMessage.message, {
              logs: (
                <Button
                  type="link"
                  className={styles.pipelineDetails_alertLink}
                  onClick={() => {
                    setActiveTab(PipelineDetailsTabsKeys.LOGS);
                    dispatch(setActiveTabPipelineDetailsPage(PipelineDetailsTabsKeys.LOGS));
                  }}
                >
                  {LOGS_LINK}
                </Button>
              ),
            })}
          </>
        );
        showAlert = true;
      }

      if (pipelineStatusMessage.status !== IndexingStatusMessages.INDEXED) {
        description = interpolateString(pipelineStatusMessage.message, {
          documentation: (
            <a href={pipelineStatusMessage.documentation} target="_blank" rel="noreferrer">
              {DOCUMENTATION_LINK}
            </a>
          ),
        });
        showAlert = true;
      }

      if (showAlert)
        return (
          <Alert
            message={pipelineStatusMessage.title}
            description={description}
            type={pipelineStatusMessage.type}
            className={styles.pipelineDetails_pipelineStatusMessage}
          />
        );
    }

    return null;
  };

  return (
    <>
      <UpgradeBanner category={LimitCategory.PIPELINES} />
      <div className="content-wrapper_padding">
        {pipeline && (
          <div className={styles.pipelineDetails}>
            <PipelineDetailsHeader
              pipeline={pipeline}
              pipelineName={pipelineName!}
              deployPipelineHandler={(name) => dispatch(deployPipeline(name))}
              undeployPipelineHandler={(name) => dispatch(undeployPipeline(name))}
              onSharePipelineHandler={() => setPipelineToShare(true)}
              deletePipelineHandler={(name) => dispatch(deletePipeline(name))}
              onActivatePipelineHandler={(name) =>
                dispatch(activatePipeline({ pipelineName: name }))
              }
              goToPipelinesPageHandler={goToPipelinesPage}
              fetchPipelineIndexingHandler={(name) => dispatch(fetchPipelineIndexing(name))}
              fetchPipelineHandler={(name) => dispatch(fetchPipeline({ pipelineName: name }))}
              pipelineIndexingData={pipelineIndexingData}
              goToStudio={goToStudio}
              duplicatePipeline={(name) => {
                if (isLimitedUser && totalPipelines >= FREE_TIER_LIMITS.PIPELINES) {
                  addNotification({
                    content: UPGRADE_POPOVER_PIPELINE_CONTENT,
                    type: NotificationType.Warning,
                  });
                  return;
                }

                dispatch(duplicatePipeline(name));
              }}
              onPromptExplorerMenuClick={goToPromptExplorer}
              setShowRenamePipelineModal={(show: boolean) => setShowRenamePipelineModal(show)}
              pipelineStatusString={getPipelineStatusLabel()}
              serviceLevel={<PipelineServiceLevelBadge serviceLevel={serviceLevel} />}
              onDocumentExportMenuClicked={handleDocumentsDownload}
            />
            {getAlertMessagePipelineStatus()}
            <PipelineServiceLevelSwitchSection />
            <Tabs
              activeKey={activeTab}
              onChange={(activeKey) => {
                setActiveTab(activeKey);
                dispatch(setActiveTabPipelineDetailsPage(activeKey));
              }}
              items={getTabItems()}
              className={styles.pipelineDetails_tabs}
            />
            {pipelineErrors.length > 0 && <MultipleErrorsPopup errors={pipelineErrors} />}
          </div>
        )}
        {pipelineToShare && (
          <ShareModal pipelineName={pipeline.name} onCancel={() => setPipelineToShare(false)} />
        )}
        {currentActiveSearchResult && (
          <DetailedQueryView
            isVisible={!!currentActiveSearchResult}
            pipelineName={pipelineName}
            pipelineId={pipeline.pipeline_id}
            showFeedbackActionButtons
            isPreviousItemButtonDisabled={
              currentActiveFeedbackIndex === 0 && feedbackDataTableParams.current?.currentPage === 1
            }
            isNextItemButtonDisabled={
              currentActiveFeedbackIndex === pipelineFeedback.data.length - 1 &&
              !pipelineFeedback.has_more
            }
            onClose={onDetailedQueryViewClose}
            searchResult={getParsedFeedbackSearchResultToSearchResult(currentActiveSearchResult)}
            feedbackData={getSingleFeedbackData()}
            extraQueryInfo={getFeedbackExtraQueryInfo()}
            onPreviousClick={onPreviousFeedbackDataPointClick}
            onNextClick={onNextFeedbackDataPointClick}
          />
        )}
        <NamePipelineModal
          openModal={showRenamePipelineModal}
          closeModal={() => setShowRenamePipelineModal(false)}
          modalTitle={RENAME_PIPELINE_MODAL_TITLE}
          okButtonText={RENAME_PIPELINE_MODAL_OK_BUTTON}
          pipelineName={pipelineName}
          renamePipeline={(payload: { pipelineName: string; newPipelineName: string }) =>
            dispatch(updatePipeline(payload))
          }
          renamePipelineNameStatus={renamePipelineNameStatus}
          resetRenamePipelineNameStatus={() => dispatch(resetRenamePipelineNameStatus)}
          pipelineMessage={message}
          resetMessage={() => dispatch(resetMessage)}
        />
        <DebugTunnelModal />
      </div>
    </>
  );
};

export default PipelineDetailsPage;
