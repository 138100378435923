import React from 'react';
import { UploadFile } from 'antd/lib/upload/interface';
import { Dayjs } from 'dayjs';
import { IPipelineLogsParamsAPI } from '@api/pipeline';
import { ITemplatesAPIParams } from '@api/prompt-explorer';
import { SelectedFilters } from '@constants/data-table';
import {
  DateRangeInterval,
  HTTPStatusCode,
  MessageCodes,
  SupportedViewerLanguage,
  UserRoles,
} from '@constants/enum/common';
import {
  FeedbackSearchResultType,
  PipelineDesiredStatusCodes,
  PipelineOutputType,
  PipelineStatusCodes,
  PipelineType,
} from '@src/constants/pipelines';
import { ICreatedBy } from './commonTypes';

export enum RequestController {
  SEARCH_QUERY = 'searchQuery',
  CHAT_QUERY = 'chatQuery',
  PROMPT_EXPLORER_QUERY = 'promptExplorerQuery',
}

export interface IAPIPaginationData<T> {
  data: T;
  has_more: boolean;
  total: number;
}

/* pipeline types */
export const RESET_PIPELINE = 'PIPELINE/RESET_PIPELINE';
export const RESET_PIPELINE_YAML = 'PIPELINE/RESET_PIPELINE_YAML';
export const RESET_NEW_PIPELINE_NAME = 'PIPELINE/RESET_NEW_PIPELINE_NAME';
export const FETCH_PIPELINE = 'PIPELINE/FETCH_PIPELINE';
export const FETCH_PIPELINE_YAML = 'PIPELINE/FETCH_PIPELINE_YAML';
export const RESET_FETCH_PIPELINE_YAML_STATUS = 'PIPELINE/RESET_FETCH_PIPELINE_YAML_STATUS';
export const SET_PIPELINE_YAML = 'PIPELINE/SET_PIPELINE_YAML';
export const SET_PIPELINE_INDEXING_YAML = 'PIPELINE/SET_PIPELINE_INDEXING_YAML';
export const SET_PIPELINE_QUERY_YAML = 'PIPELINE/SET_PIPELINE_QUERY_YAML';
export const SET_PIPELINE_EDITED_STATE = 'PIPELINE/SET_PIPELINE_EDITED_STATE';
export const SET_INDEXING_PIPELINE_EDITED_STATE = 'PIPELINE/SET_INDEXING_PIPELINE_EDITED_STATE';
export const SET_QUERY_PIPELINE_EDITED_STATE = 'PIPELINE/SET_QUERY_PIPELINE_EDITED_STATE';
export const DELETE_PIPELINE = 'PIPELINE/DELETE_PIPELINE';
export const DELETE_MULTIPLE_PIPELINES = 'PIPELINE/DELETE_MULTIPLE_PIPELINES';
export const DEPLOY_PIPELINE = 'PIPELINE/DEPLOY_PIPELINE';
export const UNDEPLOY_PIPELINE = 'PIPELINE/UNDEPLOY_PIPELINE';
export const ACTIVATE_PIPELINE = 'PIPELINE/ACTIVATE_PIPELINE';
export const CREATE_PIPELINE = 'PIPELINE/CREATE_PIPELINE';
export const RESET_VALIDATE_NEW_PIPELINE_NAME = 'PIPELINE/RESET_VALIDATE_PIPELINE_NAME';
export const VALIDATE_PIPELINE_YAML = 'PIPELINE/VALIDATE_PIPELINE_YAML';
export const RUNTIME_ISSUE_DETECTION = 'PIPELINE/RUNTIME_ISSUE_DETECTION';
export const RESET_PIPELINE_ERRORS = 'PIPELINE/RESET_PIPELINE_ERRORS';
export const RESET_RENAME_PIPELINE_NAME_STATUS = 'PIPELINE/RESET_RENAME_PIPELINE_NAME_STATUS';
export const UPDATE_PIPELINE_YAML = 'PIPELINE/UPDATE_PIPELINE_YAML';
export const UPDATE_PIPELINE = 'PIPELINE/UPDATE_PIPELINE';
export const FETCH_PIPELINES = 'PIPELINE/FETCH_PIPELINES';
export const DUPLICATE_PIPELINE = 'PIPELINE/DUPLICATE_PIPELINE';
export const RESET_PIPELINE_MESSAGE = 'PIPELINE/RESET_PIPELINE_MESSAGE';
export const SET_NEW_PIPELINE_NAME = 'PIPELINE/SET_NEW_PIPELINE_NAME';
export const START_POLLING_PIPELINES_LIST = 'PIPELINE/START_POLLING_PIPELINES_LIST';
export const STOP_POLLING_PIPELINES_LIST = 'PIPELINE/STOP_POLLING_PIPELINES_LIST';
export const START_POLLING_DEPLOYED_PIPELINES = 'PIPELINE/START_POLLING_DEPLOYED_PIPELINES';
export const STOP_POLLING_DEPLOYED_PIPELINES = 'PIPELINE/STOP_POLLING_DEPLOYED_PIPELINES';
export const START_POLLING_DRAFT_PIPELINES = 'PIPELINE/START_POLLING_DRAFT_PIPELINES';
export const STOP_POLLING_DRAFT_PIPELINES = 'PIPELINE/STOP_POLLING_DRAFT_PIPELINES';
export const START_POLLING_PIPELINE_STATUS = 'PIPELINE/START_POLLING_PIPELINE_STATUS';
export const STOP_POLLING_PIPELINE_STATUS = 'PIPELINE/STOP_POLLING_PIPELINE_STATUS';
export const START_POLLING_PIPELINE_LOGS = 'PIPELINE/START_POLLING_PIPELINE_LOGS';
export const STOP_POLLING_PIPELINE_LOGS = 'PIPELINE/STOP_POLLING_PIPELINE_LOGS';
export const START_POLLING_PIPELINE_INDEXING = 'PIPELINE/START_POLLING_PIPELINE_INDEXING';
export const STOP_POLLING_PIPELINE_INDEXING = 'PIPELINE/STOP_POLLING_PIPELINE_INDEXING';
export const FETCH_PIPELINE_INDEXING = 'PIPELINE/FETCH_PIPELINE_INDEXING';
export const EXPORT_PIPELINE_LOGS_CSV = 'PIPELINE/EXPORT_PIPELINE_LOGS_CSV';
export const SET_PIPELINE_STATISTICS = 'PIPELINE/PIPELINE_STATISTICS';
export const SET_PIPELINE_LATEST_QUERIES = 'PIPELINE/PIPELINE_LATEST_QUERIES';
export const GET_PIPELINE_LOGS = 'PIPELINE/GET_PIPELINE_LOGS';
export const CHECK_PIPELINE_LOGS_HEALTH = 'PIPELINE/CHECK_PIPELINE_LOGS_HEALTH';
export const GENERATE_PROTOTYPE_LINK = 'PIPELINE/GENERATE_PROTOTYPE_LINK';
export const DELETE_PROTOTYPE_LINK = 'PIPELINE/DELETE_PROTOTYPE_LINK';
export const RESET_PROTOTYPE_LINK = 'PIPELINE/RESET_PROTOTYPE_LINK';
export const RESET_PIPELINE_DETAILS_STATE = 'PIPELINE/RESET_PIPELINE_DETAILS_STATE';
export const GET_PIPELINE_PROTOTYPE = 'PIPELINE/GET_PIPELINE_PROTOTYPE';
export const UPDATE_PIPELINE_PROTOTYPE = 'PIPELINE/UPDATE_PIPELINE_PROTOTYPE';
export const GET_PIPELINE_PROMPTS = 'PIPELINE/GET_PIPELINE_PROMPTS';
export const GET_PIPELINE_TEMPLATES_BY_CATEGORY = 'PIPELINE/GET_PIPELINE_TEMPLATES_BY_CATEGORY';
export const GET_PIPELINE_TEMPLATES = 'PIPELINE/GET_PIPELINE_TEMPLATES';
export const GET_PIPELINE_TEMPLATE_BY_NAME = 'PIPELINE/GET_PIPELINE_TEMPLATE_BY_NAME';
export const SELECT_PIPELINE_TEMPLATES_CATEGORY = 'PIPELINE/SELECT_PIPELINE_TEMPLATES_CATEGORY';
export const GET_MORE_LIKE_THIS_PIPELINE_TEMPLATES =
  'PIPELINE/GET_MORE_LIKE_THIS_PIPELINE_TEMPLATES';
export const RESET_MORE_TEMPLATES_LIKE_THIS = 'PIPELINE/RESET_MORE_TEMPLATES_LIKE_THIS';
export const RESET_SELECTED_TEMPLATE = 'PIPELINE/RESET_SELECTED_TEMPLATE';
export const SET_SELECTED_TEMPLATE = 'PIPELINE/SET_SELECTED_TEMPLATE';
export const SET_TEMPLATES_SEARCH_VALUE = 'PIPELINE/SET_TEMPLATES_SEARCH_VALUE';
export const GET_PIPELINE_TEMPLATES_FILTER_TAGS = 'PIPELINE/GET_PIPELINE_TEMPLATES_FILTER_TAGS';
export const SELECT_PIPELINE_TEMPLATES_FILTERS = 'PIPELINE/SELECT_PIPELINE_TEMPLATES_FILTERS';
export const SELECT_PIPELINE_TEMPLATES_SORT_VALUE = 'PIPELINE/SELECT_PIPELINE_TEMPLATES_SORT_VALUE';
export const RESET_SELECTED_PIPELINE_TEMPLATES_FILTERS =
  'PIPELINE/RESET_SELECTED_PIPELINE_TEMPLATES_FILTERS';
export const SET_ACTIVE_TAB_PIPELINE_DETAILS_PAGE = 'PIPELINE/SET_ACTIVE_TAB_PIPELINE_DETAILS_PAGE';
export const SET_ACTIVE_TAB_PIPELINES_LANDING_PAGE =
  'PIPELINE/SET_ACTIVE_TAB_PIPELINES_LANDING_PAGE';
export const RESET_ACTIVE_TAB_PIPELINE_DETAILS_PAGE =
  'PIPELINE/RESET_ACTIVE_TAB_PIPELINE_DETAILS_PAGE';
export const SELECT_PIPELINES_LIST_SORT_VALUE = 'PIPELINE/SELECT_PIPELINES_LIST_SORT_VALUE';
export const EXPORT_PIPELINE_DOCUMENTS_CSV = 'PIPELINE/EXPORT_PIPELINE_DOCUMENTS_CSV';

export type AnswerOffset = { start: number; end: number }[];

/* Async Runs interfaces */

export enum RunState {
  CREATED = 'CREATED',
  STARTED = 'STARTED',
  FAILED = 'FAILED',
  ENDED = 'ENDED',
}

export interface IRun {
  name: string;
  tags: ITag[];
  status: RunState;
  created_at: string | null;
  created_by: ICreatedBy | null; // TOFIX: Remove string when job fix it
  last_edited_at: string | null;
  last_edited_by: ICreatedBy | null;
}

/* pipeline interfaces */

// TODO: Standarize `PipelineServiceLevel` and `PipelineUsageServiceLevel` in the api
export enum PipelineServiceLevel {
  PRODUCTION = 'PRODUCTION',
  DEVELOPMENT = 'DEVELOPMENT',
  DRAFT = 'DRAFT',
}

export enum DeepsetCloudVersion {
  V2 = 'v2',
}

// TODO: Extract interfaces and organize better - https://github.com/deepset-ai/haystack-hub-ui/issues/2441
export interface IPipeline {
  pipeline_id: string;
  name: string;
  indexing_yaml: string | null;
  query_yaml: string | null;
  created_at: string;
  created_by: ICreatedBy;
  edited?: boolean;
  editedIndexingYaml?: boolean;
  editedQueryYaml?: boolean;
  indexing: {
    pending_file_count: number;
    failed_file_count: number;
  };
  service_level: PipelineServiceLevel;
  indexing_details: IPipelineIndexingData;
  status: PipelineStatusCodes;
  last_edited_by: any;
  last_deployed_at?: string;
  desired_status: PipelineDesiredStatusCodes;
  supports_prompt: boolean;
  output_type: PipelineOutputType;
  idle_timeout_in_seconds: number;
  deepset_cloud_version: DeepsetCloudVersion;
}

export interface IPipelineComponentJson {
  name: string;
  type: string;
  params: Record<string, any>;
}

export interface IPipelineJsonNode {
  inputs?: unknown[];
  name: string;
}

export interface IPipelineJson {
  name: string;
  nodes: IPipelineJsonNode[];
}

export interface IYamlPipelineJsonFormat {
  version: string;
  name: string;
  components?: IPipelineComponentJson[];
  pipelines?: IPipelineJson[];
}

// Pipeline Templates

export interface IPipelineTemplate {
  author: string;
  description: string;
  best_for: string[];
  potential_applications: string[];
  expected_output: string[];
  recommended_dataset: string[];
  name: string;
  pipeline_name: string;
  pipeline_template_id: string;
  tags: ITag[];
  deepset_cloud_version: DeepsetCloudVersion;
  available_to_all_organization_types: boolean;
  // Yamls will be null if content is not available to studio users
  indexing_yaml?: string | null;
  query_yaml?: string | null;
  pipeline_type: PipelineTemplatesType;
}

export enum PipelineTemplatesCategory {
  RECOMMENDED = 'recommended',
  DOCUMENT_SEARCH = 'documentSearch',
  BASIC_QA = 'basicQA',
  ADVANCED_QA = 'advancedQA',
  VISUAL_QA = 'visualQA',
  CONVERSATIONAL = 'conversational',
  TEXT_ANALYSIS = 'textAnalysis',
  TEXT_TO_SQL = 'textToSQL',
  IMAGE_GENERATION = 'imageGeneration',
  AGENTS = 'agents',
}

export enum PipelineTemplatesType {
  INDEX = 'indexing',
  QUERY = 'query',
}

// Search

export interface IPipelineQueryPromptTemplatesParams {
  [key: string]: {
    prompt_template: string;
  };
}

export type IPipelineQueryParams = Record<string, unknown> & IPipelineQueryPromptTemplatesParams;

export type IQueryResultPrompts = Record<string, string | Record<string, unknown>> | null;

export interface ISearchResultDocument {
  content: string;
  content_type: string;
  embedding: string | null;
  file: {
    id: string;
    name: string;
  };
  meta: SearchResultMeta;
  id: string;
  result_id: string;
  score: number | null;
}

export interface ISearchResultAnswer {
  answer: string;
  context: string | null;
  document_ids: string[] | null;
  files: { id: string; name: string }[];
  meta: SearchResultMeta | IMetaAnnotations;
  offsets_in_context: AnswerOffset;
  offsets_in_document: AnswerOffset;
  prompt: string | null;
  result_id: string;
  score: number | null;
  type: PipelineType;
}

export interface ISearchResultResponse {
  query_id: string;
  results: ISearchResult[];
}

export interface ISearchResult {
  answers: ISearchResultAnswer[];
  documents: ISearchResultDocument[];
  promptTemplates?: IPipelineQueryPromptTemplatesParams;
  query: string;
  query_id: string;
  errorMessage?: string;
  infoMessage?: string;
  prompts?: IQueryResultPrompts;
  _debug?: Record<string, unknown> | null;
  debug?: Record<string, unknown> | null;
}

export interface ISearchHistoryResponse {
  documents: ISearchResultDocument[];
  rank: number;
  result: ISearchResultAnswer | null;
  search_result_history_id: string;
  type: number;
}

export type SearchResultMeta = Record<string, unknown> & {
  page?: number;
  page_number?: number;
};

export interface IMetaAnnotations extends SearchResultMeta {
  annotations: {
    _references: IReferencesMetaAnnotation[]; // Change place if API changes
  };
}

export interface IReferencesMetaAnnotation {
  answer_start_idx: number;
  answer_end_idx: number;
  doc_start_idx: number;
  doc_end_idx: number;
  document_id: string;
  document_position: number;
  label: Groundedness;
}

export interface IMappedReferencesMetaAnnotation extends IReferencesMetaAnnotation {
  content: string;
  file_name: string;
  file_id: string;
  meta: SearchResultMeta;
  result_id: string;
}

export interface IBaseSearchResultComponentProps<T = ISearchResultDocument | ISearchResultAnswer> {
  result: T;
  queryId: string;
  pipelineId: string;
  pipelineName: string;
  displayFeedbackOptions?: boolean;
  displayRelevanceScore?: boolean;
  displayFileSources?: boolean;
  displayFileOptions?: boolean;
  displayMetadata?: boolean;
  feedback?: (() => React.ReactElement | null) | null;
}

// Search Query stream

export enum QueryStreamMessageType {
  RESULT = 'result',
  DELTA = 'delta',
  ERROR = 'error',
}

export interface IQueryStreamRawMessageData {
  type: QueryStreamMessageType;
  query_id: string;
  result?: ISearchResult | null;
  delta?: { text: string };
  error?: string;
}

export interface IQueryStreamMessageData {
  query_id: string;
  result?: ISearchResult | null;
  text: string;
  error?: string;
}

export interface IMessage {
  type: MessageCodes;
  content: string | React.ReactNode;
  status?: HTTPStatusCode;
  title?: string;
}

// TODO(INDEXES): Remove indexing part, once the separtion of pipelines is done
export interface IPipelineIndexingData {
  failed_file_count: number;
  indexed_file_count: number;
  indexed_no_documents_file_count: number;
  pending_file_count: number;
  status: string;
  total_file_count: number;
}

export interface IPipelineStatistics {
  total_queries: number;
  manual_feedback_input: number;
  experiments_created: number;
  users_provided_feedback: number;
  avg_feedback_per_query: number;
  indexed_files: number;
  total_documents: number;
}

export interface IPipelineLogsExceptionTrace {
  filename: string;
  line_number: number;
  name: string;
}

export interface IPipelineLogsException {
  type: string;
  value: string;
  trace: IPipelineLogsExceptionTrace[];
}

export interface IPipelineLog {
  log_id: string;
  level: string;
  logged_at: string;
  message: string;
  exceptions: IPipelineLogsException[];
  extra_fields: Record<string, unknown>;
}

export interface IPipelineLogsParams extends IPipelineLogsParamsAPI {
  pipelineName: string;
  fetchMore?: boolean;
  searchValue?: string;
  filterValues?: SelectedFilters;
}

export interface ISearchHistory {
  duration: number;
  pipeline: {
    name: string;
  };
  request: {
    filters: any;
    query: string;
    params: IPipelineQueryParams;
  };
  response: ISearchHistoryResponse[];
  search_history_id: string;
  time: string;
  user: {
    family_name: string;
    given_name: string;
    user_id: string;
  };
}

export type PipelineLatestQueries = IAPIPaginationData<ISearchHistory[]>;

export interface IPipelineStatisticsAnswers {
  correct_answers: number;
  wrong_answers: number;
  wrong_answers_with_correct_document: number;
  accuracy_of_answers: number;
  latency: number;
}

export interface IValidatePipelineParams {
  config?: string;
  indexing_yaml?: string;
  query_yaml?: string;
}

/* thunk async types */
export const PENDING = 'pending';
export const FULFILLED = 'fulfilled';
export const REJECTED = 'rejected';

/* metadata filters types */
export const GET_PIPELINE_META = 'METADATA_FILTERS/GET_PIPELINE_META';
export const GET_METADATA_VALUES = 'METADATA_FILTERS/GET_METADATA_VALUES';
export const SELECT_METADATA_FILTER_VALUES = 'METADATA_FILTERS/SELECT_METADATA_FILTER_VALUES';
export const SET_APPLIED_METADATA_FILTER_VALUES =
  'METADATA_FILTERS/SET_APPLIED_METADATA_FILTER_VALUES';

/* search types */
export const SET_SEARCH_PIPELINE = 'SEARCH/SET_PIPELINE';
export const SEARCH = 'SEARCH';
export const APPEND_SEARCH_STREAM = 'SEARCH/APPEND_SEARCH_STREAM';
export const SEND_CHAT_QUERY = 'SEARCH/SEND_CHAT_QUERY';
export const APPEND_CHAT_STREAM = 'SEARCH/APPEND_CHAT_STREAM';
export const QUERY_PROMPT_EXPLORER = 'SEARCH/QUERY_PROMPT_EXPLORER';
export const APPEND_PROMPT_EXPLORER_QUERY_STREAM = 'SEARCH/APPEND_PROMPT_EXPLORER_QUERY_STREAM';
export const ADD_PROMPT_EXPLORER_INFO_RESULT = 'SEARCH/ADD_PROMPT_EXPLORER_INFO_RESULT';
export const RESET_SEARCH_RESULT = 'SEARCH/RESET_RESULT';
export const RESET_QUERY_RESULT_PROMPT_EXPLORER = 'SEARCH/RESET_QUERY_RESULT_PROMPT_EXPLORER';
export const RESET_CHAT_RESULTS = 'SEARCH/RESET_CHAT_RESULTS';
export const CREATE_SEARCH_SESSION = 'SEARCH/CREATE_SEARCH_SESSION';
export const GET_SEARCH_SESSIONS = 'SEARCH/GET_SEARCH_SESSIONS';
export const OPEN_REFERENCE_DRAWER = 'SEARCH/OPEN_REFERENCE_DRAWER';
export const RESET_REFERENCE_DRAWER = 'SEARCH/RESET_REFERENCE_DRAWER';
export const TOGGLE_PROMPT_MODAL = 'SEARCH/TOGGLE_PROMPT_MODAL';
export const TOGGLE_DEBUG_MODE = 'SEARCH/TOGGLE_DEBUG_MODE';
export const SET_QUERY_PIPELINE_PARAMS = 'SEARCH/SET_QUERY_PIPELINE_PARAMS';

export interface INumericRangeMetadataFilterValue {
  min: number;
  max: number;
}

export interface IDateMetadataFilterValue {
  min: string;
  max: string;
}

export type MetadataFiltersValue =
  | string[]
  | boolean
  | INumericRangeMetadataFilterValue
  | IDateMetadataFilterValue;

export enum Groundedness {
  GROUNDED = 'grounded',
  NOT_GROUNDED = 'not_grounded',
}

export enum SearchFileAction {
  DOWNLOAD = 'download',
  PREVIEW = 'preview',
  OPEN = 'open',
  DOCUMENT = 'document',
}

export enum SearchResultMoreOption {
  VIEW_PROMPT = 'viewPrompt',
  VIEW_FILTERS = 'viewFilters',
  VIEW_PARAMS = 'viewParams',
}

/* user types */
export const GET_USER = 'USER/GET_USER';
export const FETCH_USER_INFO = 'USER/FETCH_USER_INFO';
export const SET_USER_INFO = 'USER/SET_USER_INFO';
export const UPDATE_USER_INFO = 'USER/UPDATE_USER_INFO';
export const RESET_USER_INFO = 'USER/RESET_USER_INFO';
export const USER_LOGIN = 'USER/LOGIN';
export const USER_LOGOUT = 'USER/LOGOUT';
export const USER_ASSIGN_WORKSPACE_ROLE = 'USER/ASSIGN_WORKSPACE_ROLE';
export const USER_REMOVE_USER_FROM_WORKSPACE = 'USER/USER_REMOVE_USER_FROM_WORKSPACE';

/* user interfaces */

export interface INamedEntity {
  name: string;
}

export interface IWorkspaceRoleItem extends INamedEntity {
  role: string;
}

export enum OrganizationType {
  LIMITED = 'DEEPSET_STUDIO_WITH_LIMITS',
  STANDARD = 'FULL_DEEPSET_CLOUD',
}

export interface IUserData {
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  userID: string;
  role: UserRoles;
  organization: string;
  isAdmin: boolean;
  isEmailVerified: boolean;
  workspaces: IWorkspaceRoleItem[] | [];
  userflowSignature?: string;
  isLimitedUser: boolean;
  organizationType: OrganizationType;
}

export interface IAuthUserData {
  given_name?: string;
  family_name?: string;
  name?: string;
  nickname?: string;
  email?: string;
  sub?: string;
  picture?: string;
  email_verified?: boolean;
}

export interface IUserProfileData {
  name: string;
  oauth_provider: string;
  user_id: string;
  organization: IOrganization;
}

export interface IOrganization {
  name: string;
  organization_id: string;
  role: UserRoles;
  workspaces: IWorkspaceRoleItem[];
}

/* organization types */
export interface IOrganizationData {
  organizationName: string;
  organizationId: string;
  maxWorkspaces?: number;
}

export interface IAuthOrganization {
  organization_id: string;
  name: string;
}

export const SET_ORGANIZATION_INFO = 'ORGANIZATION/SET_ORGANIZATION_INFO';
export const CHANGE_ORGANIZATION = 'ORGANIZATION/CHANGE_ORGANIZATION';
export const RESET_ORGANIZATION_INFO = 'ORGANIZATION/RESET_ORGANIZATION_INFO';
export const FETCH_ORGANIZATIONS = 'ORGANIZATION/FETCH_ORGANIZATIONS';
export const FETCH_USERS = 'ORGANIZATION/FETCH_USERS';
export const INVITE_USER = 'ORGANIZATION/INVITE_USER';
export const CHANGE_USER_ROLE = 'ORGANIZATION/CHANGE_USER_ROLE';
export const REMOVE_USER = 'ORGANIZATION/REMOVE_USER';
export const RESET_ORGANIZATION_MESSAGE = 'ORGANIZATION/RESET_ORGANIZATION_MESSAGE';
export const FETCH_API_KEYS = 'ORGANIZATION/FETCH_API_KEYS';
export const DELETE_API_KEY = 'ORGANIZATION/DELETE_API_KEY';
export const GENERATE_API_KEY = 'ORGANIZATION/GENERATE_API_KEY';
export const FETCH_MODEL_REGISTRY_TOKEN = 'ORGANIZATION/FETCH_MODEL_REGISTRY_TOKEN';
export const POST_MODEL_REGISTRY_TOKEN = 'ORGANIZATION/POST_MODEL_REGISTRY_TOKEN';
export const DELETE_MODEL_REGISTRY_TOKEN = 'ORGANIZATION/DELETE_MODEL_REGISTRY_TOKEN';
export const FETCH_WORKSPACES = 'ORGANIZATION/FETCH_WORKSPACES';
export const ADD_WORKSPACE = 'ORGANIZATION/ADD_WORKSPACE';
export const DELETE_WORKSPACE = 'ORGANIZATION/DELETE_WORKSPACE';
export const SET_CURRENT_WORKSPACE = 'ORGANIZATION/SET_CURRENT_WORKSPACE';

export interface IInviteUserData {
  email: string;
  family_name: string;
  given_name: string;
  role: UserRoles;
  workspace_ids: string[];
}

export interface IAPIKey {
  expires_at: string;
  api_token_id: string;
  api_key: string;
  user: {
    given_name: string;
    family_name: string;
  };
}

export type IAPIKeysData = IAPIPaginationData<IAPIKey[]>;

export interface IModelRegistryToken {
  provider_domain: string;
  provider: string;
  tokenId: string;
  invalid: boolean;
}

export enum ModelProviders {
  huggingface = 'huggingface',
  openai = 'openai',
  cohere = 'cohere',
  aws = 'aws-bedrock',
  snowflake = 'snowflake',
  azureOpenai = 'azure-openai',
  azureAI = 'azure-document-intelligence',
  unstructured = 'unstructured',
  deepl = 'deepl',
  google = 'google',
  nvidia = 'nvidia',
  voyageai = 'voyage-ai',
  searchapi = 'searchapi',
  wandb = 'wandb-ai',
  mongodb = 'mongodb',
  together = 'together-ai',
}

/* layout types */
export const RESET_LAYOUT_STATE = 'LAYOUT/RESET_LAYOUT_STATE';
export const UPDATE_SELECTED_KEYS = 'SIDEBAR/UPDATE_SELECTED_KEYS';
export const UPDATE_OPEN_KEYS = 'SIDEBAR/UPDATE_OPEN_KEYS';
export const UPDATE_COLLAPSED = 'SIDEBAR/UPDATE_COLLAPSED';
export const UPDATE_ROUTE_NAME = 'LAYOUT/UPDATE_ROUTE_NAME';
export const RESET_ROUTE_NAME = 'LAYOUT/RESET_ROUTE_NAME';

/* data types */
export interface IFile {
  file_id: string;
  name: string;
  size: number;
  url: string;
  characters: number;
  created_at: string;
  meta: Record<string, unknown>;
  languages: string[] | null;
}

export interface IFilePreview {
  content: string | ArrayBuffer;
  displayLanguage?: SupportedViewerLanguage;
}

export enum MetadataValueType {
  KEYWORD = 'KEYWORD',
  NUMERICAL = 'NUMERICAL',
  DATE = 'DATE',
  LIST = 'LIST',
}

export const GET_WORKSPACE_FILES = 'DATA/GET_WORKSPACE_FILES';
export const DELETE_FILE = 'DATA/DELETE_FILE';
export const DELETE_MULTIPLE_FILES = 'DATA/DELETE_MULTIPLE_FILES';
export const DELETE_ALL_FILES = 'DATA/DELETE_ALL_FILES';
export const RESET_FILES_MESSAGE = 'DATA/RESET_FILES_MESSAGE';
export const DOWNLOAD_FILE = 'DATA/DOWNLOAD_FILE';
export const GET_FILE_CONTENT = 'DATA/GET_FILE_CONTENT';
export const UPDATE_FILE_META = 'DATA/UPDATE_FILE_META';
export const RESET_FILE_CONTENT = 'DATA/RESET_FILE_CONTENT';
export const SELECT_FILE_SORT_VALUE = 'DATA/SELECT_FILE_SORT_VALUE';
export const SELECT_FILE_DOCUMENT = 'DATA/SELECT_FILE_DOCUMENT';
export const GET_SAMPLE_DATASET_LIST = 'DATA/GET_SAMPLE_DATASET_LIST';
export const IMPORT_SAMPLE_DATASET = 'DATA/IMPORT_SAMPLE_DATASET';

export interface IEvalset {
  evaluation_set_id: string;
  name: string;
  created_at: string;
  matched_labels: number;
  total_labels: number;
}

export interface ILabel {
  label_id: string;
  question: string;
  answer?: string;
  answer_start?: number;
  answer_end?: number;
  context?: string;
  external_file_name?: string;
  file_id?: string;
  meta: any;
}

export const GET_WORKSPACE_EVALSETS = 'DATA/GET_WORKSPACE_EVALSETS';
export const DELETE_EVALSET = 'DATA/DELETE_EVALSET';
export const DELETE_MULTIPLE_EVALSETS = 'DATA/DELETE_MULTIPLE_EVALSETS';
export const EXPORT_EVALSET = 'DATA/EXPORT_EVALSET';
export const EXPORT_EVALSET_CSV = 'DATA/EXPORT_EVALSET_CSV';
export const IMPORT_EVALSET = 'DATA/IMPORT_EVALSET';
export const RESET_EVALSET_MESSAGE = 'DATA/RESET_EVALSET_MESSAGE';
export const GET_EVALSET_CONTENT = 'DATA/GET_EVALSET_CONTENT';

/* experiments types */
export const SELECT_SORT_VALUE = 'EXPERIMENTS/SELECT_SORT_VALUE';
export const SELECT_PREDICTIONS_SORT_VALUE = 'EXPERIMENTS/SELECT_PREDICTIONS_SORT_VALUE';
export const RESET_EXPERIMENTS_MESSAGE = 'EXPERIMENTS/RESET_EXPERIMENTS_MESSAGE';
export const RESET_CURRENT_EXPERIMENT = 'EXPERIMENTS/RESET_CURRENT_EXPERIMENT';
export const GET_EVALUATION_RUN = 'EXPERIMENTS/GET_EVALUATION_RUN';
export const GET_EXPERIMENTS = 'EXPERIMENTS/GET_EXPERIMENTS';
export const GET_FILTER_TAGS = 'EXPERIMENTS/GET_FILTER_TAGS';
export const GET_FILTER_STATUSES = 'EXPERIMENTS/GET_FILTER_STATUSES';
export const GET_FILTER_USERS = 'EXPERIMENTS/GET_FILTER_USERS';
export const CREATE_EVALUATION_RUN = 'EXPERIMENTS/CREATE_EVALUATION_RUN';
export const UPDATE_EVALUATION_RUN = 'EXPERIMENTS/UPDATE_EVALUATION_RUN';
export const DELETE_EVALUATION_RUN = 'EXPERIMENTS/DELETE_EVALUATION_RUN';
export const DELETE_MULTIPLE_EVALUATION_RUNS = 'EXPERIMENTS/DELETE_MULTIPLE_EVALUATION_RUNS';
export const START_EVALUATION_RUN = 'EXPERIMENTS/START_EVALUATION_RUN';
export const START_MULTIPLE_EVALUATION_RUNS = 'EXPERIMENTS/START_MULTIPLE_EVALUATION_RUNS';
export const SAVE_AND_START_EVALUATION_RUN = 'EXPERIMENTS/SAVE_AND_START_EVALUATION_RUN';
export const START_POLLING_RUNS_STATUS = 'EXPERIMENTS/START_POLLING_RUNS_STATUS';
export const STOP_POLLING_RUNS_STATUS = 'EXPERIMENTS/STOP_POLLING_RUNS_STATUS';
export const START_POLLING_RUN_STATUS = 'EXPERIMENTS/START_POLLING_RUN_STATUS';
export const STOP_POLLING_RUN_STATUS = 'EXPERIMENTS/STOP_POLLING_RUN_STATUS';
export const DOWNLOAD_EVALUATION_RUN = 'EXPERIMENTS/DOWNLOAD_EVALUATION_RUN';
export const GET_RUN_PREDICTIONS = 'EXPERIMENTS/GET_RUN_PREDICTIONS';
export const UPDATE_EVALUATION_RUN_COMMENT = 'EXPERIMENTS/UPDATE_EVALUATION_RUN_COMMENT';
export const GET_TAGS = 'EXPERIMENTS/GET_TAGS';
export const RESET_NEW_EVALUATION_RUN_STATUS = 'EXPERIMENTS/RESET_NEW_EVALUATION_RUN_STATUS';

export interface IEvalNodeResult {
  node_name: string;
  node_type: string;
  // Retriever metrics
  integrated_mean_reciprocal_rank?: number;
  integrated_mean_average_precision?: number;
  integrated_recall_single_hit?: number;
  integrated_recall_multi_hit?: number;
  integrated_normal_discounted_cumulative_gain?: number;
  integrated_precision?: number;
  // Reader metrics
  integrated_exact_match?: number;
  integrated_f1?: number;
  integrated_sas?: number;
  isolated_exact_match?: number;
  isolated_f1?: number;
  isolated_sas?: number;
  integrated_groundedness?: number;
  integrated_no_answer_ratio?: number;
  integrated_query_latency?: number;
}

export interface IExperiment extends IRun {
  eval_run_id: string;
  logs: ILog[];
  comment: string;
  parameters: {
    pipeline_name: string;
    evaluation_set_name: string;
    pipeline_snapshot_at: string;
    pipeline_snapshot_yaml: string;
  };
  eval_results: IEvalNodeResult[];
}

export interface ILog {
  created_at: string;
  level: string;
  msg: string;
}

export interface ITag {
  tag_id: string;
  name: string;
}

export interface IStatusFilter {
  status: RunState;
}

export interface IUserFilter {
  given_name: string | null;
  user_id: string | null;
}

/* Upload file types */
export const ADD_UPLOAD_FILES = 'UPLOAD/ADD_UPLOAD_FILES';
export const START_UPLOADING = 'UPLOAD/START_UPLOADING';
export const FINISH_UPLOADING = 'UPLOAD/FINISH_UPLOADING';
export const RESET_UPLOAD_DATA = 'UPLOAD/RESET_UPLOAD_DATA';

export interface IUploadData {
  files: ExtendedUploadFile[];
  uploadFn: <T extends unknown[]>(...args: T) => Promise<unknown>;
  afterUploadFn: <T extends unknown[]>(...args: T) => unknown;
}

export enum FileUploadStatus {
  uploading = 'uploading',
  success = 'success',
  error = 'error',
}

export type ExtendedUploadFile = UploadFile & {
  uploadStatus?: FileUploadStatus;
  errorMessage?: string;
  errorCode?: number;
  size?: number;
};

export enum FileDrawerCollapseTabOptions {
  FILE = 'file',
  DOCUMENT = 'document',
}

/* notification types */
export const ADD_NOTIFICATION = 'NOTIFICATION/ADD_NOTIFICATION';
export const ADD_SEQUENTIAL_NOTIFICATION = 'NOTIFICATION/ADD_SEQUENTIAL_NOTIFICATION';
export const RESET_NOTIFICATIONS = 'NOTIFICATION/RESET_NOTIFICATIONS';
export const REMOVE_NOTIFICATION_BY_KEY = 'NOTIFICATION/REMOVE_NOTIFICATION_BY_KEY';

export enum NotificationMode {
  Message = 'message',
  Notification = 'notification',
}

export enum NotificationType {
  Info = 'info',
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Loading = 'loading',
}

export enum NotificationDuration {
  short = 3,
  default = 4.5,
  long = 7,
  infinite = 0,
}

export interface INotification {
  key: string;
  mode?: NotificationMode;
  type?: NotificationType;
  title?: string;
  duration?: NotificationDuration;
  content: string;
  onClose?: () => void;
}

/* Prompt explorer types */
export const GET_CUSTOM_PROMPT_TEMPLATES = 'PROMPT_EXPLORER/GET_CUSTOM_PROMPT_TEMPLATES';
export const CREATE_CUSTOM_PROMPT_TEMPLATE = 'PROMPT_EXPLORER/CREATE_CUSTOM_PROMPT_TEMPLATE';
export const UPDATE_CUSTOM_PROMPT_TEMPLATE = 'PROMPT_EXPLORER/UPDATE_CUSTOM_PROMPT_TEMPLATE';
export const DELETE_CUSTOM_PROMPT_TEMPLATE = 'PROMPT_EXPLORER/DELETE_CUSTOM_PROMPT_TEMPLATE';
export const GET_PUBLIC_PROMPT_TEMPLATES = 'PROMPT_EXPLORER/GET_PUBLIC_PROMPT_TEMPLATES';
export const SELECT_PROMPT_TEMPLATES_SORT_VALUE =
  'PROMPT_EXPLORER/SELECT_PROMPT_TEMPLATES_SORT_VALUE';
export const GET_PROMPT_TEMPLATES_FILTER_TAGS = 'PROMPT_EXPLORER/GET_PROMPT_TEMPLATES_FILTER_TAGS';
export const GET_PROMPT_TEMPLATES_FILTER_USER = 'PROMPT_EXPLORER/GET_PROMPT_TEMPLATES_FILTER_USER';
export const UPDATE_PIPELINE_PROMPT = 'PROMPT_EXPLORER/UPDATE_PIPELINE_PROMPT';

export type PlaygroundId = string;

export enum PromptTemplatesModalTabOption {
  CUSTOM = 'custom',
  DEEPSET = 'deepset',
}

export enum PromptTemplatesModalMoreOptionsKey {
  DELETE = 'delete',
}

export interface IPromptNode {
  text: string;
  template_name: string | null;
}

export interface IPromptPlayground {
  id: string;
  pipelineName?: string;
  selectedPromptNode: string;
  prompts: Record<string, IPromptNode>;
  session?: string | null;
  filters?: Record<string, MetadataFiltersValue>;
}

export interface ISearchSession {
  search_history: {
    query: string;
    search_history_id: string;
    created_at: string;
  } | null;
  search_session_id: string;
}

export interface PromptTemplate {
  prompt_template_id: string;
  name: string;
  description: string;
  text: string;
  user: {
    given_name: string;
    family_name: string;
    user_id: string;
  };
  tags: string[];
  version?: string;
}

export interface PublicPromptTemplate {
  public_prompt_template_id: number;
  name: string;
  description: string;
  text: string;
  meta: Record<string, unknown | unknown[]>;
  tags: ITag[];
  version: string;
}

export interface CustomPromptTemplate {
  name: string;
  description: string;
  tags: string[];
  text: string;
}

export interface ITemplatesParams extends ITemplatesAPIParams {
  fetchMore?: boolean;
  sortValue?: string;
  searchValue?: string;
  filterValues?: SelectedFilters;
}

/* Labeling */

export const GET_LABELING_PROJECTS = 'LABELING/GET_LABELING_PROJECTS';
export const CREATE_LABELING_PROJECT = 'LABELING/CREATE_LABELING_PROJECT';
export const GET_LABELING_PROJECT = 'LABELING/GET_LABELING_PROJECT';
export const GET_LABELING_PROJECT_STATS = 'LABELING/GET_LABELING_PROJECT_STATS';
export const GET_LABELING_PROJECT_CREATOR = 'LABELING/GET_LABELING_PROJECT_CREATOR';
export const GET_LABELING_PROJECT_LAST_EDITOR = 'LABELING/GET_LABELING_PROJECT_LAST_EDITOR';
export const UPDATE_LABELING_PROJECT = 'LABELING/UPDATE_LABELING_PROJECT';
export const DELETE_LABELING_PROJECT = 'LABELING/DELETE_LABELING_PROJECT';
export const CREATE_DOCUMENT_RETRIEVAL_LABEL = 'LABELING/CREATE_DOCUMENT_RETRIEVAL_LABEL';
export const UPDATE_DOCUMENT_RETRIEVAL_LABEL = 'LABELING/UPDATE_DOCUMENT_RETRIEVAL_LABEL';
export const EXPORT_LABELS = 'LABELING/EXPORT_LABELS';
export const GET_LABELING_PROJECT_LABELS = 'LABELING/GET_LABELING_PROJECT_LABELS';
export const GET_LABELING_PROJECT_LABELS_AS_LABELED_RESULTS =
  'LABELING/GET_LABELING_PROJECT_LABELS_AS_LABELED_RESULTS';
export const GET_LABELING_PROJECT_LABELS_AS_SEARCH_RESULT =
  'LABELING/GET_LABELING_PROJECT_LABELS_AS_SEARCH_RESULT';
export const RESET_LABELING_PROJECT_LABELS_AS_SEARCH_RESULT =
  'LABELING/RESET_LABELING_PROJECT_LABELS_AS_SEARCH_RESULT';

export interface ILabelingProject {
  project_id: string;
  name: string;
  created_at: string;
  created_by: string;
  description?: string;
  pipeline_id: string | null;
  query_target: number | null;
  annotation_guideline: string | null;
  total_labels?: number;
  labeled_queries?: number | null;
  last_edited_at?: string;
  last_edited_by?: string;
}

export interface ILabelingLeaderboardUser {
  nickname: string;
  amount: number;
}
export interface ILabelingProjectStats {
  total_labels: number;
  labeled_queries: number;
  labels_per_user: ILabelingLeaderboardUser[];
  labeled_queries_per_user: ILabelingLeaderboardUser[];
}

export interface EditableLabelingProjectFields {
  name: string;
  description?: string;
  pipelineId: string | null;
  labelingGuideline: string | null;
  queryTarget: number | null;
}

export enum LabelingDetailsTabOptions {
  OVERVIEW = 'overview',
  SETTINGS = 'settings',
}

export enum LabelRelevance {
  RELEVANT = 'RELEVANT',
  NOT_RELEVANT = 'NOT_RELEVANT',
  UNDECIDED = 'UNDECIDED',
}

export interface ILabelingProjectLabel {
  relevance: LabelRelevance;
  comment: string | null;
  search_result_history_id: string;
  rank: number;
  score: number | null;
  document: unknown;
  query_id: string;
  query: string;
  created_at: string;
  created_by: string;
  label_id: string;
}

// document preview types

export interface IFileDocContent {
  value: Blob | string;
  contentId?: string;
}

export interface ISelectedFileDocument {
  contents: IFileDocContent[];
  fileName: string;
  fileId: string;
  resultId?: string;
  isDocumentPreview?: boolean;
  meta?: Record<string, unknown>;
}

export interface IHighlightData {
  answer?: string | null;
  offsetsInDocument?: AnswerOffset | null;
  context?: string | null;
  page?: number;
  label?: string;
  contentId?: string;
}

// Observability types
export const GET_OBSERVABILITY_METRIC_VALUE = 'OBSERVABILITY/GET_OBSERVABILITY_METRIC_VALUE';
export const SELECT_OBSERVABILITY_PIPELIBNE = 'OBSERVABILITY/SELECT_OBSERVABILITY_PIPELIBNE';
export const SELECT_OBSERVABILITY_DATE_RANGE = 'OBSERVABILITY/SELECT_OBSERVABILITY_DATE_RANGE';
export const RESET_SELECTED_OBSERVABILITY_DATE = 'OBSERVABILITY/RESET_SELECTED_OBSERVABILITY_DATE';
export const RESET_SELECTED_OBSERVABILITY_PIPELINE =
  'OBSERVABILITY/RESET_SELECTED_OBSERVABILITY_PIPELINE';
export const EXPORT_OBSERVABILITY_CHART = 'OBSERVABILITY/EXPORT_OBSERVABILITY_CHART';

export enum ObservabilityMetric {
  GROUNDEDNESS = 'groundedness',
  TOTAL_QUERIES = 'totalqueries',
  DOCUMENT_REFERENCES_PER_RANK = 'referenceperrank',
}

export enum DateRangeType {
  TODAY = 'today',
  SEVEN_DAYS = '7d',
  FOURTEEN_DAYS = '14d',
  THIRTY_DAYS = '30d',
  SIXTY_DAYS = '60d',
  CUSTOM = 'custom',
}

export interface IDateRange {
  type: DateRangeType;
  interval: DateRangeInterval | null;
  from: Dayjs | null;
  to: Dayjs | null;
}

export interface IReferencesPerRank {
  rank: number;
  amount: number;
  percent: number;
}

export enum ObservabilityChartType {
  AREA = 'area',
  LINE = 'line',
  BAR = 'bar',
}

export enum ChartExportType {
  CSV = 'csv',
  JPEG = 'jpeg',
  PDF = 'pdf',
  PNG = 'png',
}

// Usage types
export const GET_USAGE_LIMITS = 'METERING/GET_USAGE_LIMITS';
export const GET_USAGE_METRICS = 'METERING/GET_USAGE_METRICS';
export const GET_ALL_TIME_USAGE_METRICS = 'METERING/GET_ALL_TIME_USAGE_METRICS';
export const GET_USAGE_HOURS = 'METERING/GET_USAGE_HOURS';
export const GET_PIPELINES_USAGE = 'METERING/GET_PIPELINES_USAGE';
export const RESET_USAGE_DATA = 'METERING/RESET_USAGE_DATA';
export const SELECT_PIPELINES_USAGE_FILTERS = 'METERING/SELECT_PIPELINES_USAGE_FILTERS';
export const SELECT_PIPELINES_USAGE_SORT_VALUE = 'METERING/SELECT_PIPELINES_USAGE_SORT_VALUE';

export enum PipelineUsageServiceLevel {
  PRODUCTION = 'production',
  DEVELOPMENT = 'development',
  DRAFT = 'draft',
}

export interface IPipelineUsage {
  pipeline_id: string;
  pipeline_name: string;
  workspace_id: string;
  workspace_name: string;
  production_hours: number;
  development_hours: number;
  document_storage_units: number;
  documents: number;
  service_level: PipelineUsageServiceLevel;
}

export interface IUsageMetrics {
  development_hours?: number;
  document_storage_units?: number;
  development_pipelines?: number;
  production_pipelines?: number;
  production_hours?: number;
}

export interface IUsageLimits extends IUsageMetrics {
  pricing_plan_id: string;
}

export interface IUsageMetricConst {
  label: string;
  tooltip: string | React.ReactNode;
  limitLabel: string;
  metricUnit?: string;
}

// ECharts

export type ChartTimeSeriesEntry = (string | number)[];

export enum EChartAxisType {
  VALUE = 'value',
  CATEGORY = 'category',
  TIME = 'time',
  LOG = 'log',
}

/* Shared Prototype */

export const SET_SHARED_PROTOTYPE_TOKEN_DATA = 'SHARED_PROTOTYPE/SET_SHARED_PROTOTYPE_TOKEN_DATA';

export interface ISharedPrototypeTokenData {
  shareID: string;
  workspaceName: string;
  workspaceID: string;
  jobID?: string;
  showMetadataFilters?: boolean;
  showFiles?: boolean;
  loginRequired?: boolean;
}

/* Jobs */
export const GET_JOB = 'JOBS/GET_JOB';
export const GET_JOBS = 'JOBS/GET_JOBS';
export const START_POLLING_JOB = 'JOBS/START_POLLING_JOB';
export const STOP_POLLING_JOB = 'JOBS/STOP_POLLING_JOB';
export const START_POLLING_JOBS = 'JOBS/START_POLLING_JOBS';
export const STOP_POLLING_JOBS = 'JOBS/STOP_POLLING_JOBS';
export const GET_JOB_RESULTS = 'JOBS/GET_JOB_RESULTS';
export const CREATE_JOB = 'JOBS/CREATE_JOB';
export const START_JOB = 'JOBS/START_JOB';
export const CREATE_AND_START_JOB = 'JOBS/CREATE_AND_START_JOB';
export const START_MULTIPLE_JOBS = 'JOBS/START_MULTIPLE_JOBS';
export const DELETE_JOB = 'JOBS/DELETE_JOB';
export const DELETE_MULTIPLE_JOBS = 'JOBS/DELETE_MULTIPLE_JOBS';
export const GET_QUERY_SET = 'JOBS/GET_QUERY_SET';
export const GET_QUERY_SETS = 'JOBS/GET_QUERY_SETS';
export const RESET_CREATE_JOB_STATE = 'JOBS/RESET_CREATE_JOB_STATE';
export const RESET_JOB_DETAILS_STATE = 'JOBS/RESET_JOB_DETAILS_STATE';
export const SET_OPEN_CREATE_JOB_DRAWER = 'JOBS/SET_OPEN_CREATE_JOB_DRAWER';
export const SET_CREATE_JOB_STEP = 'JOBS/SET_CREATE_JOB_STEP';
export const SELECT_JOB_TYPE = 'JOBS/SELECT_JOB_TYPE';
export const SELECT_QUERY_RUN = 'JOBS/SELECT_QUERY_RUN';
export const SELECT_QUERY_SET_FILE = 'JOBS/SELECT_QUERY_SET_FILE';
export const SELECT_JOB_PIPELINE = 'JOBS/SELECT_JOB_PIPELINE';
export const SET_JOB_NAME = 'JOBS/SET_JOB_NAME';
export const SELECT_JOB_TAGS = 'JOBS/SELECT_JOB_TAGS';
export const GET_JOB_TAGS = 'JOBS/GET_JOB_TAGS';
export const RESET_SHARED_JOB_LINK = 'JOBS/RESET_SHARED_JOB_LINK';
export const GENERATE_SHARED_JOB_LINK = 'JOBS/GENERATE_SHARED_JOB_LINK';
export const GET_SHARED_JOB = 'JOBS/GET_SHARED_JOB';
export const REVOKE_SHARED_JOB_LINK = 'JOBS/REVOKE_SHARED_JOB_LINK';
export const UPDATE_SHARED_JOB = 'JOBS/UPDATE_SHARED_JOB';
export const EXPORT_JOB_RESULTS = 'JOBS/EXPORT_JOB_RESULTS';
export const SELECT_JOB_LIST_SORT_VALUE = 'JOBS/SELECT_JOB_LIST_SORT_VALUE';
export const GET_JOB_LIST_FILTER_USER = 'JOBS/GET_JOB_LIST_FILTER_USER';
export const GET_JOB_LIST_FILTER_TAGS = 'JOBS/GET_JOB_LIST_FILTER_TAGS';
export const GET_JOB_RESULTS_FILTER_FILES = 'JOBS/GET_JOB_RESULTS_FILTER_FILES';
export const SELECT_RESULTS_SORT_VALUE = 'JOBS/SELECT_RESULTS_SORT_VALUE';

export interface IJobPayload {
  description: string;
  execution_mode: QueryRunOption;
  name: string;
  pipeline_id: string;
  query_set_id: string;
}

export interface IJob extends IRun {
  job_id: string;
  executed_at: string | null;
  pipeline_id: string;
  pipeline_name: string;
  query_set_id: string;
  answer_ratio: number | null;
  executed_requests: number | null;
  total_requests: number | null;
  duration: number | null;
  execution_mode: QueryRunOption;
}

// TODO: Standarize API response
export interface IJobQueryResponseResult
  extends Omit<
    ISearchResult,
    'promptTemplates' | 'errorMessage' | 'prompts' | 'answers' | 'documents'
  > {
  files: { file_id: string; file_name: string }[];
  answers: Omit<ISearchResultAnswer, 'files' | 'prompt'>[];
  documents: Omit<ISearchResultDocument, 'file'>[];
}

export interface IJobQueryResult {
  answer: string;
  context: string;
  duration: number;
  file: string;
  filters: Record<string, unknown> | null;
  params: Record<string, unknown> | null;
  job_result_id: string;
  no_answer: boolean;
  prompt: string;
  prompts: Record<string, string> | null;
  query: string;
  created_at: string;
  query_label: string;
  group_name: string;
  response: IJobQueryResponseResult;
}

export interface IQuerySet {
  query_set_id: string;
  name: string;
  created_at: string;
  total_queries: number;
}

export enum CreateJobStep {
  SELECT_JOB = 0,
  SELECT_QUERY_SET = 1,
  SELECT_PIPELINE = 2,
  NAME_JOB = 3,
}

export enum JobType {
  BATCH_QA = 'batchQA',
}

export enum QueryRunOption {
  ACROSS_FILES = 'ACROSS_FILES',
  AGAINST_EACH_FILE = 'PER_FILE',
}

export enum MoreActionsOption {
  Details = 'DETAILS',
  Delete = 'DELETE',
  Edit = 'EDIT',
}

export enum SharedAssetType {
  JOB = 'job',
  PIPELINE = 'pipeline',
}

/* Pipeline Feedback */
export const PROVIDE_SEARCH_RESULT_FEEDBACK = 'PIPELINE_FEEDBACK/PROVIDE_SEARCH_RESULT_FEEDBACK';
export const UPDATE_SEARCH_RESULT_FEEDBACK = 'PIPELINE_FEEDBACK/UPDATE_SEARCH_RESULT_FEEDBACK';
export const SET_PROVIDED_FEEDBACK_BY_RESULT_ID =
  'PIPELINE_FEEDBACK/SET_PROVIDED_FEEDBACK_BY_RESULT_ID';
export const GET_PIPELINE_FEEDBACK = 'PIPELINE_FEEDBACK/GET_PIPELINE_FEEDBACK';
export const RESET_PIPELINE_FEEDBACK_DATA = 'PIPELINE_FEEDBACK/RESET_PIPELINE_FEEDBACK_DATA';
export const EXPORT_PIPELINE_FEEDBACK_CSV = 'PIPELINE_FEEDBACK/EXPORT_PIPELINE_FEEDBACK_CSV';
export const GET_PIPELINE_FEEDBACK_FILTER_USER =
  'PIPELINE_FEEDBACK/GET_PIPELINE_FEEDBACK_FILTER_USER';
export const GET_PIPELINE_FEEDBACK_TAGS = 'PIPELINE_FEEDBACK/GET_PIPELINE_FEEDBACK_TAGS';
export const RESET_PIPELINE_FEEDBACK_TAGS = 'PIPELINE_FEEDBACK/RESET_PIPELINE_FEEDBACK_TAGS';
export const CREATE_PIPELINE_FEEDBACK_TAG = 'PIPELINE_FEEDBACK/CREATE_PIPELINE_FEEDBACK_TAG';
export const DELETE_PIPELINE_FEEDBACK_TAG = 'PIPELINE_FEEDBACK/DELETE_PIPELINE_FEEDBACK_TAG';
export const SELECT_FEEDBACK_SORT_VALUE = 'PIPELINE_FEEDBACK/SELECT_FEEDBACK_SORT_VALUE';
export const RESET_PROVIDED_FEEDBACK_STATUS = 'PIPELINE_FEEDBACK/RESET_PROVIDED_FEEDBACK_STATUS';

export enum FeedbackType {
  INACCURATE = 'INACCURATE',
  FAIRLY_ACCURATE = 'FAIRLY_ACCURATE',
  ACCURATE = 'ACCURATE',
}

export interface IFeedbackSearchData {
  created_at: string;
  duration: number;
  query: string;
  filters: any;
  // user: { // TODO: Add when api ready
  //   given_name: string;
  //   family_name: string;
  //   email: string;
  // };
}

export type PipelineFeedback = IAPIPaginationData<IPipelineFeedbackData[]>;

export interface IFeedbackSearchResult {
  search_history_result_id: string;
  search: IFeedbackSearchData;
  query_id: string;
  answer: string | null;
  offsets_in_context: AnswerOffset | null;
  documents: ISearchResultDocument[];
  rank: number | null;
  type: FeedbackSearchResultType;
  score: number | null;
  files: { id: string; name: string }[];
  context: string | null;
  prompt: string | null;
  meta: Record<string, unknown> | null;
}

export interface IPipelineFeedbackData {
  feedback_id: string;
  organization_id: string;
  workspace_id: string;
  pipeline_id: string;
  result_id: string;
  score: FeedbackType | null;
  comment: string;
  bookmarked: boolean | null;
  created_by: ICreatedBy;
  tags: ITag[];
  search_history: IFeedbackSearchResult;
}

// Secrets Management types
export const RESET_SECRETS_MANAGEMENT_STATUSES =
  'SECRETS_MANAGEMENT/RESET_SECRETS_MANAGEMENT_STATUSES';
export const GET_ORGANIZATION_SECRETS = 'SECRETS_MANAGEMENT/GET_ORGANIZATION_SECRETS';
export const ADD_ORGANIZATION_SECRET = 'SECRETS_MANAGEMENT/ADD_ORGANIZATION_SECRET';
export const DELETE_ORGANIZATION_SECRET = 'SECRETS_MANAGEMENT/DELETE_ORGANIZATION_SECRET';

export interface ISecret {
  secret_id: string;
  name: string;
}
