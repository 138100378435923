import React from 'react';
import { Flex, Tag, Typography } from 'antd';
import { sortCollectionAlphabeticallyBy } from '@utils/sort';
import { CREATED_BY_LABEL } from '@constants/details-header';
import { TAGS_LABEL } from '@constants/experiments';
import { ITag } from '@redux/types/types';
import styles from './detailsHeader.module.scss';

const { Paragraph } = Typography;

// TODO: Use this component in the Pipeline Details and Experiment Details pages

interface IDetailsHeaderProps {
  title: string;
  subtitle: string;
  description?: string;
  createdBy?: string;
  tags?: ITag[];
  actions?: React.ReactNode;
  status?: React.ReactNode;
  serviceLevel?: React.ReactNode;
}

// TODO: Refactor, use more antd components
const DetailsHeader = (props: IDetailsHeaderProps) => {
  const { title, subtitle, description, createdBy, tags, actions, status, serviceLevel } = props;
  return (
    <Flex vertical gap="small">
      <Flex justify="space-between" align="center" className={styles.header}>
        <div className={styles.headerContent}>
          {serviceLevel}
          <h4 className={styles.header_title}>{title}</h4>
          <div className={styles.header_subtitle}>{subtitle}</div>
          {status && <div>{status}</div>}
        </div>
        {actions && <div className={styles.headerActions}> {actions} </div>}
      </Flex>
      {description && <Paragraph className="small-text">{description}</Paragraph>}
      {createdBy && (
        <Paragraph type="secondary" className="small-text">
          {CREATED_BY_LABEL} {createdBy}
        </Paragraph>
      )}
      {!!tags?.length && (
        <section>
          <span className={styles.infoSection_label}>{TAGS_LABEL}:</span>
          {sortCollectionAlphabeticallyBy(tags, 'name').map((tag: ITag) => {
            const { tag_id: tagID, name } = tag;
            return (
              <Tag key={tagID} style={{ marginRight: 2 }}>
                {name}
              </Tag>
            );
          })}
        </section>
      )}
    </Flex>
  );
};

export default DetailsHeader;
