import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Menu, Tag } from 'antd';
import { selectTemplatesCategory } from '@redux/actions/pipelineTemplatesActions';
import {
  pipelineTemplatesByCategorySelector,
  selectedCategorySelector,
} from '@redux/selectors/pipelineTemplatesSelectors';
import { PipelineTemplatesCategory } from '@redux/types/types';
import styles from './categoriesSidebar.module.scss';
import { CATEGORY_ALL_TEMPLATES, CATEGORY_LABELS } from '../constants/pipeline-templates';

const ALL_TEMPLATES_KEY = 'all';

interface ICategoriesSidebarProps {
  categories: PipelineTemplatesCategory[];
}

const CategoriesSidebar = ({ categories = [] }: ICategoriesSidebarProps) => {
  const dispatch = useDispatch();
  const templatesByCategory = useSelector(pipelineTemplatesByCategorySelector);
  const selectedCategory = useSelector(selectedCategorySelector);

  const onItemClick = (category: PipelineTemplatesCategory | typeof ALL_TEMPLATES_KEY) => {
    dispatch(selectTemplatesCategory(category === ALL_TEMPLATES_KEY ? null : category));
  };

  const getCategoryTotalTemplates = (category: PipelineTemplatesCategory) => {
    const { total } = templatesByCategory[category] || {};
    return total || 0;
  };

  // Renders

  const renderMenuLabel = (category: PipelineTemplatesCategory) => {
    return (
      <div className={styles.menuLabel}>
        {CATEGORY_LABELS[category]} <Tag>{getCategoryTotalTemplates(category)}</Tag>
      </div>
    );
  };

  // Menu items
  const categoryMenuItems = [
    {
      label: CATEGORY_ALL_TEMPLATES,
      key: ALL_TEMPLATES_KEY,
    },
    ...categories.map((category) => ({
      label: renderMenuLabel(category),
      key: category,
    })),
  ];

  return (
    <div className={styles.container}>
      <Menu
        selectedKeys={[selectedCategory ?? ALL_TEMPLATES_KEY]}
        items={categoryMenuItems}
        onClick={({ key }) => onItemClick(key as PipelineTemplatesCategory)}
      />
    </div>
  );
};

export default CategoriesSidebar;
