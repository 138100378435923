import { httpExt } from './base-http-external';

/* Get shared prototype */
export function getSharedPrototypeApi(
  workspaceName: string,
  shareID: string,
  token: string | null,
) {
  const headers = token ? { Authorization: `Bearer ${token}` } : {};
  return httpExt.get(`/api/v1/workspaces/${workspaceName}/shared_prototypes/${shareID}`, {
    headers,
  });
}

/* Get shared job results */
export function getSharedJobResultApi(workspaceId: string, shareID: string, token: string | null) {
  const headers = token ? { Authorization: `Bearer ${token}` } : {};
  return httpExt.get(`/api/v2/workspaces/${workspaceId}/shared_jobs/${shareID}`, {
    headers,
  });
}

/* Create external user */
export function createExternalUserApi(
  workspaceName: string,
  token: string | null,
  userID?: string,
) {
  const headers = token ? { Authorization: `Bearer ${token}` } : {};
  return httpExt.post(
    `/api/v1/workspaces/${workspaceName}/shared_prototype_users${
      userID ? `?existing_user_id=${userID}` : ''
    }`,
    {},
    {
      headers,
    },
  );
}

export const getPipelineMetadataApi = (pipelineName: string, workspaceName: string) => {
  return httpExt.get(`/api/v1/workspaces/${workspaceName}/pipelines/${pipelineName}/meta`);
};

export const getMetadataValuesApi = (
  pipelineName: string,
  fieldName: string,
  query: string,
  limit?: number,
  workspaceName?: string,
) => {
  return httpExt.get(
    `/api/v1/workspaces/${workspaceName}/pipelines/${pipelineName}/meta/${fieldName}`,
    {
      params: { query: `*${query}*`, limit },
    },
  );
};

export const getPipelineMinMaxAggregationMetadataApi = (
  pipelineName: string,
  fieldName: string,
  workspaceName?: string,
) => {
  return httpExt.get(
    `/api/v1/workspaces/${workspaceName}/pipelines/${pipelineName}/meta/${fieldName}/aggregation`,
  );
};
