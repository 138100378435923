import produce from 'immer';
import {
  ADD_NOTIFICATION,
  ADD_SEQUENTIAL_NOTIFICATION,
  INotification,
  REMOVE_NOTIFICATION_BY_KEY,
  RESET_NOTIFICATIONS,
} from '@redux/types/types';

const getNotificationKey = (notification: INotification) =>
  notification.key || notification.content;

interface IInitialStateProps {
  notifications: INotification[];
  sequentialNotifications: INotification[];
}

export const initialNotificationState: IInitialStateProps = {
  notifications: [],
  sequentialNotifications: [],
};

const notificationReducer = (state = initialNotificationState, action: any) =>
  produce(state, (draft) => {
    const localDraft = draft;
    switch (action.type) {
      case ADD_NOTIFICATION:
        // Adding a unique key to the notification
        // to avoid displaying the same notification multiple times
        const newNotification = {
          ...action.payload,
          key: getNotificationKey(action.payload),
        };
        localDraft.notifications.push(newNotification);
        break;
      case ADD_SEQUENTIAL_NOTIFICATION:
        const newSequentialNotification = {
          ...action.payload,
          key: getNotificationKey(action.payload),
        };
        localDraft.sequentialNotifications.push(newSequentialNotification);
        break;
      case RESET_NOTIFICATIONS:
        localDraft.notifications = initialNotificationState.notifications;
        break;
      case REMOVE_NOTIFICATION_BY_KEY:
        localDraft.notifications = localDraft.notifications.filter(
          (notification) => notification.key !== action.payload,
        );
        break;
      default:
        break;
    }
    return localDraft;
  });

export default notificationReducer;
