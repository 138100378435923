import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { handleErrorNotification } from '@utils/error';
import { startDebugTunnelApi } from '@api/debug-tunnel';
import { RootState } from '@redux/store';
import { IPipeline } from '@redux/types/types';
import { NotificationType } from '@redux/types/types';
import { createResetAction } from '@redux/utils/createReducerWithReset';
import { AsyncActionType, DEBUG_TUNNEL_INFO_STORE_PATH } from '../constants/debugTunnel';

export interface DebugTunnelInfo {
  pipelineName: string;
  code: string;
  url: string;
  vscode_url: string;
}

type DebugTunnelPayload = Pick<IPipeline, 'pipeline_id' | 'name'>;

interface DebugTunnelState {
  debugTunnelInfo: DebugTunnelInfo | null;
}

export const initialState: DebugTunnelState = {
  debugTunnelInfo: null,
};

export const startDebugTunnel = createAsyncThunk(
  AsyncActionType.START_DEBUG_TUNNEL,
  async (payload: DebugTunnelPayload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(createResetAction([DEBUG_TUNNEL_INFO_STORE_PATH]));
      const response = await startDebugTunnelApi(payload.pipeline_id);
      const data = response.data as Omit<DebugTunnelInfo, 'pipelineName'>;

      return {
        ...data,
        pipelineName: payload.name,
      };
    } catch (error) {
      handleErrorNotification(error, dispatch, {
        type: NotificationType.Error,
      });
      return rejectWithValue(error);
    }
  },
);

export const debugTunnelSlice = createSlice({
  name: 'debugTunnel',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(startDebugTunnel.fulfilled, (state, action) => {
      state.debugTunnelInfo = action.payload;
    });
  },
});

export default debugTunnelSlice.reducer;

// SELECTORS
const selectDebugTunnelState = (state: RootState) => state.debugTunnelStore;

const getDebugTunnelInfo = (state: RootState) => selectDebugTunnelState(state).debugTunnelInfo;

// createSelector
export const selectDebugTunnelInfo = createSelector(
  [getDebugTunnelInfo],
  (debugTunnelInfo) => debugTunnelInfo,
);
