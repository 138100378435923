import React, { useEffect, useState } from 'react';
import { Form, Input } from 'antd';
import {
  IAwsMarketplaceSubscriptionData,
  IPersonalInfoValues,
  PERSONAL_INFO_FIELDS,
  PERSONAL_INFO_FIELDS_ERROR_MESSAGE,
  PERSONAL_INFO_STEP_SUBTITLE,
  PERSONAL_INFO_STEP_TITLE,
} from '@constants/aws-marketplace';
import StepCard from '@components/cards/stepCard/StepCard';
import styles from './personalInfo.module.scss';

interface IAwsMarketplacePersonalInfoProps {
  currentStep: number;
  totalSteps: number;
  subscriptionData: IAwsMarketplaceSubscriptionData;
  onNextButtonPress: (formValues: IPersonalInfoValues) => void;
  nextButtonLabel?: string;
  loading?: boolean;
}

const AwsMarketplacePersonalInfo = (props: IAwsMarketplacePersonalInfoProps) => {
  const { totalSteps, currentStep, subscriptionData, onNextButtonPress, nextButtonLabel, loading } =
    props;
  const [enableNextButton, setEnableNextButton] = useState(true);

  const [form] = Form.useForm();

  const initialValues: IPersonalInfoValues = {
    name: subscriptionData.name,
    lastName: subscriptionData.lastName,
    email: subscriptionData.email,
    companyName: subscriptionData.companyName,
  };

  useEffect(() => {
    if (
      initialValues.name &&
      initialValues.lastName &&
      initialValues.email &&
      initialValues.companyName
    ) {
      setEnableNextButton(false);
    } else setEnableNextButton(true);
  }, []);

  const handleFormChanged = (
    _changedValues: IPersonalInfoValues,
    allValues: IPersonalInfoValues,
  ) => {
    if (
      allValues.name &&
      allValues.lastName &&
      allValues.email &&
      allValues.companyName &&
      form.getFieldError('email').length === 0
    ) {
      setEnableNextButton(false);
    } else setEnableNextButton(true);
  };

  return (
    <StepCard
      title={PERSONAL_INFO_STEP_TITLE}
      subtitle={PERSONAL_INFO_STEP_SUBTITLE}
      currentStep={currentStep}
      totalSteps={totalSteps}
      onNextButtonPress={() => onNextButtonPress(form.getFieldsValue())}
      isNextButtonDisabled={enableNextButton}
      isNextButtonLoading={loading}
      nextButtonLabel={nextButtonLabel}
    >
      <Form
        form={form}
        name="awsRegisterPersonalInfoForm"
        initialValues={initialValues}
        onValuesChange={handleFormChanged}
      >
        <div className={styles.formContainer}>
          <div className={styles.formContainer_inputField}>
            <div>
              <span className={styles.formContainer_inputField_required}>*</span>
              {PERSONAL_INFO_FIELDS.name.label}
            </div>
            <Form.Item
              name="name"
              rules={[{ required: true, message: PERSONAL_INFO_FIELDS_ERROR_MESSAGE.name }]}
            >
              <Input placeholder={PERSONAL_INFO_FIELDS.name.placeholder} />
            </Form.Item>
          </div>
          <div className={styles.formContainer_inputField}>
            <div>
              <span className={styles.formContainer_inputField_required}>*</span>
              {PERSONAL_INFO_FIELDS.lastName.label}
            </div>
            <Form.Item
              name="lastName"
              rules={[{ required: true, message: PERSONAL_INFO_FIELDS_ERROR_MESSAGE.lastName }]}
            >
              <Input placeholder={PERSONAL_INFO_FIELDS.lastName.placeholder} />
            </Form.Item>
          </div>
          <div className={styles.formContainer_inputField}>
            <div>
              <span className={styles.formContainer_inputField_required}>*</span>
              {PERSONAL_INFO_FIELDS.email.label}
            </div>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: PERSONAL_INFO_FIELDS_ERROR_MESSAGE.email },
                { type: 'email', message: PERSONAL_INFO_FIELDS_ERROR_MESSAGE.emailInvalid },
              ]}
            >
              <Input placeholder={PERSONAL_INFO_FIELDS.email.placeholder} />
            </Form.Item>
          </div>
          <div className={styles.formContainer_inputField}>
            <div>
              <span className={styles.formContainer_inputField_required}>*</span>
              {PERSONAL_INFO_FIELDS.companyName.label}
            </div>
            <Form.Item
              name="companyName"
              rules={[{ required: true, message: PERSONAL_INFO_FIELDS_ERROR_MESSAGE.companyName }]}
            >
              <Input placeholder={PERSONAL_INFO_FIELDS.companyName.placeholder} />
            </Form.Item>
          </div>
        </div>
      </Form>
    </StepCard>
  );
};

export default AwsMarketplacePersonalInfo;
