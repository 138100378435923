import { GetTokenSilentlyOptions, LogoutOptions, RedirectLoginOptions } from '@auth0/auth0-react';

/* eslint-disable @typescript-eslint/no-unused-vars */
export const auth = {
  getAccessTokenSilently: (options: GetTokenSilentlyOptions) => false,
  loginWithRedirect: (options: RedirectLoginOptions) => false,
  logout: (options: LogoutOptions) => false,
  getCurrentOrganizationId: (): string | null => null,
  setAccessTokenSilently: (func: any) => {
    auth.getAccessTokenSilently = func;
  },
  setloginWithRedirect: (func: any) => {
    auth.loginWithRedirect = func;
  },
  setLogout: (func: any) => {
    auth.logout = func;
  },
  setCurrentOrganizationId: (organizationId: string) => {
    auth.getCurrentOrganizationId = () => organizationId;
  },
};
