import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Tooltip } from 'antd';
import { getStringsInitials } from '@utils/string';
import { ICreatedBy } from '@redux/types/commonTypes';

export const USER_AVATAR_TEST_ID = 'userAvatar';

interface IUserAvatarProps {
  user: ICreatedBy | null;
  size?: 'small' | 'default' | 'large';
}

const UserAvatar: React.FC<IUserAvatarProps> = ({ user, size = 'small' }) => {
  const { given_name: givenName, family_name: familyName } = user || {};
  if (!givenName && !familyName)
    return <Avatar size={size} icon={<UserOutlined />} data-testid={USER_AVATAR_TEST_ID} />;

  return (
    <Tooltip title={`${givenName || ''} ${familyName || ''}`}>
      <Avatar size={size} data-testid={USER_AVATAR_TEST_ID}>
        {getStringsInitials(givenName || '', familyName || '')}
      </Avatar>
    </Tooltip>
  );
};

export default UserAvatar;
