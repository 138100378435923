import React from 'react';
import { SyncOutlined } from '@ant-design/icons';
import { Popover, Spin, Tag, Tooltip } from 'antd';
import { pipelineInProgress } from '@utils/pipelines';
import {
  ACTIVATING_PIPELINE_LABEL_STATUS,
  DEPLOYMENT_FAILED_LABEL,
  DEPLOYMENT_IN_PROGRESS_LABEL,
  INDEXING_STATUS_HEADER,
  IndexingStatusMessages,
  PipelineStatusCodes,
  POP_CONTENT_FAILED,
  POP_CONTENT_INDEXED,
  POP_CONTENT_PENDING,
  POP_CONTENT_SKIPPED,
  POP_CONTENT_TOTAL,
  UNDEPLOYMENT_FAILED_LABEL,
  UNDEPLOYMENT_IN_PROGRESS_LABEL,
} from '@constants/pipelines';
import { IPipeline, IPipelineIndexingData } from '@redux/types/types';
import styles from './pipelineStatusTag.module.scss';

const TAG_TEST_ID = 'pipeline_statusTag';

interface IPipelineStatusTagProps {
  pipeline: IPipeline;
  indexingData?: IPipelineIndexingData;
  getIndexingDetails?: (name: string) => void;
}

// TODO(INDEXES): Remove indexing part, once the separtion of pipelines is done
const PipelineStatusTag = (props: IPipelineStatusTagProps) => {
  const { pipeline, indexingData, getIndexingDetails } = props;
  const { name, indexing, status: queryStatus } = pipeline;
  const {
    pending_file_count: pipelinePendingFileCount,
    failed_file_count: pipelineFailedFileCount,
  } = indexing;

  if (queryStatus === PipelineStatusCodes.DEPLOYMENT_FAILED)
    return (
      <Tag data-testid={TAG_TEST_ID} color="error">
        {DEPLOYMENT_FAILED_LABEL}
      </Tag>
    );
  if (queryStatus === PipelineStatusCodes.UNDEPLOYMENT_FAILED)
    return (
      <Tag data-testid={TAG_TEST_ID} color="error">
        {UNDEPLOYMENT_FAILED_LABEL}
      </Tag>
    );

  if (queryStatus === PipelineStatusCodes.IDLE) {
    return (
      // TODO: Remove or update tooltip after activate pipeline feature is implemented
      <Tooltip title="Pipeline is on standby to save resources. Run a query to activate it.">
        <Tag data-testid={TAG_TEST_ID}>{IndexingStatusMessages.IDLE}</Tag>
      </Tooltip>
    );
  }

  if (queryStatus === PipelineStatusCodes.ACTIVATING) {
    return (
      <Tag data-testid={TAG_TEST_ID} icon={<SyncOutlined spin />} color="processing">
        {ACTIVATING_PIPELINE_LABEL_STATUS}
      </Tag>
    );
  }

  if (pipelineInProgress(pipeline.status))
    return (
      <Tag data-testid={TAG_TEST_ID} icon={<SyncOutlined spin />} color="processing">
        {queryStatus === PipelineStatusCodes.DEPLOYMENT_IN_PROGRESS ||
        queryStatus === PipelineStatusCodes.DEPLOYMENT_SCHEDULED
          ? DEPLOYMENT_IN_PROGRESS_LABEL
          : UNDEPLOYMENT_IN_PROGRESS_LABEL}
      </Tag>
    );

  if (queryStatus !== PipelineStatusCodes.DEPLOYED)
    return <Tag data-testid={TAG_TEST_ID}>{IndexingStatusMessages.NOT_INDEXED}</Tag>;

  let indexingStatus = {
    message: IndexingStatusMessages.NOT_INDEXED as string,
    color: 'default',
  };

  if (pipelinePendingFileCount > 0)
    indexingStatus = { message: IndexingStatusMessages.IN_PROGRESS, color: 'processing' };
  if (pipelinePendingFileCount === 0 && pipelineFailedFileCount > 0)
    indexingStatus = { message: IndexingStatusMessages.PARTIALLY_INDEXED, color: 'warning' };
  if (pipelinePendingFileCount === 0 && pipelineFailedFileCount === 0)
    indexingStatus = { message: IndexingStatusMessages.INDEXED, color: 'success' };

  const {
    failed_file_count: failedFileCount,
    indexed_file_count: indexedFileCount,
    pending_file_count: pendingFileCount,
    indexed_no_documents_file_count: skippedFileCount,
    total_file_count: totalFileCount,
  } = indexingData || {};

  const popContent = () => (
    <ul className={styles.popContent}>
      <li>
        <span>{POP_CONTENT_PENDING}</span> <span>{pendingFileCount}</span>
      </li>
      <li>
        <span>{POP_CONTENT_INDEXED}</span> <span>{indexedFileCount} </span>
      </li>
      <li>
        <span>{POP_CONTENT_SKIPPED}</span> <span>{skippedFileCount}</span>
      </li>
      <li>
        <span>{POP_CONTENT_FAILED}</span> <span>{failedFileCount}</span>
      </li>
      <li>
        <strong>{POP_CONTENT_TOTAL}</strong> <strong>{totalFileCount}</strong>
      </li>
    </ul>
  );

  return (
    <span>
      <Popover content={popContent} title={INDEXING_STATUS_HEADER} rootClassName={styles.popover}>
        <Tag
          color={indexingStatus.color}
          onMouseEnter={() => getIndexingDetails && getIndexingDetails(name)}
          className={styles.indexingTag}
          data-testid={TAG_TEST_ID}
        >
          {pipelinePendingFileCount > 0 && (
            <Spin
              size="small"
              className={styles.indexingTag_spinner}
              indicator={<SyncOutlined style={{ fontSize: 12 }} spin />}
            />
          )}
          {indexingStatus.message}
        </Tag>
      </Popover>
    </span>
  );
};

export default PipelineStatusTag;
