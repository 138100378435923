import React, { ReactNode, useEffect, useState } from 'react';
import { ArrowLeftOutlined, ArrowRightOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Drawer, Dropdown } from 'antd';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { formatNumberToLocaleString } from '@utils/math';
import { getResultsByType, getSearchResultPipelineType } from '@utils/search';
import { useUserEvent } from '@hooks/useUserEvent';
import { StatusCodes } from '@constants/enum/common';
import { EventControlComponent, EventControlElement, UserEventType } from '@constants/event';
import {
  COLUMN_TITLES,
  DONE_BUTTON_LABEL,
  FEEDBACK_CAROUSEL_LIST_OPTIONS_KEYS,
  FILTERS_SECTION_TITLE,
  NEXT_QUERY_BUTTON_LABEL,
  PARAMS_SECTION_TITLE,
  PREV_QUERY_BUTTON_LABEL,
} from '@constants/pipeline-feedback';
import { PipelineType } from '@constants/pipelines';
import {
  ANSWER_TITLE,
  MORE_OPTIONS_VIEW_FILTERS_LABEL,
  MORE_OPTIONS_VIEW_PARAMS_LABEL,
  MORE_OPTIONS_VIEW_PROMPT_LABEL,
} from '@constants/search';
import {
  FeedbackType,
  ISearchResult,
  ISearchResultAnswer,
  ISearchResultDocument,
  ITag,
  SearchResultMoreOption,
} from '@redux/types/types';
import DisplayDataModal from '@components/common/displayDataModal/DisplayDataModal';
import ListCarousel from '@components/common/ListCarousel/ListCarousel';
import PipelineFeedbackRatingTag from '@components/PipelineFeedbackRatingTag/PipelineFeedbackRatingTag';
import ReferencesList from '@components/references/referencesList/ReferencesList';
import PromptModal from '@components/search/features/PromptModal/PromptModal';
import usePromptHook from '@components/search/features/PromptModal/usePromptHook';
import useReferences from '@components/search/hooks/useReferences';
import ResultContainer from '@components/search/organisms/result/ResultContainer';
import SearchResultActions from '@components/search/organisms/resultActions/SearchResultActions';
import SourcesListCollapsePreview from '@components/sourcesListCollapsePreview/SourcesListCollapsePreview';
import styles from './detailedQueryView.module.scss';

type FeedbackData = {
  score: FeedbackType | null;
  tags: ITag[];
};

interface IDetailedQueryView {
  searchResult: ISearchResult;
  feedbackData?: FeedbackData | null;
  extraQueryInfo?: {
    user: { given_name: string; family_name: string } | null;
    duration: number;
    createdAt: string;
    rank?: number | null;
    filters: Record<string, unknown> | null;
    params?: Record<string, unknown> | null;
  } | null;
  pipelineName: string;
  pipelineId: string;
  isVisible: boolean;
  isPreviousItemButtonDisabled: boolean;
  isNextItemButtonDisabled: boolean;
  parsedSearchResult?: ISearchResult;
  showFeedbackActionButtons?: boolean;
  onPreviousClick: () => void;
  onClose: () => void;
  onNextClick: () => void;
}

const DetailedQueryView = ({
  searchResult,
  extraQueryInfo,
  feedbackData,
  pipelineName,
  pipelineId,
  isVisible,
  isPreviousItemButtonDisabled,
  isNextItemButtonDisabled,
  showFeedbackActionButtons = false,
  onClose,
  onPreviousClick,
  onNextClick,
}: IDetailedQueryView) => {
  const { openPromptModal } = usePromptHook();
  const { activeReference, resetReferenceDrawer, getSearchResultReferences } = useReferences();
  const { trackUserEvent, setEventProperties } = useUserEvent();

  const [isFiltersModalVisible, setIsFiltersModalVisible] = useState(false);
  const [isParamsModalVisible, setIsParamsModalVisible] = useState(false);

  const [firstAnswer] = searchResult.answers || [];
  const pipelineType = getSearchResultPipelineType(searchResult);
  const resultReferences = getSearchResultReferences(
    searchResult,
    activeReference?.resultId || firstAnswer?.result_id,
  );

  useEffect(() => {
    if (searchResult) {
      const [result] = getResultsByType(searchResult);
      setEventProperties({ result_id: result.result_id, pipeline_name: pipelineName });
    }
  }, [searchResult]);

  const getDocuments = (
    result: ISearchResultAnswer | ISearchResultDocument,
  ): ISearchResultDocument[] => {
    const { documents: parsedDocuments = [] } = searchResult!;
    if (pipelineType === PipelineType.DOCUMENT_RETRIEVAL)
      return [result] as ISearchResultDocument[];
    if (!parsedDocuments.length) return parsedDocuments;

    if (!('document_ids' in result) || !result?.document_ids) return [];

    return result.document_ids.reduce((acc: ISearchResultDocument[], id: string) => {
      const document = parsedDocuments.find((doc: { id: string }) => doc.id === id);
      if (document) return [...acc, document];
      return acc;
    }, []);
  };

  const getPrompts = () => {
    const { prompts } = searchResult;

    if (!isEmpty(prompts ?? {})) return prompts;

    // If no prompts, we use the prompt from the first answer
    const [{ prompt }] = getResultsByType(searchResult) as ISearchResultAnswer[];

    if (!prompt) return null;

    return { prompt };
  };

  const onMoreOptionsDropdownMenuItemClick = ({ key }: { key: string }) => {
    const prompts = getPrompts();

    if (key === SearchResultMoreOption.VIEW_PROMPT && prompts) openPromptModal(prompts);
    if (key === SearchResultMoreOption.VIEW_FILTERS) setIsFiltersModalVisible(true);
    if (key === SearchResultMoreOption.VIEW_PARAMS) setIsParamsModalVisible(true);
  };

  const closeDrawer = () => {
    resetReferenceDrawer();
    onClose();
  };

  const getFeedbackOptions = () =>
    feedbackData
      ? Object.entries(feedbackData).reduce(
          (acc: { key: string; label: string; value: ReactNode | string }[], [key, value]) => {
            if (key === FEEDBACK_CAROUSEL_LIST_OPTIONS_KEYS.FEEDBACK_TYPE)
              return [
                ...acc,
                {
                  key,
                  label: COLUMN_TITLES.FEEDBACK_RATING,
                  value: <PipelineFeedbackRatingTag type={value as FeedbackType} />,
                },
              ];

            return acc;
          },
          [],
        )
      : [];

  const getCarouselOptions = () => {
    const { query_id: queryId } = searchResult;
    const { user, duration, createdAt, rank } = extraQueryInfo || {};
    const { family_name: familyName, given_name: givenName } = user || {};

    return [
      ...(rank
        ? [
            {
              key: FEEDBACK_CAROUSEL_LIST_OPTIONS_KEYS.RANK,
              value: rank,
              label: COLUMN_TITLES.RANK,
            },
          ]
        : []),
      ...(user
        ? [
            {
              key: FEEDBACK_CAROUSEL_LIST_OPTIONS_KEYS.QUERY_BY,
              value: `${givenName} ${familyName}`,
              label: COLUMN_TITLES.USER,
            },
          ]
        : []),
      ...getFeedbackOptions(),
      {
        key: FEEDBACK_CAROUSEL_LIST_OPTIONS_KEYS.QUERY_ID,
        value: queryId,
        label: COLUMN_TITLES.QUERY_ID,
      },
      ...(duration
        ? [
            {
              key: FEEDBACK_CAROUSEL_LIST_OPTIONS_KEYS.DURATION,
              value: `${formatNumberToLocaleString(duration)}s`,
              label: COLUMN_TITLES.DURATION,
            },
          ]
        : []),
      ...(createdAt
        ? [
            {
              key: FEEDBACK_CAROUSEL_LIST_OPTIONS_KEYS.QUERY_CREATED,
              value: dayjs(createdAt).format('DD/MM/YYYY HH:mm'),
              label: COLUMN_TITLES.QUERY_DATE,
            },
          ]
        : []),
    ];
  };

  const renderResultMoreOptions = () => {
    const [{ prompt }] = getResultsByType(searchResult) as ISearchResultAnswer[];
    const { filters, params } = extraQueryInfo || {};

    if (!prompt && isEmpty(filters ?? {}) && isEmpty(params ?? {})) return null;

    const items = [
      ...(prompt
        ? [
            {
              key: SearchResultMoreOption.VIEW_PROMPT,
              label: MORE_OPTIONS_VIEW_PROMPT_LABEL,
            },
          ]
        : []),
      ...(!isEmpty(filters ?? {})
        ? [
            {
              key: SearchResultMoreOption.VIEW_FILTERS,
              label: MORE_OPTIONS_VIEW_FILTERS_LABEL,
            },
          ]
        : []),
      ...(!isEmpty(params ?? {})
        ? [
            {
              key: SearchResultMoreOption.VIEW_PARAMS,
              label: MORE_OPTIONS_VIEW_PARAMS_LABEL,
            },
          ]
        : []),
    ];

    return (
      <Dropdown
        menu={{
          items,
          onClick: onMoreOptionsDropdownMenuItemClick,
        }}
        trigger={['click']}
      >
        <Button type="text" onClick={(e) => e.preventDefault()} icon={<MoreOutlined />} />
      </Dropdown>
    );
  };

  const renderResult = () => {
    // We only display one results at the time
    const [result] = getResultsByType(searchResult);
    return (
      <div className={styles.section}>
        <div className={styles.section_subheader}>
          <h5>{ANSWER_TITLE}</h5>
          {renderResultMoreOptions()}
        </div>
        <div className={styles.answerContainer}>
          <ResultContainer
            searchStatus={StatusCodes.IDLE}
            searchResult={result}
            documents={getDocuments(result)}
            pipelineId={pipelineId}
            queryId={searchResult.query_id || ''}
            pipelineName={pipelineName}
            withGenerativeTypingEffect={false}
            pipelineType={pipelineType}
            displayFeedbackOptions={false}
            documentResultOptions={{
              displayFullDocument: true,
            }}
            displayFileOptions
            displayFileSources={false}
            displayMoreOptions={false}
            displayReferencesPopover={false}
          />
        </div>
        {showFeedbackActionButtons && (
          <SearchResultActions
            resultId={result.result_id}
            queryId={searchResult.query_id}
            pipelineId={pipelineId}
            pipelineName={pipelineName}
          />
        )}
      </div>
    );
  };

  const renderSources = () => {
    if (!searchResult?.documents?.length) return null;

    return (
      <SourcesListCollapsePreview
        documents={searchResult.documents}
        onTabChange={({ tab, fileId }) => {
          trackUserEvent({
            type: UserEventType.CLICK,
            control: `${EventControlComponent.QUERY_DETAILED_DRAWER}/${EventControlElement.TAB}`,
            properties: {
              tab,
              file_id: fileId,
            },
          });
        }}
      />
    );
  };

  const renderReferences = () => {
    if (!resultReferences?.length) return null;

    return (
      <ReferencesList
        searchResultReferences={resultReferences}
        searchDocuments={searchResult.documents}
      />
    );
  };

  const renderFiltersDataModal = () => {
    const { filters } = extraQueryInfo || {};
    return (
      <DisplayDataModal
        title={FILTERS_SECTION_TITLE}
        open={isFiltersModalVisible}
        onCancel={() => setIsFiltersModalVisible(false)}
        onOk={() => setIsFiltersModalVisible(false)}
      >
        <code>{JSON.stringify(filters, null, 2)}</code>
      </DisplayDataModal>
    );
  };

  const renderParamsDataModal = () => {
    const { params } = extraQueryInfo || {};
    return (
      <DisplayDataModal
        title={PARAMS_SECTION_TITLE}
        open={isParamsModalVisible}
        onCancel={() => setIsParamsModalVisible(false)}
        onOk={() => setIsParamsModalVisible(false)}
      >
        <code>{JSON.stringify(params, null, 2)}</code>
      </DisplayDataModal>
    );
  };

  return (
    <>
      <Drawer
        rootClassName={styles.detailedQueryView}
        open={isVisible}
        title={searchResult.query}
        size="large"
        onClose={closeDrawer}
        extra={
          <Button onClick={onClose} type="primary">
            {DONE_BUTTON_LABEL}
          </Button>
        }
        footer={
          <div className={styles.footer}>
            <Button disabled={isPreviousItemButtonDisabled} onClick={onPreviousClick}>
              <ArrowLeftOutlined />
              {PREV_QUERY_BUTTON_LABEL}
            </Button>
            <Button disabled={isNextItemButtonDisabled} onClick={onNextClick}>
              {NEXT_QUERY_BUTTON_LABEL}
              <ArrowRightOutlined />
            </Button>
          </div>
        }
      >
        {renderResult()}
        {!!resultReferences?.length ? renderReferences() : renderSources()}
        <div className={styles.section}>
          <ListCarousel options={getCarouselOptions()} />
        </div>
      </Drawer>
      <PromptModal />
      {renderFiltersDataModal()}
      {renderParamsDataModal()}
    </>
  );
};

export default DetailedQueryView;
