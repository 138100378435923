import React, { memo, useEffect, useState } from 'react';
import { Tag } from 'antd';

import useDashboard from '@modules/Dashboard/hooks/useDashboard';
import UpgradeButton from '@modules/Upgrade/components/upgradeButton/UpgradeButton';
import UsageCard from '@pages/metering/components/UsageCard';
import useMeteringUsage from '@pages/metering/hooks/useMeteringUsage';
import styles from './usageLimits.module.scss';
import {
  USAGE_LIMITS_MAP,
  USAGE_LIMITS_SURTITLE,
  USAGE_LIMITS_TITLE,
} from '../../constants/dashboard';

const UsageLimits: React.FC = () => {
  const [isOverLimit, setIsOverLimit] = useState(false);

  const { workspaceStats } = useDashboard();
  const { allTimeUsageMetrics, totalPipelines, fetchAllTimeUsageMetrics } = useMeteringUsage();

  const { development_hours: developmentHours } = allTimeUsageMetrics;

  useEffect(() => {
    fetchAllTimeUsageMetrics();
  }, []);

  const getMetric = (key: string) => {
    return key === 'files'
      ? workspaceStats.fileCount
      : key === 'pipelines'
      ? totalPipelines
      : developmentHours;
  };

  const checkLimitExceeded = () => {
    return Object.keys(USAGE_LIMITS_MAP).some((key: string) => {
      const { target } = USAGE_LIMITS_MAP[key];
      const metric = getMetric(key);
      return metric ? metric >= target : false;
    });
  };

  useEffect(() => {
    const isLimitExceeded = checkLimitExceeded();
    setIsOverLimit(isLimitExceeded);
  }, [workspaceStats, totalPipelines, developmentHours]);

  return (
    <section>
      <h5 className={styles.header}>
        {USAGE_LIMITS_TITLE}
        {isOverLimit ? <UpgradeButton size="small" /> : <Tag>{USAGE_LIMITS_SURTITLE}</Tag>}
      </h5>
      <div className={styles.container}>
        {Object.keys(USAGE_LIMITS_MAP).map((key: string) => {
          const { label, tooltip, target, limitLabel, targetLabel } = USAGE_LIMITS_MAP[key];
          const metric = getMetric(key);

          return (
            <UsageCard
              key={key}
              label={label}
              metric={metric}
              tooltip={tooltip}
              limit={{
                current: metric,
                target: target,
                name: limitLabel,
                targetLabel: targetLabel,
              }}
              showLimitInfo
            />
          );
        })}
      </div>
    </section>
  );
};

export default memo(UsageLimits);
