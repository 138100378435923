import { CAUSED_BY_LABEL, ILogEntry, STACK_TRACE_LABEL } from '@constants/log-explorer';
import { transformToTitleCase } from './string';

export const formatKeyValue = (key: string, value: unknown): string => {
  const formattedKey = transformToTitleCase(key);
  const formattedValue = JSON.stringify(value, null, 2) || '';

  return `${formattedKey}: ${formattedValue}`;
};

export const formatLogAsText = (log: ILogEntry): string => {
  const exceptionCount = log?.exceptions?.length || 0;
  const exceptions = exceptionCount ? [...log.exceptions].reverse() : [];

  const exceptionText = exceptions
    .map((exception, index) => {
      const formattedTraces = exception.trace
        .map((trace) => `${trace.filename}, line ${trace.line_number.toString()}, ${trace.name}`)
        .join('\n');

      if (exceptionCount === 1) {
        return `${log.message}${STACK_TRACE_LABEL}\n${formattedTraces}`;
      }

      const formattedException = `${exception.type}: ${exception.value}\n${formattedTraces}`;
      return index > 0 ? `\n${CAUSED_BY_LABEL}\n${formattedException}` : formattedException;
    })
    .join('\n');

  const formattedExtraFields = Object.entries(log.extra_fields)
    .map(([key, value]) => formatKeyValue(key, value))
    .join('\n');

  if (exceptionCount === 1) return exceptionText;

  return `${log.message}\n\n${exceptionText}\n\n${formattedExtraFields}`;
};
