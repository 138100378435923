import React, { useEffect, useState } from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { transformToTitleCase } from '@utils/string';
import { IPipelineTemplate, ITag } from '@redux/types/types';
import {
  PIPELINE_TEMPLATE_LANGUAGES_CODES,
  PipelineTemplateMetadataFields,
} from '@modules/PipelineTemplates/constants/pipeline-templates';
import PipelineTemplatesTags from './PipelineTemplatesTags';
import styles from './pipelineTemplateMetadata.module.scss';

interface IPipelineTemplateMetadataProps {
  template: IPipelineTemplate | null;
}

const enum ExcludedTags {
  LANGUAGE = 'language:',
  MULTILINGUAL = 'multilingual',
}

const PipelineTemplateMetadata = (props: IPipelineTemplateMetadataProps) => {
  const { template } = props;
  const [languages, setLanguages] = useState<ITag[]>([]);

  useEffect(() => {
    if (template) {
      template.tags.forEach((tag) => {
        if (
          tag.name.includes(ExcludedTags.LANGUAGE) &&
          !tag.name.includes(ExcludedTags.MULTILINGUAL)
        ) {
          const newLanguage = {
            ...tag,
            name: tag.name.replace(ExcludedTags.LANGUAGE, ''),
          };
          setLanguages((prevLanguages) => [...prevLanguages, newLanguage]);
        }
      });
    }

    return () => {
      setLanguages([]);
    };
  }, [template]);

  return (
    <div className={styles.metadata}>
      <div className={styles.metadata_item}>
        <div className={styles.metadata_item_title}>{PipelineTemplateMetadataFields.BEST_FOR}</div>
        <div className={styles.metadata_item_list}>
          {template?.best_for.map((item) => (
            <div key={item} className={styles.metadata_item_list_item}>
              <CheckOutlined />
              <div>{item}</div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.metadata_item}>
        <div className={styles.metadata_item_title}>
          {PipelineTemplateMetadataFields.POTENTIAL_APPLICATIONS}
        </div>
        <div className={styles.metadata_item_list}>
          {template?.potential_applications.map((item) => (
            <div key={item} className={styles.metadata_item_list_item}>
              <li>{item}</li>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.metadata_item}>
        <div className={styles.metadata_item_title}>
          {PipelineTemplateMetadataFields.EXPECTED_OUTPUT}
        </div>
        <div className={styles.metadata_item_list}>
          {template?.expected_output.map((item) => (
            <div key={item} className={styles.metadata_item_list_item}>
              <div>{item}</div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.metadata_item}>
        <div className={styles.metadata_item_title}>
          {PipelineTemplateMetadataFields.RECOMMENDED_DATASET}
        </div>
        <div className={styles.metadata_item_list}>
          {template?.recommended_dataset.map((item) => (
            <div key={item} className={styles.metadata_item_list_item}>
              <CheckOutlined />
              <div>{item}</div>
            </div>
          ))}
        </div>
      </div>
      {!!template?.tags?.length && (
        <div className={styles.metadata_item}>
          <div className={styles.metadata_item_title}>{PipelineTemplateMetadataFields.TAGS}</div>
          <PipelineTemplatesTags tags={template.tags} />
        </div>
      )}
      <div className={styles.metadata_item}>
        <div className={styles.metadata_item_title}>{PipelineTemplateMetadataFields.LANGUAGES}</div>
        <div className={styles.metadata_item_flags}>
          {languages.map((language) => (
            <div key={language.tag_id}>
              <Tooltip title={transformToTitleCase(language.name)}>
                {
                  PIPELINE_TEMPLATE_LANGUAGES_CODES[
                    language.name as keyof typeof PIPELINE_TEMPLATE_LANGUAGES_CODES
                  ]
                }
              </Tooltip>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PipelineTemplateMetadata;
