import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Drawer } from 'antd';
import { isEqual } from 'lodash';
import useEffectUpdateOnly from '@hooks/useEffectUpdateOnly';
import { FILTERS_TITLE } from '@constants/common';
import { StatusCodes } from '@constants/enum/common';
import {
  APPLY_FILTERS_BUTTON,
  CANCEL_FILTERS_BUTTON,
  CLEAR_FILTERS_BUTTON,
} from '@constants/search';
import { selectMetaDataFilterValues } from '@redux/actions/metadataFiltersActions';
import {
  getMetadataValuesStatusSelector,
  selectedMetaFilterValuesSelector,
} from '@redux/selectors/metadataFiltersSelectors';
import { MetadataFiltersValue } from '@redux/types/types';
import MetadataFilters from '@components/metadataFilters/MetadataFilters';
import styles from './playgroundFiltersDrawer.module.scss';

interface IPlaygroundFiltersDrawerProps {
  open: boolean;
  pipelineName: string;
  activeFilters: Record<string, MetadataFiltersValue>;
  emptySearchResults: boolean;
  onClickApplyFilters: (selectedFilters: Record<string, MetadataFiltersValue>) => void;
  onClose: (selectedFilters: Record<string, MetadataFiltersValue>) => void;
  onClearAllFilters: () => void;
}

const PlaygroundFiltersDrawer = ({
  open,
  pipelineName,
  activeFilters,
  onClickApplyFilters,
  onClose,
  onClearAllFilters,
}: IPlaygroundFiltersDrawerProps) => {
  const dispatch = useDispatch();

  const selectedMetaFilterValues = useSelector(selectedMetaFilterValuesSelector);
  const getMetadataValuesStatus = useSelector(getMetadataValuesStatusSelector);

  const loadedSuccessfullyMetadataValues = getMetadataValuesStatus === StatusCodes.SUCCESS;

  useEffectUpdateOnly(() => {
    if (!loadedSuccessfullyMetadataValues) return;

    if (activeFilters) dispatch(selectMetaDataFilterValues(activeFilters));
  }, [getMetadataValuesStatus]);

  const handleCancelingNewSelectedFilters = () => {
    dispatch(selectMetaDataFilterValues(activeFilters));
  };

  const handleClearingAllFilters = () => {
    dispatch(selectMetaDataFilterValues({}));
    onClearAllFilters();
  };

  const renderHeader = () => (
    <header className={styles.playgroundFilters_header}>
      <div className={styles.playgroundFilters_header_title}>
        <h5>{FILTERS_TITLE}</h5>
        {Object.keys(selectedMetaFilterValues).length > 0 && (
          <Button size="small" onClick={handleClearingAllFilters}>
            {CLEAR_FILTERS_BUTTON}
          </Button>
        )}
      </div>
    </header>
  );

  const renderBody = () => (
    <div className={styles.playgroundFilters_content}>
      <MetadataFilters pipelineName={pipelineName} />
    </div>
  );

  const renderFooter = () => {
    if (isEqual(selectedMetaFilterValues, activeFilters) || !loadedSuccessfullyMetadataValues)
      return null;
    return (
      <footer className={styles.playgroundFilters_footer}>
        <div className={styles.playgroundFilters_footer_options}>
          <Button
            onClick={handleCancelingNewSelectedFilters}
            className={styles.playgroundFilters_cancelButton}
            block
          >
            {CANCEL_FILTERS_BUTTON}
          </Button>
          <Button
            type="primary"
            onClick={() => onClickApplyFilters(selectedMetaFilterValues)}
            block
          >
            {APPLY_FILTERS_BUTTON}
          </Button>
        </div>
      </footer>
    );
  };

  return (
    <Drawer
      rootClassName={styles.playgroundFiltersDrawer}
      onClose={() => onClose(selectedMetaFilterValues)}
      closable
      open={open}
      title={renderHeader()}
      footer={renderFooter()}
    >
      {renderBody()}
    </Drawer>
  );
};

export default PlaygroundFiltersDrawer;
