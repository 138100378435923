export const isPrompt = (componentType: string, paramName: string) => {
  let prompt = false;
  if (
    ((componentType === 'PromptBuilder' || componentType === 'OutputAdapter') &&
      paramName === 'template') ||
    paramName === 'system_prompt'
  )
    prompt = true;
  return prompt;
};

export const replacePythonOverheadTypes = (typestr: string) => {
  try {
    typestr = typestr.replace(/typing\./g, '');
    return typestr.replace(/\[([^\[\]]+)\]/g, (match, p1) => {
      return `[${p1.split('.').pop()}]`;
    });
  } catch (error) {
    return typestr;
  }
};

export const isExperimental = (componentClass: string) => {
  return componentClass.includes('experimental');
};
