import { useDispatch, useSelector } from 'react-redux';
import { togglePromptModal } from '@redux/actions/searchActions';
import {
  activePromptsSelector,
  promptModalVisibleSelector,
} from '@redux/selectors/searchSelectors';
import { IQueryResultPrompts } from '@redux/types/types';

const usePromptHook = () => {
  const dispatch = useDispatch();
  const activePrompts = useSelector(activePromptsSelector);
  const isPromptModalOpened = useSelector(promptModalVisibleSelector);

  const openPromptModal = (prompts?: IQueryResultPrompts) => {
    dispatch(togglePromptModal(prompts));
  };

  const hidePromptModal = () => {
    dispatch(togglePromptModal(null));
  };

  return {
    isPromptModalOpened,
    openPromptModal,
    hidePromptModal,
    activePrompts,
  };
};

export default usePromptHook;
