import React, { memo } from 'react';
import { Tag } from 'antd';
import DataTable from '@components/dataTable/DataTable';
import styles from './dashboard.module.scss';
import { HISTORY_TABLE_COLUMNS, HISTORY_TITLE, TOTAL_HISTORY_TITLE } from '../constants/dashboard';
import { IHistoryData } from '../types/dashboard';

export interface ILatestRequestsProps {
  searchRequestCount: number;
  data: IHistoryData[];
  loading: boolean;
}

const TABLE_SCROLL_WIDTH = 1250;

const LatestRequests: React.FC<ILatestRequestsProps> = (props) => {
  const { searchRequestCount, data, loading } = props;

  const filterData = data.map((history) => {
    let localHistory = history;
    if (!localHistory.topAnswer) {
      localHistory = {
        ...history,
        topAnswer: '-',
      };
    }

    return {
      ...localHistory,
      topFiles: (
        <>
          {localHistory.topFiles &&
            localHistory.topFiles.map((file: string, idx: number) => (
              <Tag key={`${file}-${idx}`} className={styles.topFiles}>
                {file}
              </Tag>
            ))}
        </>
      ),
    };
  });

  return (
    <section>
      <div className={styles.blockTitle}>
        <div className={styles.flexBlock}>
          <div>{HISTORY_TITLE}</div>
          <div className={styles.flexBlock}>
            <div>{TOTAL_HISTORY_TITLE}</div>
            <div className={styles.totalValue}>{searchRequestCount}</div>
          </div>
        </div>
      </div>
      <DataTable
        data={filterData}
        rowKey="key"
        columns={HISTORY_TABLE_COLUMNS}
        loading={loading}
        searchAvailable={false}
        rowSelection={false}
        scroll={{ x: TABLE_SCROLL_WIDTH }}
        border
      />
    </section>
  );
};

export default memo(LatestRequests);
