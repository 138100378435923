import React, { ReactElement, useState } from 'react';
import { Tabs } from 'antd';
import { isString } from 'lodash';
import useEffectUpdateOnly from '@hooks/useEffectUpdateOnly';
import { PROMPT_MODAL_TITLE } from '@constants/common';
import DisplayDataModal from '@components/common/displayDataModal/DisplayDataModal';
import usePromptHook from './usePromptHook';
import styles from './promptModal.module.scss';

const PromptModal = () => {
  const { isPromptModalOpened, activePrompts, hidePromptModal } = usePromptHook();
  const [selectedPromptNode, setSelectedPromptNode] = useState<string | undefined>();

  useEffectUpdateOnly(() => {
    const promptNodes = Object.keys(activePrompts || {});
    if (promptNodes.length) setSelectedPromptNode(promptNodes[0]);
  }, [activePrompts]);

  if (!activePrompts || !Object.keys(activePrompts || {}).length) return null;

  const getPromptTemplatesNodeTabs = () =>
    Object.keys(activePrompts).map((node) => ({
      key: node,
      label: node,
    }));

  // Renders

  const renderPromptNodeTabs = () => {
    if (!activePrompts || Object.keys(activePrompts).length < 2) return null;
    return (
      <div className={styles.selector}>
        <Tabs
          size="small"
          activeKey={selectedPromptNode}
          items={getPromptTemplatesNodeTabs()}
          onChange={setSelectedPromptNode}
          tabBarGutter={16}
        />
      </div>
    );
  };

  const renderPromptNodeContent = (): ReactElement | null => {
    if (!activePrompts || !Object.keys(activePrompts).length) return null;
    const promptNode = activePrompts[selectedPromptNode || ''];

    if (isString(promptNode)) return <pre>{promptNode}</pre>;

    return <pre>{JSON.stringify(promptNode, null, 2)}</pre>;
  };

  return (
    <DisplayDataModal
      title={PROMPT_MODAL_TITLE}
      onCancel={() => hidePromptModal()}
      open={isPromptModalOpened}
      onOk={() => hidePromptModal()}
      header={renderPromptNodeTabs()}
    >
      {renderPromptNodeContent()}
    </DisplayDataModal>
  );
};

export default PromptModal;
