// New implementation of pipeline templates
import React from 'react';
import { CN, DE, ES, FR, IN, IT, JP, NL, PT, RU, SA, US, VN } from 'country-flag-icons/react/3x2';
import { PipelineTemplatesCategory } from '@redux/types/types';

export const BY_USER_LABEL = 'by';
export const CREATED_BY_USER_LABEL = 'Created by';
export const CATEGORY_ALL_TEMPLATES = 'All Templates';

export const VIEW_DETAILS_BUTTON_LABEL = 'View Details';
export const USE_TEMPLATE_BUTTON_LABEL = 'Use Template';
export const SEE_ALL_LABEL = 'See all';
export const CREATE_EMPTY_FILE_BUTTON_LABEL = 'Create empty pipeline';
export const PIPELINE_PREVIEW_TITLE = 'Pipeline Preview';
export const MORE_TEMPLATES_LABEL = 'Similar templates';
export const SEARCH_TEMPLATES_PLACEHOLDER = 'Find templates';
export const CLEAR_ALL_FILTERS_BUTTON_LABEL = 'clear all filters';
export const EMPTY_TEMPLATES_LIST_LABEL = `We're working on new %categoryName% templates. In the meantime, feel free to explore other categories or check back here later.`;

export const ALL_TEMPLATES_LABELS = {
  TITLE: 'All templates',
  DESCRIPTION: `Professionally built pipeline templates you can customize to give you a head start with your project.`,
};

export const CATEGORY_LABELS = {
  [PipelineTemplatesCategory.RECOMMENDED]: 'Recommended',
  [PipelineTemplatesCategory.DOCUMENT_SEARCH]: 'Document Search',
  [PipelineTemplatesCategory.BASIC_QA]: 'Basic QA',
  [PipelineTemplatesCategory.ADVANCED_QA]: 'Advanced QA',
  [PipelineTemplatesCategory.CONVERSATIONAL]: 'Conversational',
  [PipelineTemplatesCategory.TEXT_ANALYSIS]: 'Text Analysis',
  [PipelineTemplatesCategory.VISUAL_QA]: 'Visual QA',
  [PipelineTemplatesCategory.TEXT_TO_SQL]: 'Text-to-SQL',
  [PipelineTemplatesCategory.IMAGE_GENERATION]: 'Image Generation',
  [PipelineTemplatesCategory.AGENTS]: 'Agents',
};

export const SIDEBAR_CATEGORIES = [
  PipelineTemplatesCategory.DOCUMENT_SEARCH,
  PipelineTemplatesCategory.BASIC_QA,
  PipelineTemplatesCategory.ADVANCED_QA,
  PipelineTemplatesCategory.VISUAL_QA,
  PipelineTemplatesCategory.CONVERSATIONAL,
  PipelineTemplatesCategory.TEXT_ANALYSIS,
  PipelineTemplatesCategory.TEXT_TO_SQL,
  PipelineTemplatesCategory.IMAGE_GENERATION,
  PipelineTemplatesCategory.AGENTS,
];

export enum PipelineTemplateMetadataFields {
  BEST_FOR = 'Best for',
  POTENTIAL_APPLICATIONS = 'Potential applications',
  TAGS = 'Tags',
  EXPECTED_OUTPUT = 'Prerequisites',
  LANGUAGES = 'Languages',
  RECOMMENDED_DATASET = 'Expected output',
}

export const PIPELINE_TEMPLATE_LANGUAGES_CODES = {
  arabic: <SA />,
  italian: <IT />,
  spanish: <ES />,
  hindi: <IN />,
  dutch: <NL />,
  portuguese: <PT />,
  russian: <RU />,
  french: <FR />,
  english: <US />,
  vietnamese: <VN />,
  chinese: <CN />,
  japanese: <JP />,
  german: <DE />,
};

// Filtering pipeline templates

export const PIPELINE_TEMPLATES_FILTERS_TAGS_LABEL = 'Tags';
export const PIPELINE_TEMPLATES_FILTERS_FIELD_KEYS = {
  TAGS_ID: 'tags/tag_id',
};

export const EMPTY_FILTERED_PIPELINE_TEMPLATES = {
  TITLE: 'No templates match the current filters',
  DESCRIPTION:
    'To see more templates, try changing or removing some filters. You can also %clearFiltersLink% to view all available templates.',
};

export const EMPTY_SEARCHED_PIPELINE_TEMPLATES = {
  TITLE: 'No templates match the search criteria',
  DESCRIPTION: 'Try searching for something different.',
};

// Sorting pipeline templates

export const DEFAULT_SORTING_KEY = 'name_asc';
export const PIPELINE_TEMPLATES_SORTING_OPTIONS = [
  { key: 'name_asc', label: 'Name A to Z' },
  { key: 'name_desc', label: 'Name Z to A' },
];
export const SORTING_PARAMS_BY_KEY = {
  name_asc: {
    field: 'name',
    order: 'ASC',
  },
  name_desc: {
    field: 'name',
    order: 'DESC',
  },
};

export const UPGRADE_POPOVER_TEMPLATE_CONTENT =
  'To use this template you need to upgrade to a paid plan.';
