import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  CAUSED_BY_LABEL,
  IException,
  ILogEntry,
  LINE_LABEL,
  STACK_TRACE_LABEL,
} from '@constants/log-explorer';
import styles from './rawLog.module.scss';

interface IRawLogProps {
  log: ILogEntry;
  renderExtra: () => JSX.Element;
}

const RawLog: React.FC<IRawLogProps> = ({ log, renderExtra }) => {
  const exceptions = log?.exceptions?.length ? [...log.exceptions].reverse() : [];

  const renderSingleton = (exception: IException) => (
    <div key={uuidv4()}>
      {exception.trace.map((trace) => {
        return (
          <div
            key={uuidv4()}
          >{`${trace.filename}, ${LINE_LABEL} ${trace.line_number}, ${trace.name}`}</div>
        );
      })}
    </div>
  );

  const renderException = (exception: IException, index: number) => (
    <div className={styles.pipelineLogs_collapse_rawSection} key={uuidv4()}>
      {index > 0 && <div>{CAUSED_BY_LABEL}</div>}
      <div>{`${exception.type}: ${exception.value}`}</div>
      {exception.trace.map((trace) => {
        return (
          <div
            key={uuidv4()}
          >{`${trace.filename}, ${LINE_LABEL} ${trace.line_number}, ${trace.name}`}</div>
        );
      })}
    </div>
  );

  return (
    <div className={styles.pipelineLogs_collapse_tabItem}>
      {exceptions.length === 1 ? (
        <div>{log.message + STACK_TRACE_LABEL}</div>
      ) : (
        <p>{log.message}</p>
      )}
      {exceptions.length === 1 ? (
        renderSingleton(exceptions[0])
      ) : (
        <>{exceptions.map((exception, index) => renderException(exception, index))}</>
      )}
      {renderExtra()}
    </div>
  );
};

export default RawLog;
