import { UsageLimit } from '../types/dashboard';

export enum AsyncActionType {
  GET_REQUEST_HISTORY = 'DASHBOARD/GET_REQUEST_HISTORY',
  GET_SEARCH_COUNT = 'DASHBOARD/GET_SEARCH_COUNT',
  GET_WORKSPACE_STATS = 'DASHBOARD/GET_WORKSPACE_STATS',
}

/* Workspace statistic */
export const WORKSPACE_STATISTIC_TITLE = 'Workspace Statistics';
export const STATISTIC_FILES_LABEL = 'Files';
export const STATISTIC_FILES_TOOLTIP = 'Number of files for indexing and creating documents.';
export const STATISTIC_DOCS_LABEL = 'Docs';
export const STATISTIC_DOCS_TOOLTIP =
  'Passages of text the search runs on. Multiple documents may come from one file.';
export const STATISTIC_AVERAGE_RESPONSE_TIME_LABEL = 'Average Response Time';
export const STATISTIC_REQUESTS_TODAY_LABEL = 'Searches Today';

/* Number of Requests for the last Month */
export const CHART_TITLE = 'The number of searches last week';

/* Latest Requests */
export const HISTORY_TITLE = 'Latest Searches';
export const TOTAL_HISTORY_TITLE = 'Searches in total';
export const DEFAULT_HISTORY_LIMIT = 5;
export const HISTORY_TABLE_COLUMNS = [
  {
    title: 'Query',
    dataIndex: 'query',
    key: 'query',
    width: '25%',
  },
  {
    title: 'Top Answer',
    dataIndex: 'topAnswer',
    key: 'topAnswer',
    width: '25%',
  },
  {
    title: 'Pipeline',
    dataIndex: 'pipeline',
    key: 'pipeline',
    width: '20%',
  },
  {
    title: 'Top Files',
    dataIndex: 'topFiles',
    key: 'topFiles',
    width: '15%',
  },
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
    width: '10%',
  },
  {
    title: 'Time',
    dataIndex: 'time',
    key: 'time',
    width: '5%',
  },
];

/* Usage Limits */
export const USAGE_LIMITS_TITLE = 'Usage Limits';
export const USAGE_LIMITS_SURTITLE = 'Free Plan';

export const USAGE_LIMITS_MAP: Record<string, UsageLimit> = {
  files: {
    label: 'Files',
    tooltip: 'Number of files for indexing and creating documents.',
    limitLabel: 'Free plan limit',
    target: 50,
    targetLabel: '50 files',
  },
  pipelines: {
    label: 'Pipelines',
    tooltip: 'Number of pipelines you can create.',
    limitLabel: 'Free plan limit',
    target: 2,
    targetLabel: '2 pipelines',
  },
  pipelineHours: {
    label: 'Pipeline Hours',
    tooltip: 'Total hours your pipelines were deployed, including hours from deleted pipelines.',
    limitLabel: 'Free plan limit',
    target: 100,
    targetLabel: '100 hours',
  },
};
