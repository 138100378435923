import React, { useState } from 'react';
import { CodeOutlined, CopyOutlined, GithubOutlined } from '@ant-design/icons';
import { Button, Modal, Typography } from 'antd';
import { interpolateString } from '@utils/string';
import { useAppSelector } from '@hooks/redux';
import { useStateCleanup } from '@hooks/useStateCleanup';
import styles from './debugTunnelModal.module.scss';
import {
  DEBUG_TUNNEL_GITHUB_LINK_MESSAGE,
  DEBUG_TUNNEL_INFO_STORE_PATH,
  DEBUG_TUNNEL_MODAL_TITLE,
  DEBUG_TUNNEL_SUCCESS_MESSAGE,
  DEBUG_TUNNEL_VSCODE_LINK_MESSAGE,
} from '../constants/debugTunnel';
import { DebugTunnelInfo, selectDebugTunnelInfo } from '../redux/debugTunnelSlice';

interface IDebugTunnelModalProps {
  onClose?: () => void;
}

const { Paragraph, Text } = Typography;

const DEFAULT_PIPELINE_DEBUG_INFO: DebugTunnelInfo = {
  pipelineName: '',
  code: '',
  url: '',
  vscode_url: '',
};

const DebugTunnelModal: React.FC<IDebugTunnelModalProps> = ({
  onClose,
}: IDebugTunnelModalProps) => {
  const { reset } = useStateCleanup();
  const [isGithubLinkClicked, setIsGithubLinkClicked] = useState<boolean>(false);
  const pipelineDebugInfo: DebugTunnelInfo | null = useAppSelector(selectDebugTunnelInfo);

  const open: boolean = !!pipelineDebugInfo;
  const {
    pipelineName,
    code: deviceVerificationCode,
    url: githubUrl,
    vscode_url: vscodeUrl,
  } = pipelineDebugInfo ?? DEFAULT_PIPELINE_DEBUG_INFO;

  const title = interpolateString(DEBUG_TUNNEL_MODAL_TITLE, { pipelineName }, false) as string;

  const onGithubLinkClick = () => {
    setIsGithubLinkClicked(true);
    window.open(githubUrl, '_blank');
  };

  const onCancel = () => {
    onClose?.();
    reset([DEBUG_TUNNEL_INFO_STORE_PATH]);
    setIsGithubLinkClicked(false);
  };

  return (
    <Modal
      data-testid="pipeline_debug_modal"
      title={title}
      open={open}
      onCancel={onCancel}
      footer={[
        <Button
          key="login"
          type="primary"
          icon={<GithubOutlined />}
          disabled={isGithubLinkClicked}
          onClick={onGithubLinkClick}
        >
          {DEBUG_TUNNEL_GITHUB_LINK_MESSAGE}
        </Button>,
        <Button
          key="code"
          type="primary"
          icon={<CodeOutlined />}
          href={vscodeUrl}
          target="_blank"
          disabled={!isGithubLinkClicked}
        >
          {DEBUG_TUNNEL_VSCODE_LINK_MESSAGE}
        </Button>,
      ]}
    >
      <Paragraph>{DEBUG_TUNNEL_SUCCESS_MESSAGE}</Paragraph>
      <Paragraph>
        <Text
          className={styles.debugCode}
          strong
          copyable={{ text: deviceVerificationCode, icon: <CopyOutlined /> }}
        >
          {deviceVerificationCode}
        </Text>
      </Paragraph>
    </Modal>
  );
};

export default DebugTunnelModal;
