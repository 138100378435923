import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleErrorNotification } from '@utils/error';
import {
  getODataConjunctionFilterFrom,
  getODataEQFilterFrom,
  getODataFilterFrom,
  getODataSearchFilterFrom,
} from '@utils/odata';
import { getTagsNameFilter, getTemplateFilterByCategory } from '@utils/templates';
import {
  getPipelineTemplateApi,
  getPipelineTemplatesApi,
  getRecursivelyPipelineTemplatesApi,
} from '@api/pipeline';
import { SelectedFilterItem, SelectedFilters } from '@constants/data-table';
import { initialState } from '@redux/rootReducer';
import {
  GET_MORE_LIKE_THIS_PIPELINE_TEMPLATES,
  GET_PIPELINE_TEMPLATE_BY_NAME,
  GET_PIPELINE_TEMPLATES,
  GET_PIPELINE_TEMPLATES_BY_CATEGORY,
  GET_PIPELINE_TEMPLATES_FILTER_TAGS,
  IAPIPaginationData,
  IPipelineTemplate,
  ITag,
  PipelineTemplatesCategory,
  PipelineTemplatesType,
  RESET_MORE_TEMPLATES_LIKE_THIS,
  RESET_SELECTED_PIPELINE_TEMPLATES_FILTERS,
  RESET_SELECTED_TEMPLATE,
  SELECT_PIPELINE_TEMPLATES_CATEGORY,
  SELECT_PIPELINE_TEMPLATES_FILTERS,
  SELECT_PIPELINE_TEMPLATES_SORT_VALUE,
  SET_SELECTED_TEMPLATE,
  SET_TEMPLATES_SEARCH_VALUE,
} from '@redux/types/types';
import { SORTING_PARAMS_BY_KEY } from '@modules/PipelineTemplates/constants/pipeline-templates';

export const setTemplatesSearchValue = (searchValue: string | null) => ({
  type: SET_TEMPLATES_SEARCH_VALUE,
  payload: searchValue,
});

export const selectTemplatesFilters = (filterKey: string, items: SelectedFilterItem[]) => ({
  type: SELECT_PIPELINE_TEMPLATES_FILTERS,
  payload: { filterKey, items },
});

export const resetSelectTemplatesFilters = {
  type: RESET_SELECTED_PIPELINE_TEMPLATES_FILTERS,
};

export const selectTemplatesSortValue = (value: string) => ({
  type: SELECT_PIPELINE_TEMPLATES_SORT_VALUE,
  payload: value,
});

export const selectTemplatesCategory = (category: PipelineTemplatesCategory | null) => ({
  type: SELECT_PIPELINE_TEMPLATES_CATEGORY,
  payload: category,
});

export const resetSelectedTemplate = {
  type: RESET_SELECTED_TEMPLATE,
};

export const setSelectedPipelineTemplate = (template: IPipelineTemplate) => ({
  type: SET_SELECTED_TEMPLATE,
  payload: template,
});

export const resetMoreTemplatesLikeThis = {
  type: RESET_MORE_TEMPLATES_LIKE_THIS,
};

export const getPipelineTemplates = createAsyncThunk(
  GET_PIPELINE_TEMPLATES,
  async (
    {
      pipelineType,
      filterValues,
      limit,
      pageNumber,
    }: {
      pipelineType?: PipelineTemplatesType;
      filterValues?: SelectedFilters;
      limit?: number;
      pageNumber?: number;
      fetchMore?: boolean;
    },
    { rejectWithValue },
  ) => {
    const pipelineTypeFilter = pipelineType && getODataEQFilterFrom('pipeline_type', pipelineType);
    const filters = filterValues && getODataFilterFrom(filterValues);

    const params = {
      filter: getODataConjunctionFilterFrom(pipelineTypeFilter, filters),
      limit,
      page_number: pageNumber,
    };

    try {
      const { data } = await getPipelineTemplatesApi<IAPIPaginationData<IPipelineTemplate[]>>(
        params,
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// TODO: Add support for infinite scrolling
export const getPipelineTemplatesByCategory = createAsyncThunk(
  GET_PIPELINE_TEMPLATES_BY_CATEGORY,
  async (
    {
      category,
      pipelineType,
      searchValue,
      filterValues,
      sortValue,
      limit,
    }: {
      category: PipelineTemplatesCategory;
      pipelineType?: PipelineTemplatesType;
      searchValue?: string | null;
      filterValues?: SelectedFilters;
      sortValue?: string;
      limit?: number;
    },
    { rejectWithValue, dispatch, getState },
  ) => {
    const { pipelineTemplatesSortValue: defaultSortValue } = (getState() as typeof initialState)
      .pipelineTemplatesStore;
    const sortingKey = (sortValue || defaultSortValue) as keyof typeof SORTING_PARAMS_BY_KEY;

    const { field, order } = SORTING_PARAMS_BY_KEY[sortingKey] || {};
    const searchFilter = searchValue && getODataSearchFilterFrom('name', searchValue);
    const categoryFilter = getTemplateFilterByCategory(category);
    const pipelineTypeFilter = pipelineType && getODataEQFilterFrom('pipeline_type', pipelineType);
    const filters = filterValues && getODataFilterFrom(filterValues);

    const params = {
      filter: getODataConjunctionFilterFrom(
        categoryFilter,
        searchFilter,
        pipelineTypeFilter,
        filters,
      ),
      limit,
      field,
      order,
    };

    try {
      const { data } = await getPipelineTemplatesApi<IAPIPaginationData<IPipelineTemplate[]>>(
        params,
      );
      return { category, data };
    } catch (error) {
      handleErrorNotification(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const getMoreLikeThisPipelineTemplates = createAsyncThunk(
  GET_MORE_LIKE_THIS_PIPELINE_TEMPLATES,
  async ({ tags, limit }: { tags: ITag[]; limit?: number }, { rejectWithValue, dispatch }) => {
    let parsedFilter = '';

    tags.forEach((tag, index) => {
      if (index === 0) parsedFilter = getTagsNameFilter(tag.name);
      else if (index < tags.length - 1)
        parsedFilter = parsedFilter.concat(` or `).concat(getTagsNameFilter(tag.name));
    }, '');

    const params = {
      filter: parsedFilter,
      limit,
    };

    try {
      const { data } = await getPipelineTemplatesApi<IAPIPaginationData<IPipelineTemplate[]>>(
        params,
      );
      return data.data;
    } catch (error) {
      handleErrorNotification(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const getPipelineTemplateByName = createAsyncThunk(
  GET_PIPELINE_TEMPLATE_BY_NAME,
  async ({ templateName }: { templateName: string }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await getPipelineTemplateApi(templateName);
      return data;
    } catch (error) {
      handleErrorNotification(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

// Filters

export const getPipelineTemplatesFilterTags = createAsyncThunk(
  GET_PIPELINE_TEMPLATES_FILTER_TAGS,
  async (_, { rejectWithValue }) => {
    const query = {
      select: 'tags/name, tags/tag_id',
    };
    try {
      const data = await getRecursivelyPipelineTemplatesApi<string[]>(query);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
