import { useDispatch } from 'react-redux';
import { IPipeline } from '@redux/types/types';
import { startDebugTunnel as startDebugTunnelAction } from '../redux/debugTunnelSlice';

const useDebugTunnel = () => {
  const dispatch = useDispatch();

  const startDebugTunnel = (payload: Pick<IPipeline, 'pipeline_id' | 'name'>) => {
    dispatch(startDebugTunnelAction(payload));
  };

  return {
    startDebugTunnel,
  };
};

export default useDebugTunnel;
