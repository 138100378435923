import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  resetSelectTemplatesFilters,
  selectTemplatesCategory,
  setTemplatesSearchValue,
} from '@redux/actions/pipelineTemplatesActions';
import styles from './pipelineTemplatesPage.module.scss';
import { SIDEBAR_CATEGORIES } from '../constants/pipeline-templates';
import CategoriesSidebar from '../features/CategoriesSidebar';
import TemplatesActionsHeader from '../features/TemplatesActionsHeader';
import TemplatesList from '../features/TemplatesList';

const PipelineTemplatesPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTemplatesSearchValue(null));

    return () => {
      dispatch(resetSelectTemplatesFilters);
      dispatch(selectTemplatesCategory(null));
    };
  }, []);

  return (
    <div className={styles.wrapper} data-testid="templates_page">
      <div className={styles.container}>
        <CategoriesSidebar categories={SIDEBAR_CATEGORIES} />
        <div className={styles.content}>
          <TemplatesActionsHeader />
          <TemplatesList />
        </div>
      </div>
    </div>
  );
};

export default PipelineTemplatesPage;
