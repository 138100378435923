export enum AsyncActionType {
  START_DEBUG_TUNNEL = 'PIPELINE/START_DEBUG_TUNNEL',
}

export const DEBUG_TUNNEL_INFO_STORE_PATH = 'debugTunnelStore.debugTunnelInfo';

export const DEBUG_TUNNEL_SUCCESS_MESSAGE =
  "Debug tunnel started. To continue, log in to GitHub and enter the code below. When you're authenticated, open the VSCode link to start debugging.";
export const DEBUG_TUNNEL_MODAL_TITLE = 'Debug %pipelineName% in VSCode';
export const DEBUG_TUNNEL_VSCODE_LINK_MESSAGE = 'Open VSCode';
export const DEBUG_TUNNEL_GITHUB_LINK_MESSAGE = 'Log in to GitHub';
