import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@redux/store';
import {
  getRequestsHistoryApi,
  getSearchCountPerDayApi,
  getWorkspaceStatisticApi,
} from '@src/api/dashboard';
import { AsyncActionType } from '../constants/dashboard';
import { IHistoryData, ISearchCountData, IWorkspaceStatisticData } from '../types/dashboard';
import { transformHistory, transformSearchCount, transformStatistic } from '../utils/transformers';

// Dashboard State

interface DashboardState {
  workspaceStats: IWorkspaceStatisticData;
  searchCount: ISearchCountData[];
  requestHistory: IHistoryData[];
  loadingRequestHistory: boolean;
}

export const initialState: DashboardState = {
  workspaceStats: {
    fileCount: 0,
    documentCount: 0,
    averageResponseTime: 0,
    searchRequestCount: 0,
  },
  searchCount: [],
  requestHistory: [],
  loadingRequestHistory: false,
};

// Dashboard Actions

export const getRequestHistory = createAsyncThunk(AsyncActionType.GET_REQUEST_HISTORY, async () => {
  const response = await getRequestsHistoryApi();
  return response.data;
});

export const getSearchCount = createAsyncThunk(AsyncActionType.GET_SEARCH_COUNT, async () => {
  const response = await getSearchCountPerDayApi();
  return response.data;
});

export const getWorkSpaceStatistic = createAsyncThunk(
  AsyncActionType.GET_WORKSPACE_STATS,
  async () => {
    const response = await getWorkspaceStatisticApi();
    return response.data;
  },
);

// Dashboard Reducer

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRequestHistory.pending, (state) => {
      state.loadingRequestHistory = true;
    });
    builder.addCase(getRequestHistory.rejected, (state) => {
      state.loadingRequestHistory = false;
    });
    builder.addCase(getRequestHistory.fulfilled, (state, action) => {
      state.loadingRequestHistory = false;
      state.requestHistory = transformHistory(action.payload);
    });
    builder.addCase(getSearchCount.fulfilled, (state, action) => {
      state.searchCount = transformSearchCount(action.payload);
    });
    builder.addCase(getWorkSpaceStatistic.fulfilled, (state, action) => {
      state.workspaceStats = transformStatistic(action.payload);
    });
  },
});

export default dashboardSlice.reducer;

// SELECTORS
const getDashboardStore = (state: RootState) => state.dashboardStore;

// createSelector
export const loadingRequestHistorySelector = createSelector(
  [getDashboardStore],
  (dashboardStore) => dashboardStore.loadingRequestHistory,
);

export const requestHistorySelector = createSelector(
  [getDashboardStore],
  (dashboardStore) => dashboardStore.requestHistory,
);

export const searchCountSelector = createSelector(
  [getDashboardStore],
  (dashboardStore) => dashboardStore.searchCount,
);

export const workspaceStatsSelector = createSelector(
  [getDashboardStore],
  (dashboardStore) => dashboardStore.workspaceStats,
);
