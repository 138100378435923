import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';
import { PartitionOutlined } from '@ant-design/icons';
import { useWorkspaceNavigate } from '@hooks/useWorkspaceNavigate';
import { COMMON_FORM_ERRORS } from '@constants/common';
import {
  NAME_NEW_PIPELINE_MODAL_OK_BUTTON,
  NAME_NEW_PIPELINE_MODAL_TITLE,
} from '@constants/pipelines';
import {
  createPipeline,
  resetMessage as resetPipelineMessage,
} from '@redux/actions/pipelineActions';
import {
  getPipelineTemplateByName,
  resetSelectedTemplate,
} from '@redux/actions/pipelineTemplatesActions';
import {
  pipelineCreateUpdatePipelineStatusSelector,
  pipelineMessageSelector,
} from '@redux/selectors/pipelineSelectors';
import { selectedTemplateSelector } from '@redux/selectors/pipelineTemplatesSelectors';
import { IPipelineTemplate } from '@redux/types/types';
import LoadingIndicator from '@components/common/LoadingIndicator/LoadingIndicator';
import NamePipelineModal from '@components/namePipelineModal/NamePipelineModal';
import PipelineTemplateInfo from '@modules/PipelineTemplates/features/PipelineTemplateInfo';
import styles from './pipelineTemplateDetailsPage.module.scss';
import { PIPELINE_PREVIEW_TITLE } from '../constants/pipeline-templates';

const YamlVisualizer = React.lazy(() => import('@components/yamlVisualizer/YamlVisualizer'));

const PipelineTemplateDetailsPage = () => {
  const dispatch = useDispatch();
  const workspaceNavigate = useWorkspaceNavigate();
  const selectedTemplate: IPipelineTemplate | null = useSelector(selectedTemplateSelector);
  const pipelineCreateUpdatePipelineStatus = useSelector(
    pipelineCreateUpdatePipelineStatusSelector,
  );
  const pipelineMessage = useSelector(pipelineMessageSelector);
  const [showPipelinePreview, setShowPipelinePreview] = useState(true);
  const [showNamePipelineModal, setShowNamePipelineModal] = useState(false);
  const [selectedNewTemplate, setSelectedNewTemplate] = useState<IPipelineTemplate>(
    selectedTemplate!,
  );
  const { templateName } = useParams() as {
    templateName: string;
  };

  useEffect(() => {
    return () => {
      dispatch(resetSelectedTemplate);
    };
  }, []);

  useEffect(() => {
    setShowPipelinePreview(false);
  }, [selectedTemplate]);

  useEffect(() => {
    if (templateName) dispatch(getPipelineTemplateByName({ templateName }));
  }, [templateName]);

  const onCreatePipeline = ({ pipelineName }: { pipelineName: string }) => {
    const payload = {
      pipelineName,
      indexingYaml: selectedTemplate?.indexing_yaml || '',
      queryYaml: selectedTemplate?.query_yaml || '',
    };

    dispatch(createPipeline(payload));
  };

  return (
    <>
      {selectedTemplate && (
        <>
          <div className={styles.wrapper}>
            <div className={styles.container}>
              <div className={styles.templateInfo}>
                <PipelineTemplateInfo
                  template={selectedTemplate!}
                  setShowNamePipelineModal={(showModal) => setShowNamePipelineModal(showModal)}
                  setSelectedNewTemplate={(template) => setSelectedNewTemplate(template)}
                />
              </div>
              {showPipelinePreview && (
                <div className={styles.pipelinePreview}>
                  <div className={styles.pipelinePreview_title}>
                    <PartitionOutlined className={styles.pipelinePreview_title_icon} />
                    <div className={styles.pipelinePreview_title_name}>
                      {PIPELINE_PREVIEW_TITLE}
                    </div>
                  </div>
                  <ReactFlowProvider>
                    <React.Suspense fallback={<LoadingIndicator />}>
                      <YamlVisualizer
                        code={selectedTemplate?.query_yaml!}
                        yamlVisualizerExtended={false}
                      />
                    </React.Suspense>
                  </ReactFlowProvider>
                </div>
              )}
            </div>
          </div>
          <NamePipelineModal
            openModal={showNamePipelineModal}
            closeModal={() => {
              setShowNamePipelineModal(false);
              setSelectedNewTemplate(selectedNewTemplate!);
            }}
            description={!selectedNewTemplate ? COMMON_FORM_ERRORS.INVALID_CHARACTERS : ''}
            defaultPipelineNameValue={selectedNewTemplate?.pipeline_name}
            modalTitle={NAME_NEW_PIPELINE_MODAL_TITLE}
            okButtonText={NAME_NEW_PIPELINE_MODAL_OK_BUTTON}
            createPipeline={onCreatePipeline}
            onAfterPipelineCreated={(name: string) =>
              workspaceNavigate(`/pipelines/${name}/builder`)
            }
            pipelineCreateUpdatePipelineStatus={pipelineCreateUpdatePipelineStatus}
            pipelineMessage={pipelineMessage}
            resetMessage={() => dispatch(resetPipelineMessage)}
          />
        </>
      )}
    </>
  );
};

export default PipelineTemplateDetailsPage;
