import React from 'react';
import { CopyOutlined } from '@ant-design/icons';
import { Button, message, Tabs, Tooltip } from 'antd';
import { formatKeyValue, formatLogAsText } from '@utils/logs';
import {
  COPY_TO_CLIPBOARD_BUTTON_LABEL,
  COPY_TO_CLIPBOARD_MESSAGE_ALERT,
  ILogEntry,
  LOG_TAB_LABELS,
  LogLevels,
  LogTabKeys,
} from '@constants/log-explorer';
import styles from './logContent.module.scss';
import FormattedLog from '../formattedLog/FormattedLog';
import RawLog from '../rawLog/RawLog';

interface ILogContentProps {
  log: ILogEntry;
}

const { useMessage } = message;

const LogContent: React.FC<ILogContentProps> = ({ log }) => {
  const [messageApi, contextHolder] = useMessage();

  const copyToClipboard = () => {
    const logText = formatLogAsText(log);
    navigator.clipboard.writeText(logText);
    messageApi.success(COPY_TO_CLIPBOARD_MESSAGE_ALERT);
  };

  const renderExtraFields = () => (
    <>
      {Object.entries(log.extra_fields).map(([key, value]) => (
        <div key={key}>{formatKeyValue(key, value)}</div>
      ))}
    </>
  );

  if (log.level === LogLevels.INFO)
    return (
      <>
        <p className={styles.pipelineLogs_collapse_infoLog}>{log.message}</p>
        {renderExtraFields()}
      </>
    );

  const tabsItems = [
    {
      key: LogTabKeys.FORMATTED,
      label: LOG_TAB_LABELS[LogTabKeys.FORMATTED],
      children: <FormattedLog log={log} />,
    },
    {
      key: LogTabKeys.RAW,
      label: LOG_TAB_LABELS[LogTabKeys.RAW],
      children: <RawLog log={log} renderExtra={renderExtraFields} />,
    },
  ];

  return (
    <>
      {contextHolder}
      <Tabs
        defaultActiveKey="formatted"
        items={tabsItems}
        tabBarExtraContent={
          <Tooltip title={COPY_TO_CLIPBOARD_BUTTON_LABEL}>
            <Button size="small" icon={<CopyOutlined />} onClick={() => copyToClipboard()} />
          </Tooltip>
        }
      />
    </>
  );
};

export default LogContent;
