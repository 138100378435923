import dayjs from 'dayjs';
import {
  FiltersProp,
  FilterType,
  SelectedFilterItem,
  SelectedFilters,
} from '@constants/data-table';

export const initFiltersValues = (
  filters: FiltersProp | undefined,
  filterValues: SelectedFilters,
) => {
  if (!filters) return {};

  const parsedFiltersValues = filters.reduce((acc, { key }) => {
    const defaultValues = filterValues[key];
    if (defaultValues) return { ...acc, [key]: defaultValues };
    return { ...acc, [key]: [] };
  }, {});

  return parsedFiltersValues;
};

export const resetFilterValues = (filters: FiltersProp | undefined) => {
  if (!filters) return {};

  return filters.reduce((acc, { key }) => ({ ...acc, [key]: [] }), {});
};

const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ[Z]';

/**
 * Format a date filter option to the correct date value. The option keys can be one of the following:
 * date_15_m, date_30_m, date_1_h, date_2_h, date_4_h, date_1_d, date_2_d.
 *
 * @param item - The item of the filter
 * @returns The formatted date filter
 */
export const formatDateFilter = (item: SelectedFilterItem) => {
  if (!item.key.includes('date')) return { ...item };

  const [, duration, unit] = item.key.split('_');

  const date = dayjs().subtract(Number(duration), unit as dayjs.ManipulateType);

  return {
    ...item,
    value: date.format(DATE_FORMAT),
    type: FilterType.DATE,
  };
};
