export interface IBreadcrumb {
  label: string;
  link: string;
}

export interface IRoutesConfiguration {
  withSidebar?: boolean;
  sideBar: {
    activeKey?: string;
    tooltipText?: string;
  };
  path: string;
  header: {
    title?: string;
    withUserMenu?: boolean;
    withSmallContainer?: boolean;
    // We could use this instead to create breadcrumbs https://reactrouter.com/en/main/hooks/use-matches
    // but it requires chaging the routes configuration and still have to define crumbs manually
    crumbs?: IBreadcrumb[];
  };
}

const SEARCH_ROUTES_CONFIGURATION: IRoutesConfiguration[] = [
  {
    sideBar: {
      activeKey: 'Playground',
    },
    path: '/:workspace/playground',
    header: {
      title: 'Playground',
      crumbs: [
        {
          label: 'Playground',
          link: '/playground',
        },
      ],
    },
  },
  {
    sideBar: {
      activeKey: 'Playground',
    },
    path: '/:workspace/playground/:pipelineName',
    header: {
      title: 'Playground',
      crumbs: [
        {
          label: 'Playground',
          link: '/playground',
        },
        {
          label: ':pipelineName',
          link: '/playground/:pipelineName',
        },
      ],
    },
  },
];

const SETTINGS_ROUTES_CONFIGURATION: IRoutesConfiguration[] = [
  {
    sideBar: {
      activeKey: 'Home',
    },
    path: '/settings/personal-details',
    header: {
      title: 'Settings',
      crumbs: [
        {
          label: 'Settings',
          link: '/settings/personal-details',
        },
      ],
    },
  },
  {
    sideBar: {
      activeKey: 'Home',
    },
    path: '/settings/organization',
    header: {
      title: 'Settings',
      crumbs: [
        {
          label: 'Settings',
          link: '/settings/organization',
        },
      ],
    },
  },
  {
    sideBar: {
      activeKey: 'Home',
    },
    path: '/settings/connections',
    header: {
      title: 'Settings',
      crumbs: [
        {
          label: 'Settings',
          link: '/settings/connections',
        },
      ],
    },
  },
  {
    sideBar: {
      activeKey: 'Home',
    },
    path: '/settings/workspaces',
    header: {
      title: 'Settings',
      crumbs: [
        {
          label: 'Settings',
          link: '/settings/workspaces',
        },
      ],
    },
  },
  {
    sideBar: {
      activeKey: 'Home',
    },
    path: '/settings/usage',
    header: {
      title: 'Settings',
      crumbs: [
        {
          label: 'Settings',
          link: '/settings/usage',
        },
      ],
    },
  },
];

const PIPELINES_ROUTES_CONFIGURATION: IRoutesConfiguration[] = [
  {
    sideBar: {
      activeKey: 'Pipelines',
    },
    path: '/:workspace/pipelines',
    header: {
      title: 'Pipelines',
      crumbs: [
        {
          label: 'Pipelines',
          link: '/pipelines',
        },
      ],
    },
  },
  {
    sideBar: {
      activeKey: 'Pipelines',
    },
    path: '/:workspace/pipelines-landing',
    header: {
      title: 'Pipelines Landing',
      crumbs: [
        {
          label: 'Pipelines',
          link: '/pipelines-landing',
        },
      ],
    },
  },
  {
    sideBar: {
      activeKey: 'Pipelines',
    },
    path: '/:workspace/pipelines/builder',
    header: {
      title: 'Pipeline Builder',
      crumbs: [
        {
          label: 'Pipelines',
          link: '/pipelines',
        },
        {
          label: 'Builder',
          link: '/pipelines/builder',
        },
      ],
    },
  },
  {
    sideBar: {
      activeKey: 'Pipelines',
    },
    path: '/:workspace/pipelines/:pipelineName/builder',
    header: {
      title: 'Pipeline Builder',
      crumbs: [
        {
          label: 'Pipelines',
          link: '/pipelines',
        },
        {
          label: ':pipelineName',
          link: '/pipelines/:pipelineName',
        },
        {
          label: 'Builder',
          link: '/pipelines/:pipelineName/builder',
        },
      ],
    },
  },
  {
    sideBar: {
      activeKey: 'Pipelines',
    },
    path: '/:workspace/pipelines/:pipelineName',
    header: {
      title: 'Pipeline Details',
      crumbs: [
        {
          label: 'Pipelines',
          link: '/pipelines',
        },
        {
          label: ':pipelineName',
          link: '/pipelines/:pipelineName',
        },
      ],
    },
  },
  {
    sideBar: {
      activeKey: 'Pipeline Templates',
    },
    path: '/:workspace/pipelines/templates',
    header: {
      title: 'Pipeline Templates',
      crumbs: [
        {
          label: 'Pipelines',
          link: '/pipelines',
        },
        {
          label: 'Templates',
          link: '/pipelines/templates',
        },
      ],
    },
  },
  {
    sideBar: {
      activeKey: 'Pipeline Templates',
    },
    path: '/:workspace/pipelines/templates/:templateName',
    header: {
      title: 'Template',
      crumbs: [
        {
          label: 'Pipelines',
          link: '/pipelines',
        },
        {
          label: 'Templates',
          link: '/pipelines/templates',
        },
        {
          label: ':templateName',
          link: '/pipelines/templates/:templateName',
        },
      ],
    },
  },
];

const PROMPT_EXPLORER_ROUTES_CONFIGURATION: IRoutesConfiguration[] = [
  {
    sideBar: {
      activeKey: 'Prompt Explorer',
    },
    path: '/:workspace/prompt-explorer/:pipelineName',
    header: {
      title: 'Prompt Explorer',
      crumbs: [
        {
          label: 'Prompt Explorer',
          link: '/prompt-explorer',
        },
        {
          label: ':pipelineName',
          link: '/prompt-explorer/:pipelineName',
        },
      ],
    },
  },
  {
    sideBar: {
      activeKey: 'Prompt Explorer',
    },
    path: '/:workspace/prompt-explorer',
    header: {
      title: 'Prompt Explorer',
      crumbs: [
        {
          label: 'Prompt Explorer',
          link: '/prompt-explorer',
        },
      ],
    },
  },
];

const OBSERVABILITY_ROUTES_CONFIGURATION: IRoutesConfiguration[] = [
  {
    sideBar: {
      activeKey: 'Groundedness',
    },
    path: '/:workspace/groundedness',
    header: {
      title: 'Groundedness Observability',
      crumbs: [
        {
          label: 'Groundedness Observability',
          link: '/groundedness',
        },
      ],
    },
  },
  {
    sideBar: {
      activeKey: 'Groundedness',
    },
    path: '/:workspace/groundedness/:pipelineName',
    header: {
      title: 'Groundedness Observability',
      crumbs: [
        {
          label: 'Groundedness Observability',
          link: '/groundedness',
        },
        {
          label: ':pipelineName',
          link: '/groundedness/:pipelineName',
        },
      ],
    },
  },
];

const INDEXES_ROUTES_CONFIGURATION: IRoutesConfiguration[] = [
  {
    sideBar: {
      activeKey: 'Indexes',
    },
    path: '/:workspace/indexes',
    header: {
      title: 'Indexes',
      crumbs: [
        {
          label: 'Indexes',
          link: '/indexes',
        },
      ],
    },
  },
  {
    sideBar: {
      activeKey: 'Indexes',
    },
    path: '/:workspace/indexes/:indexName',
    header: {
      title: 'Index Details',
      crumbs: [
        {
          label: 'Indexes',
          link: '/indexes',
        },
        {
          label: ':indexName',
          link: '/indexes/:indexName',
        },
      ],
    },
  },
  {
    sideBar: {
      activeKey: 'Indexes',
    },
    path: '/:workspace/indexes/:indexName/builder',
    header: {
      title: 'Index Builder',
      crumbs: [
        {
          label: 'Indexes',
          link: '/indexes',
        },
        {
          label: ':indexName',
          link: '/indexes/:indexName',
        },
        {
          label: 'Builder',
          link: '/indexes/:indexName/builder',
        },
      ],
    },
  },
];

// TODO: Use routes configuration to dynamically create routes
export const ROUTES_CONFIGURATION: IRoutesConfiguration[] = [
  {
    sideBar: {
      activeKey: 'Home',
    },
    path: '/:workspace',
    header: {
      title: 'Home',
    },
  },
  {
    sideBar: {
      activeKey: 'Files',
    },
    path: '/:workspace/files',
    header: {
      title: 'Files',
      crumbs: [
        {
          label: 'Files',
          link: '/files',
        },
      ],
    },
  },
  {
    sideBar: {
      activeKey: 'Jobs',
    },
    path: '/:workspace/jobs',
    header: {
      title: 'Jobs',
      crumbs: [
        {
          label: 'Jobs',
          link: '/jobs',
        },
      ],
    },
  },
  {
    sideBar: {
      activeKey: 'Jobs',
    },
    path: '/:workspace/jobs/:jobId',
    header: {
      title: 'Jobs',
      crumbs: [
        {
          label: 'Jobs',
          link: '/jobs',
        },
        {
          label: ':jobId',
          link: '/jobs/:jobId',
        },
      ],
    },
  },
  {
    withSidebar: false,
    path: '/shared_job_results',
    header: {},
    sideBar: {},
  },
  {
    withSidebar: false,
    path: '/shared_prototypes',
    header: {},
    sideBar: {},
  },
  {
    sideBar: {
      activeKey: 'Home',
    },
    path: '/upgrade',
    header: {
      title: 'Upgrade',
      crumbs: [
        {
          label: 'Upgrade',
          link: '/upgrade',
        },
      ],
    },
  },
  ...PROMPT_EXPLORER_ROUTES_CONFIGURATION,
  ...PIPELINES_ROUTES_CONFIGURATION,
  ...SEARCH_ROUTES_CONFIGURATION,
  ...SETTINGS_ROUTES_CONFIGURATION,
  ...OBSERVABILITY_ROUTES_CONFIGURATION,
  ...INDEXES_ROUTES_CONFIGURATION,
];
