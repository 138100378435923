import { DOCS_URL } from '@constants/constant';
import { SearchFileAction } from '@redux/types/types';
import { PipelineType } from './pipelines';

export const SEARCH_FILTERS_TITLE = 'Filters';
export const SEARCHBAR_PLACEHOLDER = 'Type your query here';
export const SEARCH_BUTTON = 'Search';
export const APPLY_AND_SEARCH_BUTTON = 'Apply & Search';
export const CLEAR_FILTERS_BUTTON = 'Clear all';
export const APPLY_FILTERS_BUTTON = 'Apply';
export const CANCEL_FILTERS_BUTTON = 'Cancel';
export const CLEAR_SINGLE_FILTER = 'Clear';
export const SELECT_PIPELINE_PLACEHOLDER = 'Select a pipeline';
export const EMPTY_PIPELINE_SELECT_LABEL = 'There are no deployed pipelines.';
export const EMPTY_METADATA_MESSAGE = `You don't have any filters available. Add the metadata to your files and  they will appear here.`;
export const EMPTY_PIPELINE_METADATA_MESSAGE = `This is where the metadata from your files will be shown. You can use the metadata to filter your search results. Use the API %uploadFile% endpoint to add metadata to your files.`;
export const SELECT_KEYWORD_PLACEHOLDER = 'Type to search...';
export const EMPTY_KEYWORD_SELECT_LABEL = 'No matches found';
export const NO_DEPLOYED_PIPELINE_LABEL = `You have no deployed pipelines yet`;
export const NO_DEPLOYED_PIPELINE_DESCRIPTION =
  'To use the search, first %newPipelineLink% and then deploy it. You can also %viewDocumentationLink% on how to do everything step by step.';
export const NEW_PIPELINE_LINK = 'create pipeline';
export const UPLOAD_FILE_LINK = 'upload file';
export const VIEW_DOCUMENTATION_LINK = 'view documentation';
export const UNEXPECTED_ERROR_LABEL = 'An unexpected error occurred';
export const UNEXPECTED_ERROR_DESCRIPTION =
  'We encountered an error, try searching again. If the problem persists, contact customer services.';
export const UNDEPLOYED_PIPELINE_ERROR_LABEL = 'Redeploying your pipeline';
export const UNDEPLOYED_PIPELINE_ERROR_DESCRIPTION =
  "Wait with your search until we deploy your pipeline. We'll be done in a flash. %whyIsThisLink%";
export const UNDEPLOYED_PIPELINE_ERROR_QUESTION_LINK = 'Why is this?';
export const UNDEPLOYED_PIPELINE_MODAL_TITLE = 'Why is my pipeline on standby?';
export const UNDEPLOYED_PIPELINE_MODAL_DESCRIPTION =
  'Unused pipelines switch to standby mode after %idleTimeout% to optimize performance. Your data remains secure and accessible anytime.';
export const STANDBY_STATUS_TITLE = 'This pipeline is on standby';
export const STANDBY_STATUS_DESCRIPTION = `Unused pipelines enter standby mode after %idleTimeout%. This is done to save resources. Your data is still there, secure and accessible. Activate the pipeline to use it.`;
export const ACTIVATE_PIPELINE_BUTTON = 'Activate Pipeline';
export const ACTIVATING_PIPELINE_TITLE = 'Activating your pipeline';
export const ACTIVATING_PIPELINE_DESCRIPTION = `Wait a couple of seconds while we're preparing your pipeline to be ready for search. %whyIsThisLink%`;
export const ACTIVATING_PIPELINE_BUTTON = 'Activating Pipeline';
export const RUN_SEARCH_LABEL = 'Ask a query to start a search.';
export const NO_ANSWER_LABEL = "We couldn't find any answers for";
export const NO_ANSWER_DESCRIPTION =
  "Try a different pipeline or update your filters. Make sure the information you're looking for exists in the data you're running the search on.";
export const SEARCHING_LABEL = 'Searching for answers for';
export const RELEVANCE_LABEL = 'Relevance';
export const SOURCE_FILE_LABEL = 'Source';
export const SOURCES_FILE_LABEL = 'Sources';
export const DISABLED_EXTERNAL_SOURCES_FILES_TOOLTIP =
  "You don't have permissions to view the sources for this result. Reach out to the person that shared the link with you to get the required permissions.";
export const VIEW_DOCUMENT_LINK_LABEL = 'View Document';
export const PREVIEW_FILE_DRAWER_TITLE = 'File';
export const PREVIEW_DOCUMENT_DRAWER_TITLE = 'Document';
export const PREVIEW_DOCUMENTS_DRAWER_TITLE = 'Documents';
export const MORE_OPTIONS_VIEW_PROMPT_LABEL = 'View Prompt';
export const MORE_OPTIONS_VIEW_FILTERS_LABEL = 'View Filters';
export const MORE_OPTIONS_VIEW_PARAMS_LABEL = 'View Parameters';
export const SHOW_PROMPT_BUTTON_LABEL = 'Show Prompt Template';
export const HIDE_PROMPT_BUTTON_LABEL = 'Hide Prompt Template';
export const REPEAT_QUERY_BUTTON_LABEL = 'Repeat Query';
export const SAVE_AS_PROMPT_TEMPLATE_BUTTON_LABEL = 'Save as Prompt Template';
export const COPY_PROMPT_MESSAGE = 'Prompt copied to clipboard.';
export const ANSWER_RESULT_ERROR_ALERT_TITLE = 'Could not generate an answer.';
export const DOCUMENT_METADATA_TITLE = 'Metadata';
export const VIEW_REFERENCE_BUTTON_LABEL = 'View Reference';
export const HISTORY_LOG_TITLE = 'History Log';
export const ANSWER_TITLE = 'Answer';
export const ADDITIONAL_FEEDBACK_PLACEHODLER = 'Provide additional feedback...';
export const FEEDBACK_RATING_MODAL_TITLE = 'Why have you chosen this rating?';
export const FEEDBACK_RATING_MODAL_NO_TAGS =
  'Add custom tags in the pipeline %settings% to help gather more use-case specific feedback.';

export const RESULTS_NUM_INFO_BY_PIPELINE_TYPE = {
  [PipelineType.EXTRACTIVE_QUESTION_ANSWERING]: '%numResults% answers found',
  [PipelineType.DOCUMENT_RETRIEVAL]: '%numResults% documents found',
  [PipelineType.GENERATIVE_QUESTION_ANSWERING]: '%numResults% answers generated',
};

export const fileDropdownMenuItems = [
  {
    key: SearchFileAction.DOCUMENT,
    label: 'View Document',
  },
  {
    key: SearchFileAction.PREVIEW,
    label: 'View File',
  },
  {
    key: SearchFileAction.OPEN,
    label: 'View file in new tab',
  },
  {
    key: SearchFileAction.DOWNLOAD,
    label: 'Download File',
  },
];

export const multiFilesDropdownMenuItems = [
  {
    key: SearchFileAction.DOCUMENT,
    label: 'View Document(s)',
  },
  ...fileDropdownMenuItems.slice(1),
];

export const CREATE_PIPELINE_DOCUMENTATION_URL = `${DOCS_URL}docs/create-a-pipeline`;

// Labeling Feedback
export const RELEVANT_BUTTON_LABEL = 'Relevant';
export const NOT_RELEVANT_BUTTON_LABEL = 'Not relevant';

export const REFERENCES_HEADER = 'References';
export const REFERENCE_HEADER = 'Reference';
export const DOCUMENT_RANK_HEADER = 'Document Rank';
export const SCROLL_TO_REFERENCE_TITLE = 'Scroll to reference';
export const EXPAND_ALL_BUTTON_LABEL = 'Expand all';
export const COLLAPSE_ALL_BUTTON_LABEL = 'Collapse all';

export const UPGRADE_POPOVER_ACTIVATE_CONTENT =
  'You’ve reached the limit of the 100 hours that were granted to you as part of the free plan. To continue using your pipeline, upgrade to a paid plan.';

// Query parameters
export const QUERY_PARAMETERS_TITLE = 'Parameters';
export const QUERY_PARAMETERS_TEXT_INPUT_PLACEHOLDER = `{
  "COMPONENT_NAME": {
    "PARAMETER_NAME": "PARAMETER_VALUE"
  }
}`;
export const QUERY_PARAMETERS_DESCRIPTION =
  'Use these parameters to customize the pipeline by applying additional filters.';
export const CONFIGURATIONS_TOOLTIP_TITLE = 'Configurations';

// Stream delay
export const STREAM_DELAY_NOTIFICATION_CONTENT =
  'Streaming is still in progress. The data might be too large for optimal performance. Try using the API endpoint instead.';
