import { normalizeErrorMessage } from '@utils/error';
import { PartialBy } from '@redux/types/commonTypes';
import {
  ADD_NOTIFICATION,
  ADD_SEQUENTIAL_NOTIFICATION,
  INotification,
  NotificationType,
  REMOVE_NOTIFICATION_BY_KEY,
  RESET_NOTIFICATIONS,
} from '@redux/types/types';

export const resetNotificiations = {
  type: RESET_NOTIFICATIONS,
};

export const addNotification = (value: PartialBy<INotification, 'key'>) => ({
  type: ADD_NOTIFICATION,
  payload: value,
});

export const addSequentialNotification = (notification: PartialBy<INotification, 'key'>) => ({
  type: ADD_SEQUENTIAL_NOTIFICATION,
  payload: notification,
});

export const removeNotificationByKey = (key: string) => ({
  type: REMOVE_NOTIFICATION_BY_KEY,
  payload: key,
});

// Simplified version of the above code, next step to reduce code repetition for the same action
export const addErrorNotification = (error: unknown) => ({
  type: ADD_NOTIFICATION,
  payload: {
    content: normalizeErrorMessage(error),
    type: NotificationType.Error,
  },
});

export const addSuccessNotification = (message: string) => ({
  type: ADD_NOTIFICATION,
  payload: {
    content: message,
    type: NotificationType.Success,
  },
});
