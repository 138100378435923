import { useAuth0 } from '@auth0/auth0-react';
import {
  fetchOrganizations as fetchOrganizationsAction,
  getWorkspaces as getWorkspacesAction,
  organizationChange as organizationChangeAction,
} from '@redux/actions/organizationActions';
import { fetchUserInfo } from '@redux/actions/userActions';
import {
  fetchOrganizationsStatusSelector,
  organizationMessageSelector,
  organizationSelector,
  organizationsSelector,
} from '@redux/selectors/organizationSelectors';
import { useAppDispatch, useAppSelector } from './redux';

export const useOrganization = () => {
  const dispatch = useAppDispatch();
  const { user } = useAuth0();
  const currentOrganization = useAppSelector(organizationSelector);
  const organizations = useAppSelector(organizationsSelector);
  const fetchOrganizationsStatus = useAppSelector(fetchOrganizationsStatusSelector);
  const message = useAppSelector(organizationMessageSelector);

  const fetchOrganizations = () => {
    dispatch(fetchOrganizationsAction());
  };

  const fetchOrganizationWorkspaces = () => {
    dispatch(getWorkspacesAction());
  };

  const changeOrganization = async (organizationId: string) => {
    dispatch(organizationChangeAction(organizationId));
    await dispatch(fetchOrganizationsAction());
    dispatch(getWorkspacesAction());
    dispatch(fetchUserInfo({ authUser: user! }));
  };

  return {
    currentOrganization,
    organizations,
    fetchOrganizationsStatus,
    message,
    fetchOrganizations,
    fetchOrganizationWorkspaces,
    changeOrganization,
  };
};
