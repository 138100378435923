import React from 'react';
import { Alert, Divider } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import {
  CAUSED_BY_LABEL,
  IException,
  ILogEntry,
  LINE_LABEL,
  LogLevels,
  STACK_TRACE_LABEL,
} from '@constants/log-explorer';
import styles from './formattedLog.module.scss';

interface IFormattedLogProps {
  log: ILogEntry;
}

const FormattedLog: React.FC<IFormattedLogProps> = ({ log }) => {
  const exceptions = log?.exceptions?.length ? [...log.exceptions].reverse() : [];

  const renderSingleton = (exception: IException) => {
    return (
      <div key={uuidv4()}>
        {exception.trace.map((trace, indexTrace) => {
          return (
            <div key={uuidv4()}>
              <p>{`${trace.filename}, ${LINE_LABEL} ${trace.line_number}, ${trace.name}`}</p>
              {indexTrace < exception.trace.length - 1 && (
                <Divider className={styles.pipelineLogs_collapse_divider} />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const renderException = (exception: IException, index: number) => {
    return (
      <div key={uuidv4()}>
        {index > 0 && (
          <div className={styles.pipelineLogs_collapse_causedBy}>{CAUSED_BY_LABEL}</div>
        )}
        <p>{`${exception.type}: ${exception.value}`}</p>
        {exception.trace.map((trace) => {
          return (
            <p
              key={uuidv4()}
            >{`${trace.filename}, ${LINE_LABEL} ${trace.line_number}, ${trace.name}`}</p>
          );
        })}
        {index < log.exceptions.length - 1 && (
          <Divider className={styles.pipelineLogs_collapse_divider} />
        )}
      </div>
    );
  };

  return (
    <div className={styles.pipelineLogs_collapse_tabItem}>
      {log.level === LogLevels.ERROR ||
      log.level === LogLevels.CRITICAL ||
      log.level === LogLevels.WARNING ? (
        <Alert
          message={exceptions.length === 1 ? log.message + STACK_TRACE_LABEL : log.message}
          type={log.level === LogLevels.WARNING ? 'warning' : 'error'}
          className={styles.pipelineLogs_collapse_alert}
        />
      ) : null}
      {exceptions.length === 1 ? (
        renderSingleton(exceptions[0])
      ) : (
        <>{exceptions.map((exception, index) => renderException(exception, index))}</>
      )}
    </div>
  );
};

export default FormattedLog;
