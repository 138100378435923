import React, { useCallback, useMemo } from 'react';
import { Alert, Divider, Flex, Select, Typography } from 'antd';
import { useOrganization } from '@hooks/useOrganization';
import { NOT_AVAILABLE_LABEL } from '@constants/common';
import PeopleBlock from '@components/common/PeopleBlock/PeopleBlock';
import TopNavigation from '@components/settings/TopNavigation';
import { ORGANIZATION_DESCRIPTION, ORGANIZATION_LABEL } from '@src/constants/settings-page';
import styles from './organizationPage.module.scss';

const { Paragraph, Title } = Typography;

const OrganizationPage = () => {
  const { changeOrganization, currentOrganization, organizations, message } = useOrganization();

  const onOrganizationChange = (value: string) => {
    changeOrganization(value);
  };

  const getOrganizationOptions = useCallback(() => {
    return organizations.map((org) => ({ label: org.name, value: org.organization_id }));
  }, [organizations]);

  const userIsPartOfMultipleOrganizations = useMemo(
    () => organizations.length > 1,
    [organizations],
  );

  const getOrganizationName = () => {
    if (currentOrganization) return currentOrganization.organizationName;
    return NOT_AVAILABLE_LABEL;
  };

  // Renders
  const renderOrganizationSelect = () => {
    return (
      <Select
        className={styles.organizationSelect}
        options={getOrganizationOptions()}
        value={currentOrganization?.organizationId}
        onChange={onOrganizationChange}
      />
    );
  };

  const renderHeader = () => {
    return (
      <Flex vertical gap="small">
        <Paragraph type="secondary">{ORGANIZATION_LABEL}</Paragraph>
        <Flex gap="small" vertical>
          {userIsPartOfMultipleOrganizations ? (
            renderOrganizationSelect()
          ) : (
            <Title level={4}>{getOrganizationName()}</Title>
          )}
          <Paragraph>{ORGANIZATION_DESCRIPTION}</Paragraph>
        </Flex>
      </Flex>
    );
  };

  return (
    <div className="content-wrapper">
      <TopNavigation />
      {message && message.content && <Alert message={message.content} type={message.type} banner />}
      <div className="content-wrapper_padding">
        {renderHeader()}
        <Divider />
        <div>
          <PeopleBlock withInvite />
        </div>
      </div>
    </div>
  );
};

export default OrganizationPage;
