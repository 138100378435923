import React, { memo } from 'react';
import { Flex, Typography } from 'antd';
import UnexpectedErrorSVG from '@assets/unexpected-error.svg?react';
import styles from './errorDisplay.module.scss';

const { Title } = Typography;

interface IErrorDisplayProps {
  message: string;
}

const ErrorDisplay = ({ message }: IErrorDisplayProps) => {
  return (
    <Flex vertical className={styles.container} align="center" justify="center">
      <UnexpectedErrorSVG />
      <Title level={3}>{message}</Title>
    </Flex>
  );
};

export default memo(ErrorDisplay);
