import { ChangeEvent } from 'react';
import { Align, Edge, Node, Position } from '@xyflow/react';

export interface IPipelineData {
  indexingNodes: any[];
  indexingEdges: any[];
  queryNodes: any[];
  queryEdges: any[];
}

export interface IComponentMapping {
  type: string;
  typeConst: string;
  family?: string;
  familyDescription?: string;
  description?: string;
  initParams: Record<string, { required: boolean; [key: string]: unknown }>;
  oftenOutputsTo?: string[];
  oftenReceivesFrom?: string[];
  docsLink?: string;
  dynamicParams?: Record<string, any>;
  packageVersion?: string;
  componentFrequency?: number;
}

export interface IInputOutput {
  key: string;
  type: string;
  items: Record<string, any>;
  required: boolean;
  _raw: Record<string, any>;
  variadic?: boolean;
}

export interface EnvVarParam {
  type: 'env_var';
  env_vars: string[];
  strict: boolean;
}

export type RfiElementsType = {
  validGraph: boolean;
  nodes: Node[];
  edges: Edge[];
};

export type StudioNodeType = {
  id: string;
  toolbarVisible?: boolean;
  toolbarPosition?: Position;
  paramsVisible?: boolean;
  optionalParamsVisible: boolean;
  toolbarAlign?: Align;
  label: string;
  customName: string;
  customComponentVersion?: string;
  description: string;
  type: string;
  nodeTypeConst: string;
  family: string;
  oftenOutputsTo: string[];
  oftenReceivesFrom: string[];
  onParamsChange: (nodeId: string, nodeType: string, params: Record<string, any>) => void;
  setNodeEditorVisible: (value: { nodeId: string; paramName: string }) => void;
  setNodes: (value: React.SetStateAction<Node[]>) => void;
  initParams: Record<
    string,
    {
      default: any;
      type: string[];
      required: boolean;
      description: string;
      $ref?: string;
      oneOf?: any[];
      anyOf?: any[];
      examples?: any[];
    }
  >;
  params: Record<string, any>;
  inputOutputs: {
    input: IInputOutput[];
    output: IInputOutput[];
    name: string;
  };
  docsLink: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

export type ParamType =
  | 'string'
  | 'number'
  | 'integer'
  | 'boolean'
  | 'object'
  | 'array'
  | undefined;
export type ConnectionType = 'input' | 'output';

export interface IHoveredConnection {
  key: string;
  _raw: any;
  check_against: ConnectionType;
}

export type InputNodeType = 'Query' | 'Filters' | 'FilesInput' | 'DocumentStore';
export type NodeTypeValue = 'query' | 'filters' | 'files' | 'document_store';

export const INPUT_NODE_TYPE_MAPPING: Record<InputNodeType, NodeTypeValue> = {
  Query: 'query',
  Filters: 'filters',
  FilesInput: 'files',
  DocumentStore: 'document_store',
};
export const INPUT_NODE_TYPE = Object.keys(INPUT_NODE_TYPE_MAPPING);
export const OUTPUT_NODE_TYPE = ['Output'];
export const IGNORE_NODE_TYPE = OUTPUT_NODE_TYPE.concat(INPUT_NODE_TYPE);

// Undo/Redo
interface HistoryEventData {
  element?: Node | Edge;
  nodeElements?: Node[];
  edgeElements?: Edge[];
  previousState?: Node | Edge | Node[] | Edge[];
  previousStateNodes?: Node[];
  previousStateEdges?: Edge[];
}

export enum HistoryEventType {
  NODES_EDGES_CHANGE = 'NODES_EDGES_CHANGE',
  NODE_UPDATED = 'NODE_UPDATED',
  NODE_ADDED = 'NODE_ADDED',
  NODE_REMOVED = 'NODE_REMOVED',
  EDGE_ADDED = 'EDGE_ADDED',
  EDGE_REMOVED = 'EDGE_REMOVED',
  EDGE_UPDATED = 'EDGE_UPDATED',
  NODE_MOVED = 'NODE_MOVED',
  NODES_CONNECTED = 'NODES_CONNECTED',
  NODES_DISCONNECTED = 'NODES_DISCONNECTED',
  MULTIPLE_CHANGES = 'MULTIPLE_CHANGES',
}

export interface HistoryEvent {
  type: HistoryEventType;
  data: HistoryEventData;
}

export interface HistoryItem {
  events: HistoryEvent[];
  timestamp: number;
}
